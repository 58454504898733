<template>
  <div class="layer" v-show="isShowCheckinLayer">
    <div class="layerContent">
      <div class="content-wrapper">
        <div class="content-blind-area"/>
        <div class="content-button">
          <ul class="button">
            <li class="arrow">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onLayerBtnClicked"
              >
                {{ layerBtnCaption }}
              </erp-button>
            </li>
            <li class="refresh">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  :disabled="isCheckinLayerThumbnail"
                  :ignore="isPopupOpened || isCheckinLayerThumbnail"
                  @click.native="onRefreshBtnClicked"
              >
                Refreshs
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onLayerCloseBtnClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
        <div class="content-step">
          <ul class="step">
            <li :class="['step-01', stepStyleClass(0)]">
              <div class="item" @click="goStepPage(0)">
                STEP 01. 내장객 등록 (F7)
              </div>
            </li>
            <li :class="['step-02', stepStyleClass(1)]">
              <div class="item" @click="goStepPage(1)">
                STEP 02. 요금정산 (F8)
              </div>
            </li>
            <li :class="['step-03', stepStyleClass(2)]">
              <div class="item" @click="goStepPage(2)">STEP 03. 업장매출</div>
            </li>
          </ul>
        </div>
        <div class="content-thumbnail">
          <div
            class="thumbnail thumb-01"
            v-if="visitInfoList"
            v-show="isShowThumbnail(0)"
          >
            <div
              class="card"
              v-for="visitInfo in thumbnailVisitInfoList"
              :key="visitInfo.visitId"
            >
              <ul class="item">
                <li class="header">
                  <div class="title">
                    {{ visitInfo.visitName + (visitInfo.memberNo ? " " + memberNoFormatter(visitInfo.memberNo) : "") }}
                  </div>
                  <div class="content">
                    락카 {{ visitInfo.lockerNo }} /
                    {{
                      commonCodesGetComName("PAYMT_CODE", visitInfo.paymtCode)
                    }}
                  </div>
                </li>
                <li>
                  <!-- TODO : 락카정보, 회원등급 정보가 없는 경우에는 이름만 출력해야 함 -->
                  <div class="title">입장료</div>
                  <div class="content">
                    {{
                      getGreenFee(
                        visitInfo.paymtCode,
                        visitInfo.greenFeeInfo
                      ) !== null
                        ? getGreenFee(
                            visitInfo.paymtCode,
                            visitInfo.greenFeeInfo
                          ).toLocaleString()
                        : ""
                    }}
                  </div>
                </li>
                <li>
                  <div class="title">카트대여</div>
                  <div class="content">
                    {{
                      visitInfo.cartAmt !== null &&
                      visitInfo.cartAmt !== undefined
                        ? Number(visitInfo.cartAmt).toLocaleString()
                        : ""
                    }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="thumbnail thumb-02" v-show="isShowThumbnail(1)">
            <div
              class="card"
              v-for="payInfo in thumbnailPayInfoList"
              :key="payInfo.visitId"
            >
              <ul class="item">
                <li class="header">
                  <div class="title">
                    {{ payInfo.visitName }}
                  </div>
                  <div class="content">
                    락카 {{ payInfo.lockerNo }} /
                    {{ commonCodesGetComName("HOLE_DIV", payInfo.holeDiv) }}
                  </div>
                </li>
                <li>
                  <div class="title">매출합계</div>
                  <div class="content">
                    {{
                      !!payInfo.totAmt
                        ? Number(payInfo.totAmt).toLocaleString()
                        : ""
                    }}
                  </div>
                </li>
                <li>
                  <div class="title">미정산액</div>
                  <div class="content">
                    <em>
                      {{
                        !!payInfo.noPayAmt
                          ? Number(payInfo.noPayAmt).toLocaleString()
                          : ""
                      }}
                    </em>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="thumbnail thumb-03" v-show="isShowThumbnail(2)">
            <div
              class="card"
              v-for="saleInfo in thumbnailStoreSaleInfoList"
              :key="saleInfo.storeGroupCode"
              :style="
                'width: calc(100% / ' +
                (thumbnailStoreSaleInfoList.length === 0
                  ? 1
                  : thumbnailStoreSaleInfoList.length) +
                ')'
              "
            >
              <ul class="item">
                <li class="header">
                  <div class="title">
                    {{ saleInfo.storeGroupName }}
                  </div>
                </li>
                <li>
                  <div class="title">건수</div>
                  <div class="content">
                    {{ saleInfo.totCnt > 0 ? `${Number(saleInfo.totCnt).toLocaleString()}건` : "-" }}
                  </div>
                </li>
                <li>
                  <div class="title">판매금액</div>
                  <div class="content">
                    {{ saleInfo.totAmt > 0 ? Number(saleInfo.totAmt).toLocaleString() : "-" }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="content-body">
          <article
            class="body-article article-01"
            v-show="isShowContent(null)"
            v-if="teamInfo !== null"
          >
            <div class="article-left">
              <section class="article-section section-01">
                <div class="section-header">
                  <div class="header-left">
                    <div class="header-title">팀 정보</div>
                    <div class="header-caption" v-if="teamInfo.chkinId">
                      <strong>[{{ teamInfo.chkinId }}]</strong>
                      <!--[{{visitsLength}}], [{{visitsTotalPay}}]-->
                    </div>
                  </div>
                  <div class="header-right" >
                    <ul class="header-button">
                      <li style="margin: 0px">
                        <erp-button button-div="GET" @click.native="viewHistory" :is-icon-custom="true">변경이력</erp-button>
                      </li>
                    </ul>
                  </div>
                  <div class="header-right" v-if="teamInfo.roundNo">
                    <div class="header-title">라운드 번호</div>
                    <div class="header-caption">
                      <strong>[{{ teamInfo.roundNo }}]</strong>
                    </div>
                  </div>
                </div>
                <div class="section-body">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title required">시간</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <input-time
                                      ref="startTime"
                                      v-model="teamInfo.startTime"
                                      :format="'HH:mm'"
                                      :disabled="!isTeamInfoEnable"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title required">코스</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <ejs-dropdownlist
                                      ref="teamInfoStartCourse"
                                      v-model="teamInfo.startCourse"
                                      :dataSource="
                                        teamInfoOptions.startCourseOptions
                                      "
                                      :allowFiltering="false"
                                      :fields="commonOptions.commonCodeField"
                                      cssClass="body-data-dropdown"
                                      :enabled="isTeamInfoEnable"
                                    ></ejs-dropdownlist>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title required">부 구분</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <ejs-dropdownlist
                                      ref="teamInfoPartDiv"
                                      v-model="teamInfo.partDiv"
                                      :dataSource="
                                        teamInfoOptions.partDivOptions
                                      "
                                      :allowFiltering="false"
                                      :fields="commonOptions.commonCodeField"
                                      cssClass="body-data-dropdown"
                                      :enabled="isTeamInfoEnable"
                                    ></ejs-dropdownlist>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title required">예약종류</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <ejs-dropdownlist
                                        ref="teamInfoResveKind"
                                        v-model="teamInfo.resveKind"
                                        :dataSource="
                                        teamInfoOptions.resveKindOptions
                                      "
                                        :allowFiltering="false"
                                        :fields="commonOptions.commonCodeField"
                                        cssClass="body-data-dropdown"
                                        :enabled="isTeamInfoEnable"
                                    ></ejs-dropdownlist>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title required">팀 홀구분</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div
                                    class="form"
                                    @keydown.capture="
                                      onTeamInfoHoleDivKeydownCapture
                                    "
                                  >
                                    <ejs-dropdownlist
                                      ref="teamInfoTeamHoleDiv"
                                      v-model="teamInfo.teamHoleDiv"
                                      :dataSource="
                                        teamInfoOptions.teamHoleDivOptions
                                      "
                                      :allowFiltering="false"
                                      :fields="commonOptions.commonCodeField"
                                      cssClass="body-data-dropdown"
                                      :enabled="isTeamInfoEnable"
                                      @change="onTeamInfoTeamHoleDivChanged"
                                    ></ejs-dropdownlist>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">우천(강제)</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  <ul class="check">
                                    <li>
                                      <label>
                                        <input
                                          type="checkbox"
                                          name="rainFlag"
                                          v-model="teamInfo.rainFlag"
                                          @change="onRainFlagChange"
                                        />
                                        <i></i>
                                        <div class="label">
                                          우천(강제)
                                        </div>
                                      </label>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">카트 종류</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  <div class="form">
                                    <ejs-dropdownlist
                                        :dataSource="cartOptions"
                                        :fields="commonOptions.commonCodeField"
                                        v-model="teamInfo.cartKind"
                                        @change="onChangeCartKind"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">카트 수량</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <input-number
                                      v-model="teamInfo.cartQty"
                                      :allow-minus="false"
                                      :disabled="!isTeamInfoEnable"
                                      :propMaxLength="3"
                                      @change="onChangeCartQty"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field groupName">
                            <!-- 필수 : required -->
                            <div class="title">단체명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group input">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <input-text
                                        ref="grpName"
                                        v-model="teamInfo.grpName"
                                        :disabled="!isTeamInfoEnable"
                                        @change="onTeamInfoGrpNameChanged"
                                    />
                                  </div>
                                </li>
                                <li class="item form-group button">
                                  <ul class="button">
                                    <li class="search">
                                      <erp-button
                                          button-div="GET"
                                          @click.native="onGroupPopupOpen(true)"
                                      >
                                        검색
                                      </erp-button>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field promotion">
                            <!-- 필수 : required -->
                            <div class="title">내장인원수</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <!-- validator를 위하여 form-group class를 붙임 -->
                                  <div class="form">
                                    <input-number
                                      ref="visitCnt"
                                      v-model="teamInfo.visitCnt"
                                      :allow-minus="false"
                                      :disabled="!isTeamInfoEnable"
                                      :min="1"
                                      :max="5"
                                      :propMaxLength="1"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">예약자명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ resveInfo.resveName }}
                                  <img :src="vipIcon" alt="vip" v-if="resveInfo.vipFlag" />
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">위임자</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group text">
                                  {{ teamInfo.transName }}
                                </li>
                              </ul>
                            </div>
                          </li>

                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </div>
            <div class="article-right">
              <section class="article-section section-02">
                <div class="section-body" style="overflow: hidden">
                  <ejs-tab
                    ref="teamInfoTab"
                    id="element"
                    :animation="teamInfoTabOptions.tabAnimation"
                    :cssClass="teamInfo.eventContents && 'remark-active'"
                  >
                    <div class="e-tab-header">
                      <div>부가정보</div>
                      <div>회원정보</div>
                      <div>예약정보</div>
                      <div>캐디배치</div>
                      <div>비고</div>
                    </div>
                    <div class="e-content content-body" >
                      <div>
                        <article class="body-article article-0101" style="padding: 4px">
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0201" style="padding: 3px">
                            <div class="section-body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field" style="width: 410px">
                                        <!-- 필수 : required -->
                                        <div class="title">선택</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <!-- 포커스 : focus -->
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      name="outFlag"
                                                      :disabled="
                                                        !isTeamInfoEnable
                                                      "
                                                      v-model="
                                                        teamInfo.joinFlag
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      조인신청
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      name="outFlag"
                                                      v-model="
                                                        teamInfo.galleyTeamFlag
                                                      "
                                                      :disabled="
                                                        !isTeamInfoEnable
                                                      "
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      갤러리팀
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      name="outFlag"
                                                      :disabled="
                                                        !isTeamInfoEnable
                                                      "
                                                      v-model="
                                                        teamInfo.selfFlag
                                                      "
                                                      @change="onChangeSelfFlag"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      셀프팀
                                                    </div>
                                                  </label>
                                                </li>
                                                <li>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      name="outFlag"
                                                      :disabled="
                                                        !isTeamInfoEnable
                                                      "
                                                      v-model="teamInfo.kepFlag"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      락카보관
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field" style="width: 210px">
                                        <div class="title"><em>내장여부</em></div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <ul class="check">
                                                <li>
                                                  <label>
                                                    <input
                                                        type="checkbox"
                                                        name="outFlag"
                                                        :disabled="
                                                                  !isTeamInfoEnable
                                                                "
                                                        v-model="
                                                                  teamInfo.visitConfirmFlag
                                                                "
                                                        @change="onOutFlagChange"
                                                    />
                                                    <i></i>
                                                    <div class="label">
                                                      내장확정
                                                    </div>
                                                  </label>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field teamStatus" style="width: calc(100% - 617px)">
                                        <div class="title">팀 상태</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text-01" style="width: 25%">
                                              {{ teamInfo.payIngFlagName }}
                                            </li>
                                            <li class="item form-group text" style="width: 25%" ref="baseAbsoluteField">
                                              {{ teamInfo.payIngIdName }}
                                            </li>
                                            <li class="item form-group text-01" style="width: 50%">
                                              {{ `${teamInfo.teamChkoutFlagName} ${teamInfo.teamChkoutDt ? removeYearFromDate(teamInfo.teamChkoutDt) : ''}`}}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="title">
                                          <div>예약비고</div>
                                        </div>
                                        <div class="content">
                                          <ul class="row">
                                              <li class="item form-group text">
                                                {{ resveInfo.reserveRemarks }}
                                              </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="title">
                                          <div>단체정보</div>
                                        </div>
                                        <div class="content">
                                          <div class="body-data">
                                            <div class="data-outer">
                                              <div class="data-inner">
                                                <ul class="data-content">
                                                  <li class="field" style="width: calc(100% / 4 + 1px)">
                                                    <div class="title" style="border-left: none; border-top: none">회장명</div>
                                                    <div class="content" style="border-top: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ teamInfo.grpChairName }}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field" style="width: calc(100% / 4 + 1px)">
                                                    <div class="title" style="border-top: none">회장연락처</div>
                                                    <div class="content" style="border-top: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ getFormattedPhoneNumber(teamInfo.grpChairContactTel) }}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field" style="width: calc(100% / 4 + 1px)">
                                                    <div class="title" style="border-top: none">총무명</div>
                                                    <div class="content" style="border-top: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ teamInfo.grpGenerName }}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field" style="width: calc(100% / 4 + 1px)">
                                                    <div class="title" style="border-top: none">총무연락처</div>
                                                    <div class="content" style="border-top: none; border-right: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ getFormattedPhoneNumber(teamInfo.grpGenerContactTel) }}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field" style="width: calc(100% / 2 + 1px)">
                                                    <div class="title" style="border-left: none; border-bottom: none">계약조건</div>
                                                    <div class="content" style="border-bottom: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ teamInfo.grpResveRemarks }}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field" style="width: calc(100% / 2 + 1px)">
                                                    <div class="title" style="border-bottom: none">결제조건</div>
                                                    <div class="content" style="border-bottom: none; border-right: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ teamInfo.grpFrontRemarks}}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                  <li class="field">
                                                    <div class="title" style="border-left: none; border-bottom: none">비고</div>
                                                    <div class="content" style="border-bottom: none; border-right: none">
                                                      <ul class="row" style="border-bottom: none">
                                                        <li class="item form-group text">
                                                          {{ teamInfo.grpCharct}}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                      <div>
                        <article class="body-article article-0103">
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0203">
                            <div class="section-body">
                              <div class="body-card">
                                <!-- ul 길이 = 214px * card갯수 -->
                                <ul
                                    class="box"
                                    :style="{
                                    width:
                                      'calc(214px * ' +
                                      memberImageInfo.length +
                                      ')',
                                  }"
                                >
                                  <!-- 활성 : active -->
                                  <li
                                      class="card"
                                      v-for="memberImage in memberImageInfo"
                                      :key="memberImage.visitId"
                                      @click="onShowMemberImageClick(memberImage)"
                                  >
                                    <div class="photo">
                                      <div
                                        class="item"
                                        :style="{
                                          backgroundImage: memberImage.imagePath
                                            ? 'url(' +
                                            memberImage.imagePath +
                                            ')'
                                            : null,
                                          backgroundColor: memberImage.imagePath
                                            ? '#fff'
                                            : 'transparent',
                                        }"
                                      ></div>
                                    </div>
                                    <ul class="content" style="margin: -8px 0;">
                                      <li class="item">
                                        <strong>{{
                                            memberImage.visitName
                                          }}</strong
                                        > <span v-if="memberImage.memberNo">({{ memberNoFormatter(memberImage.memberNo) }})</span>
                                      </li>
                                      <li class="item">
                                        {{
                                          commonCodesGetComName(
                                              "MEMBER_DIV",
                                              memberImage.memberDiv
                                          )
                                        }}/{{
                                          commonCodesGetComName(
                                              "MEMBER_GRADE",
                                              memberImage.memberGrade
                                          )
                                        }}
                                      </li>
                                      <li class="item">
                                        {{ memberImage.remarks }}
                                      </li>
                                      <li class="item">
                                        {{ memberImage.expirDateToByMax ? "연회비 ~ " + memberImage.expirDateToByMax : memberImage.expirDateToByMax }}
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                      <div>
                        <article class="body-article article-0102">
                          <div class="article-left">
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section class="article-section section-020201">
                              <div class="section-body">
                                <div class="body-card">
                                  <div class="card">
                                    <div class="photo">
                                      <div
                                        class="item"
                                        :style="{
                                          backgroundImage: resveInfo.imagePath
                                            ? 'url(' + resveInfo.imagePath + ')'
                                            : null,
                                          backgroundColor: resveInfo.imagePath
                                            ? '#fff'
                                            : 'transparent',
                                        }"
                                      ></div>
                                    </div>
                                    <ul class="content">
                                      <li class="item">
                                        <strong>{{
                                          resveInfo.resveName
                                        }}</strong
                                        > <span v-if="resveInfo.memberNo">({{ memberNoFormatter(resveInfo.memberNo) }})</span>
                                      </li>
                                      <li class="item">
                                        {{
                                          commonCodesGetComName(
                                            "MEMBER_DIV",
                                            resveInfo.memberDiv
                                          )
                                        }}/{{
                                          commonCodesGetComName(
                                            "MEMBER_GRADE",
                                            resveInfo.memberGrade
                                          )
                                        }}
                                      </li>
                                      <li class="item">
                                        {{
                                          getFormattedPhoneNumber(
                                            resveInfo.contactTel
                                          )
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                          <div class="article-right">
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section class="article-section section-020202">
                              <div class="section-body">
                                <div class="body-data">
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">예약번호</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{ resveInfo.resveNo }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">예약채널</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{
                                                  commonCodesGetComName(
                                                      "RESVE_CHANNEL",
                                                      resveInfo.resveChannel
                                                  )
                                                }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">코스/시간</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{
                                                  commonCodesGetComName(
                                                      "COURSE_CODE",
                                                      resveInfo.resveCourse
                                                  )
                                                }}
                                                / {{ resveInfo.resveTime }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">프로모션</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{ resveInfo.promtnName }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">홀구분</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{
                                                  commonCodesGetComName(
                                                      "HOLE_DIV",
                                                      resveInfo.holeDiv
                                                  )
                                                }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title flex-narrow-field">비고</div>
                                          <div class="content flex-wide-field">
                                            <ul class="row">
                                              <li class="item form-group text">
                                                {{ resveInfo.reserveRemarks }}
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-top"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </article>
                      </div>
                      <div>
                        <article class="body-article article-0104">
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0204">
                            <div class="section-body">
                              <div class="body-data header">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field number">
                                        <div class="title">NO</div>
                                      </li>
                                      <li class="field">
                                        <div class="title">시간</div>
                                      </li>
                                      <li class="field">
                                        <div class="title">코스</div>
                                      </li>
                                      <li class="field">
                                        <div class="title">캐디명</div>
                                      </li>
                                      <li class="field">
                                        <div class="title">캐디피</div>
                                      </li>
                                      <li class="field">
                                        <div class="title">카트No</div>
                                      </li>
                                      <li class="field caddieFeeReceipt">
                                        <div class="title">캐디피영수증</div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                              <div class="body-data body">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul
                                      class="data-content"
                                      v-for="(
                                        caddieInfo, caddieInfoIdx
                                      ) in caddieInfoList"
                                      :key="caddieInfo.caddieId"
                                    >
                                      <li class="field number">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{ caddieInfoIdx + 1 }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{ caddieInfo.startTime }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{
                                                commonCodesGetComName(
                                                  "COURSE_CODE",
                                                  caddieInfo.startCourse
                                                )
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{ caddieInfo.caddieName }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{
                                                Number(
                                                  caddieInfo.caddieFee
                                                ).toLocaleString()
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group text">
                                              {{ caddieInfo.cartNo }}번
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field caddieFeeReceipt">
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group button">
                                              <ul class="button">
                                                <li class="delete">
                                                  <erp-button
                                                      button-div="PRINT"
                                                      :is-icon-custom="true"
                                                      @click.native="
                                                      openCaddieFee(caddieInfo)
                                                    "
                                                  >
                                                    발행
                                                  </erp-button>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                              <div
                                class="body-footer"
                                style="
                                  border-left: 1px solid #ccc;
                                  border-right: 1px solid #ccc;
                                  border-bottom: 1px solid #ccc;
                                "
                              >
                                <ul>
                                  <li>합계 {{ caddieInfoList.length }} 명</li>
                                  <li>
                                    캐디피 토탈 :
                                    {{
                                      Number(
                                        sumOfCaddieInfoListCaddieFee
                                      ).toLocaleString()
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </section>
                        </article>
                      </div>
                      <div>
                        <article class="body-article article-0105" style="display: flex;">
                          <!-- 아코디언 : accordion / 닫힘 : close -->
                          <section class="article-section section-0105" style="padding: 1px; flex: 1">
                            <div class="section-body body-data">
                              <ul class="data-content">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea ref="eventContents"  :rows="6" :height="109" :resize="false" v-model="teamInfo.eventContents"/>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </section>
                          <div class="section-header" style="padding: 0; margin: 0; display: flex; flex-flow: column nowrap; justify-content: end">
                            <div class="header-right">
                              <ul class="header-button">
                                <li class="save">
                                  <erp-button
                                      button-div="SAVE"
                                      @click.native="saveEventContents(teamInfo)"
                                  >
                                    저장
                                  </erp-button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </ejs-tab>
                </div>
              </section>
            </div>
          </article>
          <article
            class="body-article article-02"
            :class="{
              'dev-body-article-active-check-in__information': isPayInfoGridVisible,
            }"
          >
            <!-- 아코디언 : accordion / 닫힘 : close -->
            <section
              class="article-section section-03"
              v-show="isShowContent(0)"
            >
              <div class="section-header">
                <div class="header-left">
                  <div class="header-title">내장객 등록</div>
                </div>
                <div class="header-right">
                  <ul class="header-button">
                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          :disabled="!isTeamInfoEnable"
                          :ignore="isPopupOpened || stepIndex !== 0"
                          @click.native="takeCompanionMemberClicked"
                      >
                        동반자 가져오기
                      </erp-button>
                    </li>
                    <li class="save keyColor">
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :is-shortcut-button="true"
                          :disabled="
                          isCheckinLayerThumbnail ||
                          !isTeamInfoEnable ||
                          isChkinStatusByRain
                        "
                          :ignore="isPopupOpened || stepIndex !== 0"
                          @click.native="onSaveVisitInfoClicked"
                      >
                        저장
                      </erp-button>
                    </li>
                    <li class="add">
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :is-custom-shortcut-button="true"
                          :shortcut-key="'CheckinLayer.shortcut.add'"
                          :shortcut="{key:'F3'}"
                          :disabled="
                          isCheckinLayerThumbnail ||
                          areAllTeamCheckout
                        "
                          :ignore="isPopupOpened || stepIndex !== 0"
                          @click.native="onAddVisitInfoClicked"
                      >
                        추가
                      </erp-button>
                    </li>
                    <li class="delete">
                      <erp-button
                          button-div="DELETE"
                          :is-icon-custom="true"
                          :is-shortcut-button="true"
                          @click.native="onDeleteVisitInfoClicked"
                          :disabled="
                          isCheckinLayerThumbnail ||
                          !isTeamInfoEnable
                        "
                          :ignore="isPopupOpened || stepIndex !== 0"
                      >
                        삭제
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :is-custom-shortcut-button="true"
                          :shortcut-key="'CheckinLayer.shortcut.Prepayment'"
                          :shortcut="{  altKey: true,key:'F1'}"
                          @click.native="onPrepaymentButtonClicked"
                          :disabled="
                          isCheckinLayerThumbnail ||
                          !isTeamInfoEnable ||
                          isChkinStatusByRain
                        "
                          :ignore="isPopupOpened || stepIndex !== 0"
                      >
                        선불정산
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          :is-custom-shortcut-button="true"
                          :shortcut-key="'CheckinLayer.shortcut.lockerPublish'"
                          :shortcut="{ key:'F10'}"
                          :disabled="
                          isCheckinLayerThumbnail
                        "
                          :ignore="isPopupOpened || stepIndex !== 0"
                          @click.native="onLockerPublishClicked"
                      >
                        락카발행
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          :is-custom-shortcut-button="true"
                          :shortcut-key="'CheckinLayer.shortcut.LockerStatus'"
                          :shortcut="{ key:'F12'}"
                          :disabled="isCheckinLayerThumbnail"
                          :ignore="isPopupOpened || stepIndex !== 0"
                          @click.native="onLockerViewClicked"
                      >
                        락카상황판
                      </erp-button>
                    </li>
                    <li class="add">
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="!isTeamInfoEnable ||isChkinStatusByRain"
                          @click.native="holeAddButtonClicked"
                      >
                        홀추가
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="!isTeamInfoEnable"
                          @click.native="onVisitsCopyClicked"
                      >
                        내장객복사
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          @click.native="onCartDiscountClicked"
                      >
                        카트할인
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="!isTeamInfoEnable"
                          @click.native="onNormalRentClicked"
                      >
                        일반대여
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="!isTeamInfoEnable"
                          @click.native="onSendSmsClicked"
                      >
                        SMS전송
                      </erp-button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="section-body"
                @keydown.capture="onVisitGridSectionBodyKeyDown"
                @click.capture="onVisitGridClick"
              >
                <ejs-grid-wrapper
                  v-if="isVisitGridCreate"
                  ref="visitsGrid"
                  :provides="visitsGridProvides"
                  :dataSource="visitInfoList"
                  :columns="visitsGridColumns"
                  :enableHover="false"
                  :isNOColumnDisplay="false"
                  :allowSorting="false"
                  :allowFiltering="false"
                  :allowKeyboard="false"
                  :validationRules="visitsGridValidationRules"
                  :validationModification="false"
                  :selectionSettings="visitsGridSelectionSettings"
                  :editSettings="visitGridEditSettings"
                  :aggregates="visitsGridAggregates"
                  :isCustomizeKeyboardAction="true"
                  :noColumnWidth="40"
                  @headerCellInfo="visitsGridHeaderCellInfo"
                  @queryCellInfo="visitsGridQueryCellInfo"
                  @cellSave="visitsGridCellSave($event)"
                  @cellSaved="visitsGridCellSaved($event)"
                  @recordClick="visitsGridClicked"
                  @actionComplete="onVisitsGridActionComplete"
                  @beforeBatchDelete="onVisitsGridBeforeBatchDelete"
                  @batchDelete="onVisitsGridBatchDelete"
                  @batchCancel="onVisitsGridBatchCancel"
                  @cellEdit="onVisitsGridCellEdit"
                  @batchAdd="onVisitsGridBatchAdd"
                  @keyPressed="onVisitsGridKeyPressed"
                  @rowSelected="onVisitGridRowSelected"
                  @visitEjsDropdownListEditTemplateChanged="onVisitEjsDropdownListEditTemplateChanged"
                  @gridCheckboxChanged="onGridCheckboxChanged"
                >
                </ejs-grid-wrapper>
                <!-- TODO : 그리드 집계는 aggregation 버그로 인해 넣지 않기로. 추후 별도의 영역에 통계 정보 넣을 예정 -->
              </div>
            </section>
            <!-- 아코디언 : accordion / 닫힘 : close -->
            <section
              class="article-section section-04"
              v-show="isShowContent(1)"
            >
              <div class="section-header">
                <div class="header-left">
                  <div class="header-title">요금 정산</div>
                </div>
                <div class="header-right">
                  <ul class="header-button">
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          @click.native="onPayLockCancelButtonClicked"
                          :ignore="isPopupOpened || stepIndex !== 1"
                          :disabled="isCheckinLayerThumbnail"
                          :is-custom-shortcut-button="true"
                          :shortcut-key="'FrontCheckinVisit.shortcuts.saveOpenPayLock'"
                          :shortcut="{ altKey: true, key:'Q' }"
                          class="dev-unlock-area"
                      >
                        LOCK해제
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="isChkinStatusByRain"
                          @click.native="checkOutConfirm()"
                      >
                        체크아웃
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :ignore="isPopupOpened || stepIndex !== 1"
                          :disabled="isCheckinLayerThumbnail || areAllTeamCheckout || commonMixinIsButtonDisableByAuth('frontChkinVisitReg')"
                          @click.native="onDivideByNClicked"
                      >
                        1/N 분할
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                        button-div="SAVE"
                        :is-icon-custom="true"
                        :ignore="isPopupOpened || stepIndex !== 1"
                        :disabled="isCheckinLayerThumbnail || areAllTeamCheckout || commonMixinIsButtonDisableByAuth('frontChkinVisitReg')"
                        @click.native="onSelectPaySumMenuItems({item: {value: 'PARTIAL'}})"
                      >
                        부분합산
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                        button-div="SAVE"
                        :is-icon-custom="true"
                        :ignore="isPopupOpened || stepIndex !== 1"
                        :disabled="isCheckinLayerThumbnail || areAllTeamCheckout || commonMixinIsButtonDisableByAuth('frontChkinVisitReg')"
                        @click.native="onSelectPaySumMenuItems({item: {value: 'ALL'}})"
                      >
                        전체합산
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          :disabled="areAllTeamCheckout"
                          :ignore="isPopupOpened || stepIndex !== 1"
                          @click.native="onNewPartialSumPopupClicked"
                          :is-custom-shortcut-button="true"
                          :shortcut="{key: 'F3'}"
                          :shortcut-key="'CheckinLayer.shortcut.PartialSum'"
                      >
                        금액 이동
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                        button-div="SAVE"
                        :disabled="isTeamCheckout"
                        @click="openDutchPayByItemPopup"
                      >
                        품목별 1/N
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :disabled="
                            areAllTeamCheckout ||
                            commonMixinIsButtonDisableByAuth('frontChkinVisitReg')
                          "
                          @click="onDivideCancelMenuSelect"
                      >
                        분할 취소
                      </erp-button>
                    </li>
<!--
                    <li>
                      <ejs-dropdownbutton
                        :items="divideCancelMeunItems"
                        :select="onDivideCancelMenuSelect"
                        :disabled="
                          areAllTeamCheckout ||
                          commonMixinIsButtonDisableByAuth('frontChkinVisitReg')
                        "
                        cssClass="lookup-button-dropdown"
                      >
                        분할 취소
                      </ejs-dropdownbutton>
                    </li>
-->

                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          :ignore="isPopupOpened || stepIndex !== 1"
                          @click.native="onPayReceiptPopupClicked"
                          :is-custom-shortcut-button="true"
                          :shortcut="{key: 'F4'}"
                          :shortcut-key="'CheckinLayer.shortcut.PayReceipt'"
                      >
                        정산 내역서
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          @click.native="onPaymentStatementPopupClicked"
                      >
                        지불 내역서
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="GET"
                          :is-icon-custom="true"
                          @click.native="onOpenReceiptClicked"
                      >
                        오픈 영수증
                      </erp-button>
                    </li>
                    <li>
                      <erp-button
                          button-div="SAVE"
                          :is-icon-custom="true"
                          @click.native="payProcess"
                          :disabled="
                          isCheckinLayerThumbnail ||
                          areAllTeamCheckout ||
                          isChkinStatusByRain"
                          :ignore="isPopupOpened || stepIndex !== 1"
                          :is-custom-shortcut-button="true"
                          :shortcut="{key: 'F6'}"
                          :shortcut-key="'CheckinLayer.shortcut.payProcess'"
                      >
                        정산
                      </erp-button>
                    </li>
                  </ul>
                  <ul class="header-check">
                    <li>
                      <label>
                        <input
                          type="radio"
                          name="divideDiv"
                          v-model="divideDiv"
                          value="T"
                          @change="getInfoByTeamOrGrp"
                        />
                        <i></i>
                        <div class="label">팀</div>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          name="divideDiv"
                          v-model="divideDiv"
                          value="G"
                          :disabled="!(teamInfo && teamInfo.grpNo)"
                          @change="getInfoByTeamOrGrp"
                        />
                        <i></i>
                        <div class="label">단체 분할</div>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          v-model="personFlag"
                          value="true"
                        />
                        <i></i>
                        <div class="label">개인 정산</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="section-body"
                @keydown.capture="onPayGridSectionBodyKeyDown"
              >
                <ejs-grid-wrapper
                  v-if="isShowContent(1)"
                  ref="payGrid"
                  :provides="payGridProvides"
                  :dataSource="paySettleTeamInfoList"
                  :columns="payGridColumns"
                  :aggregates="payGridAggregates"
                  :allowSorting="false"
                  :allowFiltering="false"
                  :enableHover="false"
                  :noColumnWidth="40"
                  :selectionSettings="payGridColumnOptions.selectionSettings"
                  :editSettings="payGridColumnOptions.editSettings"
                  @cellEdit="payGridCellEdit"
                  @recordClick="onPayGridRecordClick"
                  @headerCellInfo="payGridHeaderCellInfo"
                  @queryCellInfo="payGridQueryCellInfo"
                  @gridCheckboxChanged="payGridCheckboxChanged"
                >
                </ejs-grid-wrapper>
              </div>
            </section>
            <!-- 아코디언 : accordion / 닫힘 : close -->
            <section
              class="article-section section-05"
              v-show="isShowContent(2)"
            >
              <div class="section-header">
                <div class="header-left">
                  <div class="header-title">업장 매출</div>
                </div>
                <div class="header-right">
                  <ul class="header-check">
                    <li>
                      <div style="padding-right: 6px;">그룹구분</div>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          name="salesGroupDiv"
                          v-model="storeGridsalesGroupDiv"
                          value="DATE"
                        />
                        <i></i>
                        <div class="label">일자별</div>
                      </label>
                    </li>
                    <li style="margin-right: 20px;">
                      <label>
                        <input
                          type="radio"
                          name="salesGroupDiv"
                          v-model="storeGridsalesGroupDiv"
                          value="STORE"
                        />
                        <i></i>
                        <div class="label">매장별</div>
                      </label>
                    </li>
                    <li>
                      <div style="padding-right: 6px;">팀/단체 구분</div>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          name="divideDiv2"
                          v-model="divideDiv"
                          value="T"
                          @change="getInfoByTeamOrGrp"
                        />
                        <i></i>
                        <div class="label">팀</div>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="radio"
                          name="divideDiv2"
                          v-model="divideDiv"
                          value="G"
                          :disabled="!(teamInfo && teamInfo.grpNo)"
                          @change="getInfoByTeamOrGrp"
                        />
                        <i></i>
                        <div class="label">단체</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="section-body"
                @keydown.capture="onStoreGridSectionBodyKeyDown"
              >
                <!-- TODO : rouping 적용 된 그리드가 보이지 않다가 보이게 되는(isShow 사용) 경우에 버그 발생하여, 보일 경우에만 생성되도록 v-if 적용. 개선 필요 -->
                <ejs-grid-wrapper
                  v-if="isShowContent(2)"
                  ref="storeGrid"
                  v-bind="storeGridOptions"
                  :dataSource="storeSaleInfoList"
                  @headerCellInfo="storeGridHeaderCellInfo"
                  @queryCellInfo="storeGridQueryCellInfo"
                  @recordClick="storeGridClicked"
                >
                </ejs-grid-wrapper>
              </div>
            </section>
          </article>
          <article class="body-article article-03" v-show="isShowContent(null)">
            <!-- 아코디언 : accordion / 닫힘 : close -->
            <section
              class="article-section section-06 accordion"
              :class="{ close: !isPayInfoGridVisible }"
            >
              <div class="section-header">
                <div class="header-left">
                  <div class="header-title" @click="payInfoHeaderClick">
                    정산 정보
                  </div>
                </div>
              </div>
              <div
                class="section-body"
                @keydown.capture="onPayInfoGridSectionBodyKeyDown"
              >
                <ejs-grid-wrapper
                  ref="payInfoGrid"
                  :provides="payInfoGridProvides"
                  :dataSource="payInfoList"
                  :columns="payInfoGridColumns"
                  :aggregates="payInfoGridAggregates"
                  :height="169"
                  :enableHover="false"
                  :editSettings="payInfoGridOptions.editSettings"
                  @headerCellInfo="payInfoGridHeaderCellInfo"
                  @queryCellInfo="payInfoGridQueryCellInfo"
                  @actionComplete="onPayInfoGridActionComplete"
                  @recordClick="payInfoGridClicked"
                >
                </ejs-grid-wrapper>
              </div>
            </section>
          </article>
        </div>
      </div>
    </div>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      :is-checkin-layer-open="isCheckinLayerOpen"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <caddieFeePopup
      v-if="isCaddieFeePopupOpen"
      ref="caddieFeePopup"
      @popupClosed="caddieFeePopupClosed"
    />
    <discount-popup
      v-if="isDiscountPopupOpen"
      ref="discountPopup"
      @popupClosed="discountPopupClosed"
    />
    <visitsCopyPopup
      v-if="isVisitsCopyPopupOpen"
      ref="visitsCopyPopup"
      @popupClosed="visitsCopyPopupClosed"
    />
    <sale-info-popup
      v-if="isSaleInfoPopupOpen"
      ref="saleInfoPopup"
      @popupClosed="saleInfoPopupClosed"
    />
    <divide-by-n-popup
      v-if="isDivideByNPopupOpen"
      ref="divideByNPopup"
      @popupClosed="divideByNPopupClosed"
    />
    <partial-sum-popup
      v-if="isPartialSumPopupOpen"
      ref="partialSumPopup"
      @popupClosed="partialSumPopupClosed"
    />
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    />
    <pay-receipt-popup
      v-if="isPayReceiptPopupOpen"
      ref="payReceiptPopup"
      @popupClosed="payReceiptPopupClosed"
    />
    <pay-popup
      v-if="isPayPopupOpen"
      ref="payPopup"
      @popupClosed="payPopupClosed"
    />
    <locker-issue-popup
      v-if="isLockerIssuePopupOpen"
      ref="lockerIssuePopup"
      @popupClosed="lockerIssuePopupClosed"
    />
    <open-receipt-popup
      v-if="isOpenReceiptPopupOpen"
      ref="openReceiptPopup"
      @popupClosed="openReceiptPopupClosed"
    />
    <re-issue-receipt-popup
      v-if="isReIssueReceiptPopupOpen"
      ref="reIssueReceiptPopup"
      @popupClosed="reIssueReceiptPopupClosed"
    />
    <change-payer-popup
      v-if="isChangePayerPopupOpen"
      ref="changePayerPopup"
      @popupClosed="changePayerPopupClosed"
      @popupConfirmed="changePayerPopupConfirmed"
      @popupConfirmed2="changePayerPopupConfirmed2"
    />
    <cart-discount-popup
      v-if="isCartDiscountPopupOpen"
      :start-course="teamInfo.startCourse"
      :start-time="teamInfo.startTime"
      :grp-name="teamInfo.grpName"
      ref="cartDiscountPopup"
      @submit="onCartDiscountPopupSubmit"
      @close="cartDiscountPopupClose"
    />
    <locker-status-popup
      v-if="isLockerStatusPopupOpen"
      ref="lockerStatusPopup"
      @popupClosed="onLockerStatusPopupClose"
    />
    <deposit-use-receipt-popup
      v-if="isDepositUseReceiptPopupOpen"
      ref="depositUseReceiptPopup"
      @popupClosed="depositUseReceiptPopupClosed"
    />
    <partner-certification-popup
      v-if="isPartnerCertificationPopupOpen"
      ref="partnerCertificationPopup"
      @popupClosed="partnerCertificationPopupClosed"
    />
    <payment-statement-popup
      v-if="isPaymentStatementPopupOpen"
      ref="paymentStatementPopup"
      @popupClosed="paymentStatementPopupClosed"
    />
    <hole-add-popup
      ref="holeAddPopup"
      v-if="isHoleAddPopupOpen"
      @popupClosed="onHoleAddPopupClosed"
    />
    <visit-delete-confirm-popup
      ref="visitDeleteConfirmPopup"
      v-if="isVisitDeleteConfirmPopupOpen"
      @popupClosed="onVisitDeleteConfirmPopupClosed"
      @popupConfirm="onVisitDeleteConfirmPopupConfirm"
      @dataRefresh="onVisitDeleteConfirmPopupDataRefresh"
    />
    <take-companion-member-popup
      ref="takeCompanionMemberPopup"
      v-if="isTakeCompanionMemberPopupOpen"
      @popupClosed="onTakeCompanionMemberPopupClosed"
      @popupConfirm="onTakeCompanionMemberPopupConfirm"
    />
    <member-visit-status-popup
      ref="memberVisitStatusPopup"
      v-if="isMemberVisitStatusPopup"
      @popupClosed="onMemberVisitStatusPopupClosed"
    />
    <new-partial-sum-popup
      ref="newPartialSumPopup"
      v-if="isNewPartialSumPopup"
      @popupClosed="onNewPartialSumPopupClosed"
    />
    <blacklist-popup
        v-if="isBlacklistPopupOpen"
        ref="blacklistPopup"
        @popupClosed="onBlacklistPopupClose"
    />
    <dutch-pay-by-item-popup
        v-if="isDutchPayByItemPopupOpen"
        ref="dutchPayByItemPopup"
        @popupClosed="onDutchPayByItemPopupClose"
    />
    <member-info-popup
        v-if="isMemberInfoPopupOpen"
        ref="memberInputPopup"
        :member-id="memberInfoMemberId"
        :member-no="memberInfoMemberNo"
        @close="onMemberInfoPopupClosed"
    />
    <sms-send-popup
        v-if="isSmsSendPopupOpen"
        ref="smsSendPopup"
        @popupClosed="smsSendPopupClosed"
    />
    <history-popup
        v-if="isHistoryPopupOpen"
        ref="historyPopup"
        @popupClosed="historyPopupClose"
    />
  </div>

</template>


<script>
import ErpButton from "@/components/button/ErpButton.vue";
import Vue from "vue";
import maxBy from "lodash/maxBy";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import moment from "moment";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputTime from "@/components/common/datetime/InputTime";
import inputNumber from "@/components/common/InputNumber";
import { deepDiffs } from "@/utils/ObjectUtil";
import { getFormattedPhoneNumber } from "@/utils/string";

import {
  Edit,
  Resize,
  ForeignKey,
  Aggregate,
  Group,
} from "@syncfusion/ej2-vue-grids";

import payDivideTemplate from "./PayDivideTemplate";
import gridVisitEjsDropdownlistEditTemplate from "@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate";

import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetComName,
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeByIdxAttrb, commonCodesGetSortNo, commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import {
  gridUtilGetTelColumnAccess,
  calculateApplyAmt, gridUtilGetMemberNoColumnAccess,
} from "@/utils/gridUtil";
import { isReservedLockerNo } from "@/utils/lockerUtil";
import { memberNoFormatter } from "@/utils/formatter";
import {
  formNotPassedRequiredCheck,
  validateFormRefs,
} from "@/utils/formUtil"; // TODO: 정리

import groupPopup from "@/views/golf-reservation/popup/GroupPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import payReceiptPopup from "@/views/common/PayReceiptPopup";
import caddieFeePopup from "./popup/CaddieFeePopup";
import discountPopup from "./popup/DiscountPopup";
import visitsCopyPopup from "./popup/VisitsCopyPopup";
import saleInfoPopup from "./popup/SaleInfoPopup";
import divideByNPopup from "./popup/DivideByNPopup";
import partialSumPopup from "./popup/PartialSumPopup";
import payCancelPopup from "../../components/popups/PayCancelPopup";
import payPopup from "../common/PayPopup";
import lockerIssuePopup from "./popup/LockerIssuePopup";
import openReceiptPopup from "./popup/OpenReceiptPopup";
import reIssueReceiptPopup from "../../components/popups/ReIssueReceiptPopup";
import changePayerPopup from "./popup/ChangePayerPopup";
import lockerStatusPopup from "./popup/LockerStatusPopup";
import depositUseReceiptPopup from "@/views/credit-management/popup/DepositUseReceiptPopup";
import partnerCertificationPopup from "@/views/common/PartnerCertificationPopup";
import paymentStatementPopup from "@/views/checkin/popup/PaymentStatementPopup";
import holeAddPopup from "./popup/HoleAddPopup";
import visitDeleteConfirmPopup from "./popup/VisitDeleteConfirmPopup";
import takeCompanionMemberPopup from "./popup/TakeCompanionMemberPopup";
import MemberVisitStatusPopup from "@/views/member-management/popup/MemberVisitStatusPopup";
import NewPartialSumPopup from "@/views/checkin/popup/NewPartialSumPopup";
import blacklistPopup from "@/views/golf-reservation/popup/BlacklistPopup";
import DutchPayByItemPopup from "./popup/DutchPayByItemPopup.vue";
import MemberInfoPopup from "@/views/member-management/MemberInfoPopup";
import InputTextarea from "@/components/common/text/InputTextarea.vue";
import HistoryPopup from "@/views/common/HistoryPopup.vue";

import { getGroupList } from "@/api/group";
import { getMemberList } from "@/api/member";
import { getPaymentPrice, getFixedPaymentPrice } from "@/api/common";
import {lockerStatusSetting, putPayerChange, putPayerChange2} from "@/api/frontManagement";
import {
  getCheckinInfo,
  saveLockerCaddieFee,
  checkOut,
  divideCancel, savePartialSum, getCheckinHistory,
} from "@/api/frontManagementCheckin";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import CartDiscountPopup from "@/views/checkin/popup/CartDiscountPopup";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import {
  addShortcut,
  keypressEventEqualsShortcut,
  removeShortcut,
  SHORTCUT_KEYS,
} from "@/utils/KeyboardUtil";
import {mapGetters, mapMutations, mapState} from 'vuex';

import vipIcon from "@/assets/images/common/vip.png";
import {getDeviceInfo} from "@/utils/device";
import {openNewWindow} from "@/utils/appInfo";
import {getReservationCheck} from "@/api/reservation";
import smsSendPopup from "@/views/common/SmsSendPopup.vue";
import BillPrintUtil from "@/utils/billPrintUtil";


const CHECKIN_LAYER_DISPLAY_NONE = 0;
const CHECKIN_LAYER_THUMBNAIL = 1;
const CHECKIN_LAYER_CONTENT = 2;

// const MAX_VISIT_INFO_ADD_EMPTY_MEMBER_NUM = 4; // 이 건 미만일 경우, 이 건까지 빈 내장객을 채운다

// const VISIT_GRD_VISIT_ID_COLUMN_IDX = 0
// const VISIT_GRD_VISIT_NAME_COLUMN_IDX = 3
// const VISIT_GRID_VISIT_SNO_COLUMN_IDX = 5
// const VISIT_GRID_LOCKER_NO_COLUMN_IDX = 6

const PAY_INFO_GRID_MENU_CANCEL = "cancel";
const PAY_INFO_GRID_MENU_REPUBLIC = "republic";
const PAY_INFO_GRID_CREDIT_DEF_VERBOSE = "verbose";

// 내장객 이름, 락커 컬럼 포커싱 시 엔터키를 쳤을 때의 rule 정의
const visitNameLockerNoEnterKeyRule = {
  HORIZONTAL: 1, // 내장객 -> 락카 -> 밑의 row 내장객 -> 락카
  VERTICAL: 2, // 내장객 -> 내장객 -> .. --> 맨 밑의 내장객 -> 첫 번째 row의 락카 -> 락카 -> ... -> 맨 밑의 락카
};

// 내장객-락카 enter key 이동 rule 결정
const visitNameLockerNoEnterKey = visitNameLockerNoEnterKeyRule.HORIZONTAL;

// 저장 시 체크할 최소 입장료 값 기준
const minGreenFee = 0;

const SHORTCUT_CHECKIN = {
  key: "CheckInLayer.shortcuts.checkin",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F7,
  },
};
const SHORTCUT_CHECKOUT = {
  key: "CheckInLayer.shortcuts.checkout",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F8,
  },
};
const SHORTCUT_CHECK_IN_OUT_LAYER_THUMBNAIL = {
  key: "CheckInLayer.shortcuts.checkInOutLayerThumbnail",
  shortcut: {
    ctrlKey: true,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.ArrowDown,
  },
};
const SHORTCUT_CHECK_IN_OUT_LAYER_CONTENT = {
  key: "CheckInLayer.shortcuts.checkInOutLayerContent",
  shortcut: {
    ctrlKey: true,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.ArrowUp,
  },
};

export default {
  name: "checkinLayer",
  components: {
    HistoryPopup,
    smsSendPopup,
    DutchPayByItemPopup,
    InputText,
    CartDiscountPopup,
    ejsGridWrapper,
    InputTime,
    inputNumber,
    groupPopup,
    memberSelectPopup,
    caddieFeePopup,
    discountPopup,
    visitsCopyPopup,
    saleInfoPopup,
    divideByNPopup,
    partialSumPopup,
    payCancelPopup,
    payReceiptPopup,
    blacklistPopup,
    payPopup,
    lockerIssuePopup,
    openReceiptPopup,
    reIssueReceiptPopup,
    changePayerPopup,
    lockerStatusPopup,
    depositUseReceiptPopup,
    partnerCertificationPopup,
    paymentStatementPopup,
    holeAddPopup,
    visitDeleteConfirmPopup,
    takeCompanionMemberPopup,
    MemberVisitStatusPopup,
    NewPartialSumPopup,
    ErpButton,
    MemberInfoPopup,
    InputTextarea
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    bsnDate: {
      type: String,
      default: null,
    },
    bsnDateInfo: {
      type: Object,
      default: null,
    },
    isFindVisitPopupOpen: {
      type: Boolean,
      default: false,
    },
    isBackLockerStatusPopupOpen: {
      type: Boolean,
      default: false,
    },
    isPayLockYesNoCancelPopupOpen: {
      type: Boolean,
      default: false,
    },
    savedSearchVisitName: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      /*
       내장객 그리드 생성 여부.
       v-show로 감춰진 div 내에 grid가 있으면 하단이 깨지는 문제 때문에,
       STEP 01의 상세 화면(내장객 그리드 포함)이 display 될 때 true로 바꾸어서 내장객 그리드를 생성함
      */
      vipIcon,

      //회원정보 조회용
      memberInfoMemberId: null,
      memberInfoMemberNo: null,

      firstMemoOpen:false,
      isVisitGridCreate: false,
      visitsLength: 0, // 내장객 그리드 총 명 TODO : 내장객 그리드 집계 영역에 넣어야 함
      visitsTotalPay: 0, // 내장객 그리드 total 금액 TODO : 내장객 그리드 집계 영역에 넣어야 함
      checkinLayerStatus: CHECKIN_LAYER_DISPLAY_NONE,
      stepIndex: 0,
      rowIndexAfterTakeCmpnMember: null, // 동반자 가져오기 이후 포커스될 row index
      addedCmpnMemberCount: 0, // 동반자 가져온 후 카운팅
      isAlreadyAssignedLockNoFlag: false, // 동반자 가져온 후 기존 row를 다시 그리는 과정에서 이미 부여 받은 락카번호는 api 검사하지 않음
      timeData: null, // 내장객 grid에서 선택한 타임 정보
      resveCmpnFlag: false, // 내장객 grid에서 선택한 동반자 자동 체크인 여부
      timeId: null, // 새로운 time을 선택하였는지 여부 확인을 위함
      teamInfo: null,
      teamInfoValidateRefList: {
        startTime: { required: true },
      },
      isDutchPayByItemPopupOpen: false,
      cartOptions: commonCodesGetCommonCode("CART_KIND"),
      divideDiv: "T", // 팀/단체 선택. 단체이면 'G' / 팀이면 'T'
      originalTeamInfo: null,
      visitsGridSelectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
      },
      visitsGridProvides: [Edit, Resize, ForeignKey, Aggregate, Group],
      gridVisitEjsDropdownlistEditTemplateEventBus: new Vue(),
      visitsGridSelectedIdx: -1,
      visitsGridColumns: [
        {
          field: "visitId",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "visitSno",
          headerText: "NO",
          allowEditing: true, // 힐마루 내장객 순번 직접 수정. // 2020-08-19 회의에서 결정
          minWidth: 16,
          width: 47,
          type: "string",
          isNumericType: true,
          inputNumberProperty: { maxLength: 10 },
          textAlign: "center",
        },
        {
          field: "startTime",
          headerText: "시간",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          type: "string",
          textAlign: "center",
        },
        {
          field: "startCourse",
          headerText: "코스",
          allowEditing: false,
          minWidth: 16,
          width: 70,
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          type: "string",
        },
        {
          field: "visitConfirmFlag",
          headerText: "확정",
          minWidth: 16,
          width: 50,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "visitName",
          headerText: "내장객",
          allowEditing: true,
          minWidth: 16,
          width: 90,
          type: "string",
        },
        {
          field: "visitNameIcon",
          allowEditing: false,
          headerText: "",
          allowFiltering: false,
          minWidth: 16,
          width: 32,
          type: "string",
        },
        {
          field: "contactTel",
          headerText: "연락처",
          minWidth: 16,
          width: 99,
          type: "string",
          editType: "telephoneedit",
          valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: "lockerSmsSendCount",
          headerText: "SMS",
          minWidth: 16,
          width: 50,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
          allowEditing: false,
        },
        {
          field: "lockerNo",
          headerText: "락카",
          minWidth: 16,
          width: 55,
          type: "string",
          textAlign: "center",
        },
        {
          field: "lockerPrtNo",
          headerText: "락카프린트넘버",
          minWidth: 16,
          width: 55,
          type: "string",
          textAlign: "center",
          visible: false,
        },
        {
          field: "sexCode",
          headerText: "성별",
          minWidth: 16,
          width: 50,
          type: "string",
          textAlign: "center",
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "sexCode",
              commonCodesGetCommonCode("SEX_CODE", true),
              this.$refs.visitsGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("SEX_CODE").find(
              (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "center",
          valueAccessor: (field, data) =>
              gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "memberGrade",
          headerText: "회원등급",
          minWidth: 16,
          width: 120,
          type: "string",
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "memberGrade",
              commonCodesGetCommonCode("MEMBER_GRADE", true),
              this.$refs.visitsGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("MEMBER_GRADE").find(
              (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "paymtCode",
          headerText: "요금구분",
          minWidth: 16,
          width: 120,
          type: "string",
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "paymtCode",
              commonCodesGetCommonCode("PAYMT_CODE", true),
              this.$refs.visitsGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("PAYMT_CODE").find(
              (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "holeDiv",
          headerText: "홀구분",
          minWidth: 16,
          width: 73,
          type: "string",
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "holeDiv",
              commonCodesGetCommonCode("HOLE_DIV", true),
              this.$refs.visitsGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("HOLE_DIV").find(
              (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "mmbsCrtfcResultFlag",
          headerText: "인증",
          minWidth: 16,
          width: 60,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
          visible: false,
        },
        {
          field: "hasNormalDiscount",
          headerText: "일반할인",
          minWidth: 16,
          width: 56,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "hasCouponDiscount",
          headerText: "쿠폰할인",
          minWidth: 16,
          width: 56,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "birthDay",
          headerText: "생일",
          width: 90,
          visible: false,
        },
        {
          field: "discountIcon",
          headerText: "할인",
          allowEditing: false,
          minWidth: 16,
          width: 40,
          type: "string",
        },
        {
          field: "greenFeeDc",
          headerText: "입장료",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
        },
        {
          field: "cartAmt",
          headerText: "카트대여",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "prpayFlag",
          headerText: "선불",
          minWidth: 16,
          width: 56,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "mmbsCrtfcResult",
          headerText: "인증결과",
          width: 90,
          allowEditing: false,
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "MMBS_CRTFC_RESULT",
          edit: { params: { sortOrder: "none" } },
          visible: false,
        },
        {
          field: "bagName",
          headerText: "백네임",
          minWidth: 16,
          width: 90,
          type: "string"
        },
        {
          field: "chkinTime",
          headerText: "C/I",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          type: "string",
        },
        {
          field: "chkoutTime",
          headerText: "C/O",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          type: "string",
        },
        {
          field: "insertName",
          headerText: "입력자",
          width: 110,
          type: "string",
          textAlign: "center",
        },
        {
          field: "insertDt",
          headerText: "입력일시",
          width: 130,
          type: "string",
          textAlign: "center",
        },
        {
          field: "cartDcAmt",
          headerText: "카트할인금액",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "dcContents",
          headerText: "할인내용",
          minWidth: 16,
          width: 260,
          type: "string",
        },
        {
          field: "nativeDiv",
          headerText: "내국인",
          minWidth: 16,
          width: 60,
          type: "string",
          editTemplate: () =>
            gridVisitEjsDropdownlistEditTemplate(
              "nativeDiv",
              commonCodesGetCommonCode("NATIVE_DIV", true),
              this.$refs.visitsGrid,
              this.gridVisitEjsDropdownlistEditTemplateEventBus
            ),
          valueAccessor: (field, data) => {
            const commonCode = commonCodesGetCommonCode("NATIVE_DIV").find(
              (commonCode) => commonCode.comCode === data[field]
            );
            if (commonCode) {
              return commonCode.comName;
            } else {
              return null;
            }
          },
        },
        {
          field: "updateName",
          headerText: "등록자",
          width: 110,
          type: "string",
          textAlign: "center",
        },
        {
          field: "updateDt",
          headerText: "등록일시",
          width: 130,
          type: "string",
          textAlign: "center",
        },
        {
          field: "greenFeeInfo", // 요금 및 입장료 관련 정보
          allowEditing: false,
          visible: false,
        },
        {
          field: "dcList", // 할인 정보
          allowEditing: false,
          visible: false,
        },
        {
          field: "membershipId",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "visitDate",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "memberDiv",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "promtnDelFlag",
          allowEditing: false,
          visible: false,
          type: "boolean",
        },
        {
          field: "sector",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "sectorPrtMale",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        {
          field: "sectorPrtFemale",
          allowEditing: false,
          visible: false,
          type: "string",
        },
        /*
        {
          field: "joinGroupNo",
          headerText: "J",
          minWidth: 16,
          width: 40,
          type: "string",
          maxLength: 10,
        },
        {
          field: "cartRentInfo",
          allowEditing: false,
          visible: false,
          type: "string"
        }
        */
      ],
      visitsGridAggregates: [
        {
          columns: [
            {
              field: "startTime",
              aggregationType: "TotalCaption",
              customAggregate: "1팀",
            },
            {
              field: "visitName",
              aggregationType: "TotalCount",
              stringFormat: "${value}명",
            },
            {
              field: "greenFeeDc",
              aggregationType: "TotalCaption",
              customAggregate: () => {
                const gridData = this.$refs.visitsGrid.getGridBatchData();
                const sum = gridData
                  .map((visit) => parseInt(visit.greenFeeDc.replace(/,/g, "")))
                  .reduce((acc, cur) => cur + acc, 0);
                return sum;
              },
            },
            {
              field: "cartAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "cartDcAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      visitsGridValidationRules: {
        visitName: {
          maxLength: 50,
          custom: {
            method: (record) => {
              if (record.visitId) {
                return !formNotPassedRequiredCheck(record.visitName);
              }
              return true;
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        lockerNo: {
          maxLength: 50,
          custom: {
            method: (record) => {
              if (
                record.visitId ||
                !formNotPassedRequiredCheck(record.visitName)
              ) {
                return !formNotPassedRequiredCheck(record.lockerNo);
              }
              return true;
            },
            message: "main.validationMessage.requiredMessage",
          },
        },
        joinGroupNo: {
          maxLength: 50,
        },
        contactTel: {
          maxLength: 50,
        },
        dcContents: {
          maxLength: 400,
        },
      },
      visitInfoList: [],
      resveVisitInfoList: [],
      thumbnailVisitInfoList: [], // 섬네일에서 출력할 내장객 정보. grid와 sync 시켜야 한다
      thumbnailPayInfoList: [], // 섬네일에서 출력할 요금정산 정보. grid와 sync 시켜야 한다
      thumbnailStoreSaleInfoList: [], // 섬네일에서 출력할 업장매출 정보. grid와 sync 시켜야 한다
      payGridProvides: [Edit, Resize, ForeignKey, Aggregate, Group],
      paySettleTeamInfoList: [],
      isTeamInfoHoleDivKeydownCaptureLock: false,
      payGridColumns: [
        {
          field: "visitId",
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          type: "string",
          textAlign: "center",
        },
        {
          field: "startCourse",
          headerText: "코스",
          allowEditing: false,
          minWidth: 16,
          width: 70,
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          type: "string",
        },
        {
          field: "visitName",
          headerText: "내장객",
          allowEditing: false,
          minWidth: 16,
          width: 115,
          type: "string",
        },
        {
          field: "lockerReturnFlag",
          headerText: "락카반납",
          minWidth: 16,
          width: 65,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "lockerNo",
          headerText: "락카",
          allowEditing: false,
          minWidth: 16,
          width: 50,
          type: "string",
          textAlign: "center",
        },
        {
          field: "holeDiv",
          headerText: "홀구분",
          allowEditing: false,
          minWidth: 16,
          width: 55,
          type: "string",
          isCommonCodeField: true,
          groupCode: "HOLE_DIV",
        },
        // {
        //   field: "joinGroupNo",
        //   headerText: "J",
        //   allowEditing: false,
        //   minWidth: 16,
        //   width: 40,
        //   type: "string",
        // },
        {
          field: "paymtCode",
          headerText: "요금구분",
          allowEditing: false,
          minWidth: 16,
          width: 100,
          type: "string",
          isCommonCodeField: true,
          groupCode: "PAYMT_CODE",
        },
        {
          field: "greenFeeAmt",
          headerText: "입장료",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "cartAmt",
          headerText: "카트대여",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "rentAmt",
          headerText: "일반대여",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "grillAmt",
          headerText: "대식당",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "teeAmt",
          headerText: "그늘집",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "shopAmt",
          headerText: "프로샵",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "etcAmt",
          headerText: "기타매출",
          allowEditing: false,
          minWidth: 16,
          width: 85,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "totAmt",
          headerText: "매출합계",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "payAmt",
          headerText: "총정산액",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "noPayAmt",
          headerText: "미정산액",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "cardAmt",
          headerText: "카드",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "cashAmt",
          headerText: "현금",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "caddieFeePymntFlag",
          headerText: "캐디피",
          minWidth: 16,
          width: 55,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
        {
          field: "chkoutTime",
          headerText: "C/O",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          type: "number",
        },
        {
          field: "visitOptFlag",
          visible: false,
        },
      ],
      payGridAggregates: [
        {
          columns: [
            {
              field: "startTime",
              aggregationType: "TotalCaption",
              customAggregate: "전체",
            },
            {
              field: "startCourse",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridTeamNumberAggregateFn,
            },
            {
              field: "visitName",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridTotalCountAggregateFn,
            },
            {
              field: "greenFeeAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "cartAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "rentAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "grillAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "teeAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "shopAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "etcAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "cfrontAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "totAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "noPayAmt",
              aggregationType: "TotalSum",
              textColor: "#FF0000",
            },
          ],
        },
        {
          columns: [
            {
              field: "startTime",
              aggregationType: "TotalCaption",
              customAggregate: "분할",
            },
            {
              field: "startCourse",
              aggregationType: "TotalCaption",
              customAggregate: "인원",
            },
            {
              field: "visitName",
              aggregationType: "TotalCaption",
              footerTemplate: () => {
                return {
                  template: {
                    extends: payDivideTemplate,
                    propsData: {
                      contentProp: "Custom",
                      min: 1,
                      max: this.paySettleTeamInfoList.length,
                      defaultValue: this.paySettleTeamInfoDivideNumber,
                      divideCallback: this.payGridViewDivide,
                    },
                  },
                };
              },
            },
            {
              field: "lockerReturnFlag",
              aggregationType: "TotalCaption",
              textAlign: "left",
              customAggregate: "계산기",
            },
            {
              field: "greenFeeAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridGreenFeeDivideByNAggregateFn,
            },
            {
              field: "cartAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridCartAmtDivideByNAggregateFn,
            },
            {
              field: "rentAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridRentAmtDivideByNAggregateFn,
            },
            {
              field: "grillAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridGrillAmtDivideByNAggregateFn,
            },
            {
              field: "teeAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridTeeAmtDivideByNAggregateFn,
            },
            {
              field: "shopAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridShopAmtDivideByNAggregateFn,
            },
            {
              field: "etcAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridEtcAmtDivideByNAggregateFn,
            },
            {
              field: "cfrontAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridCfrontAmtDivideByNAggregateFn,
            },
            {
              field: "totAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payGridTotAmtDivideByNAggregateFn,
            },
          ],
        },
      ],
      paySettleTeamInfoDivideNumber: 1, // 분할 금액 보여줄 경우의 분할 명 수
      storeSaleInfoList: [],
      storeGridAggregates: [
        {
          columns: [
            {
              field: "productName",
              aggregationType: "GroupCount",
              stringFormat: "${value} 건",
            },
            {
              field: "totAmt",
              aggregationType: "GroupSum",
            },
          ],
        },
        {
          columns: [
            {
              field: "storeName",
              aggregationType: "TotalCaption",
              customAggregate: "식음매출",
            },
            {
              field: "totAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.storeGridFootTotalAmtAggregateFn,
            },
          ],
        },
        {
          columns: [
            {
              field: "storeName",
              aggregationType: "TotalCaption",
              customAggregate: "매출합계",
            },
            {
              field: "totAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      payInfoGridProvides: [Resize, ForeignKey, Aggregate],
      payInfoList: [],
      checkoutDiv: 0,
      payInfoGridColumns: [
        {
          field: "payId",
          isPrimaryKey: true,
          visible: false,
          type: "number",
        },
        {
          field: "teamChkoutFlag",
          visible: false,
        },
        {
          field: "startTime",
          headerText: "시간",
          width: 60,
          type: "string",
          textAlign: "center",
        },
        {
          field: "startCourse",
          headerText: "코스",
          width: 70,
          type: "string",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
        },
        {
          field: "visitName",
          headerText: "내장객",
          width: 90,
          type: "string",
        },
        {
          field: "payerNameIcon",
          headerText: "",
          width: 32,
          type: "string",
        },
        {
          field: "prpayFrpyDiv",
          headerText: "선/후불",
          width: 70,
          type: "string",
          isCommonCodeField: true,
          groupCode: "PRPAY_FRPY_DIV",
        },
        {
          field: "storeCode",
          headerText: "매장",
          width: 90,
          type: "string",
          isCommonCodeField: true,
          groupCode: "STORE_CODE",
        },
        {
          field: "payDiv",
          headerText: "정산 구분",
          width: 90,
          type: "string",
          isCommonCodeField: true,
          groupCode: "PAY_DIV",
        },
        {
          field: "delStatus",
          headerText: "상태",
          width: 90,
          type: "string",
        },
        {
          field: "purcName",
          headerText: "카드사",
          width: 120,
          type: "string",
        },
        {
          field: "approvalDivName",
          headerText: "승인상태",
          width: 90,
          type: "string",
        },
        {
          field: "approvalNo",
          headerText: "승인번호",
          width: 90,
          type: "string",
          textAlign: "center",
        },
        {
          field: "cardNo",
          headerText: "카드번호",
          width: 140,
          type: "string",
        },
        {
          field: "payAmt",
          headerText: "정산금액",
          width: 90,
          type: "string",
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "insertName",
          headerText: "정산자",
          width: 90,
          type: "string",
        },
        {
          field: "insertDt",
          headerText: "정산일시",
          width: 150,
          type: "string",
        },
        {
          field: "dropdownMenu",
          headerText: "",
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component("payInfoGridDropdownTemplate", {
                  template:
                    "<div class='dropdownbutton' style=''><ejs-dropdownbutton :items='menuItems' :select='onMenuSelect'></ejs-dropdownbutton></div>",
                  props: ["payInfoGridMenuSelectedCallback"],
                  data() {
                    return {
                      menuItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    const isPayCancel = this.data.delFlag;
                    const isCredit = this.data.payDiv === "CREDIT" || this.data.payDiv === "SELF" ; // 미수
                    if(isPayCancel) {
                      this.menuItems = isCredit ? [] : [{ text: "영수증 재발행", value: PAY_INFO_GRID_MENU_REPUBLIC }];
                    } else {
                      switch (this.data.payDiv){
                        case "CREDIT":
                        case "SELF":
                          this.menuItems = [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                          ];
                          break;

                        case "CREDIT_DEF":
                          this.menuItems = [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                            {
                              text: '상세정보 보기',
                              value: PAY_INFO_GRID_CREDIT_DEF_VERBOSE
                            }
                          ];
                          break;

                        default:
                          this.menuItems = [
                            {
                              text: "정산취소",
                              value: PAY_INFO_GRID_MENU_CANCEL,
                            },
                            {
                              text: "영수증 재발행",
                              value: PAY_INFO_GRID_MENU_REPUBLIC,
                            },
                          ];
                          break;
                      }
                    }
                  },
                  methods: {
                    onMenuSelect: function (args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.payInfoGridMenuSelectedCallback(eventParam);
                      // this.$EventBus.$emit('payInfoGridMenuSelected', eventParam);
                    },
                  },
                }),
                propsData: {
                  payInfoGridMenuSelectedCallback: this
                    .onPayInfoGridMenuSelected,
                },
              },
            };
          },
          type: "string",
          allowFiltering: false,
        },
        {
          field: "remarks",
          headerText: "비고",
          type: "string",
        },
      ],
      payInfoGridAggregates: [
        {
          columns: [
            {
              field: "visitName",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "payAmt",
              aggregationType: "TotalCaption",
              customAggregate: this.payInfopayAmtTotalCaptionAggregateFn,
            },
          ],
        },
      ],
      isApprovalDivFiltered: false, // 정산 상태 기본 filter 처리되었는지 여부.
      isGridFormCreated: false, // 그리드 폼이 생성되었는지 여부. cellEdit, beforeBatchAdd 이벤트 발생 시 true로 바뀜. 그리드 form validation을 위함
      originalVisitInfoList: [],
      resveInfo: null,
      originalResveInfo: null,
      memberImageInfo: [],
      originalMemberImageInfo: [],
      caddieInfoList: [],
      originalCaddieInfoList: [],
      commonOptions: {
        commonCodeField: { text: "comName", value: "comCode" },
      },
      teamInfoOptions: {
        startCourseOptions: [],
        partDivOptions: [],
        teamHoleDivOptions: [],
        resveKindOptions: [],
      },
      teamInfoTabOptions: {
        tabAnimation: {
          previous: { effect: "None" },
          next: { effect: "None" },
        },
      },
      visitGridColumnOptions: {
        resveChannel: commonCodesGetCommonCode("RESVE_CHANNEL"),
        numericParams: {
          // TODO : 공통모듈
          params: {
            validateDecimalOnType: true,
            decimals: 0,
            format: "N",
          },
        },
        sortOrderNoneParams: { params: { sortOrder: "none" } },
      },
      payGridColumnOptions: {
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          checkboxOnly: false,
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
      },
      storeGridsalesGroupDiv: "STORE",
      payInfoGridOptions: {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
      },
      isChecked: false,
      isGroupPopupOpen: false,
      isCaddieFeePopupOpen: false,
      isDiscountPopupOpen: false,
      isVisitsCopyPopupOpen: false,
      isSaleInfoPopupOpen: false,
      isDivideByNPopupOpen: false,
      isPartialSumPopupOpen: false,
      isPayCancelPopupOpen: false,
      isPayReceiptPopupOpen: false,
      isPayPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isCheckinLayerOpen: false,
      isLockerIssuePopupOpen: false,
      isOpenReceiptPopupOpen: false,
      isReIssueReceiptPopupOpen: false,
      isDepositUseReceiptPopupOpen: false,
      isChangePayerPopupOpen: false,
      isCartDiscountPopupOpen: false,
      isLockerStatusPopupOpen: false,
      isPartnerCertificationPopupOpen: false,
      isPaymentStatementPopupOpen: false,
      isHoleAddPopupOpen: false,
      isVisitDeleteConfirmPopupOpen: false,
      isTakeCompanionMemberPopupOpen: false,
      isBlacklistPopupOpen: false,
      isMemberVisitStatusPopup: false,
      isNewPartialSumPopup: false,
      isMemberInfoPopupOpen: false,
      isSmsSendPopupOpen: false,
      isHistoryPopupOpen: false,
      /**
       * 맨 밑 row의 락카 필드에서 +,- 를 친 후 엔터키를 눌렀는지 여부.
       * 엔터키 후 셀 focusing을 위해 사용함
       * onVisitGridSectionBodyKeyDown() 에서 true값이 되고, visitsGridCellSaved()에서 확인 후 false 값이 됨
       */
      isEnterKeyDownInLockerNoBottomAfterPlusMinus: false,
      memberPopupType: {
        BEN_MEMBERSHIP: "BEN_MEMBERSHIP", // 회원특전
        VISIT_MEMBER: "VISIT_MEMBER", // 내장객
      },
      currentPayGridEditingCellIndex: -1,
      personFlag: true,
      divideCancelMeunItems: [
        { text: "1/N취소", value: "1N" },
        { text: "전체취소", value: "ALL" },
      ],
      preLockerNo: null,
      isPayInfoGridVisible: false,
    };
  },
  provide: {
    grid: [Edit, Resize, ForeignKey, Aggregate, Group],
  },
  watch: {
    teamInfo: {
      deep: true,
      handler({eventContents}) {
        if(eventContents) {
          this.$refs.teamInfoTab.ej2Instances.element.classList.add('remark-active');
        } else {
          this.$refs.teamInfoTab.ej2Instances.element.classList.remove('remark-active');
        }
      }
    }
  },
  computed: {
    ...mapState("memoView", ["memoResveId"]),
    ...mapGetters("dialog", ["activeDialog"]),
    isChkinStatusByRain() {
      return this.timeData?.chkinStatus === 'RAIN';
    },
    storeGridGroupColumns() {
      return this.storeGridsalesGroupDiv === "DATE" ? ["groupInfoByDate"] : ["groupInfoByStore"];
    },
    storeGridOptions() {
      return {
        provides: [Resize, ForeignKey, Aggregate, Group],
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        enableHover: false,
        allowGrouping: true,
        aggregates: this.storeGridAggregates,
        groupSettings: {
          showDropArea: false,
          columns: this.storeGridGroupColumns,
        },
        columns: [
          {
            field: "salesDate",
            headerText: "매출일자",
            width: 90,
            type: "string",
            textAlign: "center",
          },
          {
            field: "storeName",
            headerText: "매장",
            width: 90,
            type: "string",
          },
          {
            field: "startTime",
            headerText: "시간",
            width: 70,
            type: "string",
            textAlign: "center",
          },
          {
            field: "startCourse",
            headerText: "코스",
            width: 70,
            type: "string",
            textAlign: "center",
          },
          {
            field: "visitName",
            headerText: "내장객",
            width: 90,
            type: "string",
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            width: 90,
            type: "string",
          },
          {
            field: "productName",
            headerText: "상품명",
            width: 180,
            type: "string",
          },
          {
            field: "caddieUseFlag",
            headerText: "캐디이용",
            width: 90,
            type: "boolean",
            displayAsCheckBox: true,
            textAlign: "Center",
          },
          {
            field: "priceDiv",
            headerText: "단가 구분",
            width: 90,
            type: "string",
            isCommonCodeField: true,
            groupCode: "PRICE_DIV",
          },
          {
            field: "salePrice",
            headerText: "단가",
            width: 90,
            type: "string",
            textAlign: "right",
            isNumericType: true,
            visible: false,
          },
          {
            field: "saleQty",
            headerText: "수량",
            width: 70,
            type: "number",
            format: "N",
            isNumericType: true,
            textAlign: "right",
          },
          {
            field: "saleAmt",
            headerText: "금액",
            width: 90,
            type: "string",
            textAlign: "right",
            isNumericType: true,
            visible: false,
          },
          {
            field: "dcAmt",
            headerText: "할인금액",
            width: 90,
            type: "string",
            textAlign: "right",
            isNumericType: true,
          },
          {
            field: "totAmt",
            headerText: "판매금액",
            width: 90,
            type: "string",
            textAlign: "right",
            isNumericType: true,
          },
          {
            field: "payerId",
            visible: false,
          },
          {
            field: "payerName",
            headerText: "지불자",
            width: 90,
            type: "string",
          },
          {
            field: "payerNameIcon",
            headerText: "",
            width: 32,
            type: "string",
          },
          {
            field: "divideDiv",
            headerText: "분할",
            width: 70,
            type: "string",
            isCommonCodeField: true,
            groupCode: "DIVIDE_DIV",
          },
          {
            field: "prpayFrpyFlag",
            headerText: "선불",
            width: 90,
            type: "boolean",
            displayAsCheckBox: true,
            textAlign: "Center",
          },
          {
            field: "insertTime",
            headerText: "등록",
            width: 90,
            type: "string",
          },
          {
            field: "groupInfoByStore",
            type: "string",
            // TODO : 그룹 Column 변경시 Visible: false 처리가 안되는 버그로 인해 width: 1 을 셋팅하여 안보이게 처리.
            // TODO : 스크롤바가 생기는 경우 Visible: false 처리가 잘됨.
            // visible: false,
            width: 1,
          },
          {
            field: "groupInfoByDate",
            type: "string",
            // visible: false,
            width: 1,
          },
        ],
      };
    },
    isPopupOpened() {
      return (
        this.isFindVisitPopupOpen ||
        this.isBackLockerStatusPopupOpen ||
        this.isLockerStatusPopupOpen ||
        this.isPayLockYesNoCancelPopupOpen ||
        this.isGroupPopupOpen ||
        this.isCaddieFeePopupOpen ||
        this.isDiscountPopupOpen ||
        this.isVisitsCopyPopupOpen ||
        this.isSaleInfoPopupOpen ||
        this.isDivideByNPopupOpen ||
        this.isPartialSumPopupOpen ||
        this.isPayCancelPopupOpen ||
        this.isPayReceiptPopupOpen ||
        this.isPayPopupOpen ||
        this.isMemberSelectPopupOpen ||
        this.isLockerIssuePopupOpen ||
        this.isOpenReceiptPopupOpen ||
        this.isReIssueReceiptPopupOpen ||
        this.isDepositUseReceiptPopupOpen ||
        this.isChangePayerPopupOpen ||
        this.isCartDiscountPopupOpen ||
        this.isPartnerCertificationPopupOpen ||
        this.isPaymentStatementPopupOpen ||
        this.isHoleAddPopupOpen ||
        this.isVisitDeleteConfirmPopupOpen ||
        this.isTakeCompanionMemberPopupOpen ||
        this.isMemberVisitStatusPopup ||
        this.isBlacklistPopupOpen ||
        this.isNewPartialSumPopup ||
        this.isMemberInfoPopupOpen ||
        this.isSmsSendPopupOpen ||
        this.isHistoryPopupOpen
      );
    },
    isCheckinLayerThumbnail() {
      return this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL;
    },
    prepaymentShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.prepayment",
        shortcut: {
          ctrlKey: false,
          altKey: true,
          shiftKey: false,
          key: SHORTCUT_KEYS.F1,
        },
      };
    },
    lockerPublishShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.lockerPublish",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F10,
        },
      };
    },
    lockerViewShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.lockerView",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
      };
    },
    newPartialSumShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.newPartial",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F3,
        },
      };
    },
    payLockCancelShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.payLockCancel",
        shortcut: {
          ctrlKey: false,
          altKey: true,
          shiftKey: false,
          key: SHORTCUT_KEYS.Q,
        },
      };
    },
    payReceiptShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.payReceipt",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F4,
        },
      };
    },
    payProcessShortcutButtonProps() {
      return {
        shortcutKey: "CheckInLayer.shortcuts.payProcess",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F6,
        },
      };
    },
    layerBtnCaption() {
      // 레이어 상단 버튼 caption
      switch (this.checkinLayerStatus) {
        case CHECKIN_LAYER_THUMBNAIL:
          return "위화살표";
        case CHECKIN_LAYER_CONTENT:
          return "아래화살표";
        default:
          return null;
      }
    },
    /**
     * Step 01, 02, 03 영역의 css class를 얻어옴
     * @param index step 인덱스
     * @returns {*} css class명
     */
    stepStyleClass() {
      return (index) => {
        let className = null;
        if (
          this.stepIndex === index &&
          (this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL ||
            this.checkinLayerStatus === CHECKIN_LAYER_CONTENT)
        ) {
          className = this.$t("className.frontCheckin.frontStepEnabled");
        }
        return className;
      };
    },
    /**
     * 체크인 레이어 전체를 보여주느냐 여부를 return
     */
    isShowCheckinLayer() {
      if (this.checkinLayerStatus !== CHECKIN_LAYER_DISPLAY_NONE) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 섬네일 컨텐츠를 보여주느냐 여부를 return
     */
    isShowThumbnail() {
      return (step) => {
        if (
          this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL &&
          this.stepIndex === step
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    /**
     * 컨텐츠를 보여주느내 여부를 return
     */
    isShowContent() {
      return (step) => {
        return this.checkinLayerStatus === CHECKIN_LAYER_CONTENT &&
            (this.stepIndex === step || step === null);
      };
    },
    /**
     * 팀 정보 항목 Enable 여부
     * @returns {boolean}
     */
    isTeamInfoEnable() {
      return !this.teamInfo || !this.teamInfo.teamChkoutFlag;
    },
    visitGridEditSettings() {
      return {
        allowEditing: this.isTeamInfoEnable,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      };
    },
    areAllTeamCheckout() {
      return this.checkoutDiv === 1;
    },
    /**
     * total 캐디피
     */
    sumOfCaddieInfoListCaddieFee() {
      let totalCaddieFee = 0;
      this.caddieInfoList.forEach((caddieInfo) => {
        totalCaddieFee += caddieInfo.caddieFee;
      });
      return totalCaddieFee;
    },
    isTeamCheckout() {
      return !!this.teamInfo?.teamChkoutFlag;
    }
  },
  created: function () {
    this.teamInfoOptions.startCourseOptions = commonCodesGetCommonCode("COURSE_CODE", true);
    this.teamInfoOptions.partDivOptions = commonCodesGetCommonCode("PART_DIV", true);
    this.teamInfoOptions.teamHoleDivOptions = commonCodesGetCommonCode("HOLE_DIV", true);
    this.teamInfoOptions.resveKindOptions = commonCodesGetCommonCode("RESVE_KIND", true);

    // 정산정보 그리드 메뉴 핸들러
    // this.$EventBus.$on('payInfoGridMenuSelected', this.onPayInfoGridMenuSelected);

    addShortcut(SHORTCUT_CHECKIN.key, SHORTCUT_CHECKIN.shortcut);
    addShortcut(SHORTCUT_CHECKOUT.key, SHORTCUT_CHECKOUT.shortcut);
    addShortcut(
      SHORTCUT_CHECK_IN_OUT_LAYER_THUMBNAIL.key,
      SHORTCUT_CHECK_IN_OUT_LAYER_THUMBNAIL.shortcut
    );
    addShortcut(
      SHORTCUT_CHECK_IN_OUT_LAYER_CONTENT.key,
      SHORTCUT_CHECK_IN_OUT_LAYER_CONTENT.shortcut
    );
    window.addEventListener("keydown", this.onWindowKeypress, true);
  },
  beforeDestroy: function () {
    // this.$EventBus.$off('payInfoGridMenuSelected');
    window.removeEventListener("keydown", this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_CHECK_IN_OUT_LAYER_THUMBNAIL.key);
    removeShortcut(SHORTCUT_CHECK_IN_OUT_LAYER_CONTENT.key);
    removeShortcut(SHORTCUT_CHECKOUT.key);
    removeShortcut(SHORTCUT_CHECKIN.key);
  },
  mounted() {
    // 연락처 컬럼은 암호화데이터 열람 권한이 있어야 편집 가능
    let contactTelColumn = this.visitsGridColumns.find(
      (column) => column.field === "contactTel"
    );
    contactTelColumn.allowEditing = this.commonMixinHasCiperTextGet;
  },
  methods: {
    memberNoFormatter,
    getFormattedPhoneNumber,
    ...mapActions("memoView", ["setResveId"]),
    ...mapMutations(["SET_FOOTER_MESSAGE", "SET_FOOTER_MESSAGE_ICON"]),
    validateFormRefs,
    commonCodesGetComName,
    getCurrentBizCode,
    openDutchPayByItemPopup() {
      this.isDutchPayByItemPopupOpen = true;
      const popupDate = {
        chkinId: this.teamInfo.chkinId,
        grpNo: this.teamInfo.grpNo,
      };
      this.$nextTick(() => {
        this.$refs.dutchPayByItemPopup.showPopup(popupDate);
      });
    },
    /**
     * showCheckinThumbnail(), showCheckinPage() 에서 체크인 상세 정보를 설정하기 위한 method
     */
    getIsPopupOpened() {
      return this.isPopupOpened;
    },
    async checkInDuplicateName(info,callback) {
      const {findVisitLists} = await GolfErpAPI.fetchCheckForTheSameVisitorName({
        visitDate: this.teamInfo.visitDate,
        visitName: info.visitName,
        visitId: info.visitId
      });
      if (findVisitLists?.length > 0) {
        const addMessage = findVisitLists.map(visitorInfo =>
            `<br>시간/코스 : ${visitorInfo.startTime.substring(0,2)}:${visitorInfo.startTime.substring(2,4)}/${commonCodesGetComName("COURSE_CODE", visitorInfo.startCourse)}`
        ).join();
        if (!(await this.confirm("동일 이름의 내장자가 등록되어 있습니다." + addMessage + "<br>계속하시겠습니까?"))) {
          callback();
          return;
        } else {
          const currentRowIndex = this.$refs.visitsGrid.currentSelectedRowIndex;
          this.$refs.visitsGrid.editCell(currentRowIndex, "visitName");
        }
      }
    },
    async visitDuplicateName(rowData,callback) {
      const visitsGridViewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      if (visitsGridViewRecords.length > 0) {
        if (visitsGridViewRecords.filter((record, index) => record.visitName === rowData.visitName && rowData.visitName && index !== (rowData.visitSno - 1)).length > 0) {
          if (!(await this.confirm("현재 팀의 내장객등록 데이터에 동일 이름의 내장자가 등록되어 있습니다.<br>계속하시겠습니까?"))) {
            callback();
            return;
          } else {
            const currentRowIndex = this.$refs.visitsGrid.currentSelectedRowIndex;
            this.$refs.visitsGrid.editCell(currentRowIndex, "visitName");
          }
        }
      }
    },
    async setDetailData(timeData, resveCmpnFlag = false, cellIndex, isThumbnail = false) {
      this.timeData = timeData;
      this.resveCmpnFlag = resveCmpnFlag;

      // 팀정보 초기 설정
      if (timeData.grpNo) {
        this.divideDiv = "G";
      } else {
        this.divideDiv = "T";
      }

      if (isThumbnail) {
        // 썸네일 조회
        await this.getVisitThumbnail(timeData);
      } else {
        // 상세 조회
        await this.getCheckinInfo(timeData, resveCmpnFlag, cellIndex);
      }
    },
    async getVisitThumbnail(timeData) {
      if (timeData.resveId) {
        // 메모 오픈.
          this.setResveId(timeData.resveId);
      }

      if (timeData.chkinId) {
        const data = await GolfErpAPI.getVisitThumbnail(timeData.chkinId);

        this.setThumbnailVisitInfoList(orderBy(data, ["visitSno"]));
      } else {
        this.setThumbnailVisitInfoList();
      }
    },
    isTeamInfoEdit() {
      return this.stepIndex !== 0;
    },
    removeYearFromDate(timeData) {
      const yearIdx = timeData.indexOf('-');
      return timeData.slice(yearIdx+1, timeData.length);
    },
    isSetDetailData(timeData) {
      if (
        this.checkinLayerStatus === CHECKIN_LAYER_DISPLAY_NONE ||
        this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL ||
        this.timeId !== timeData.timeId
      ) {
        return true;
      }
      return false;
    },
    /**
     * 내장객 그리드를 클릭하여, 프론트 섬네일 화면을 띄울 때 호출함
     * 하단 섬네일 활성 상태로
     * @param timeData 그리드의 타임 정보
     */
    async showCheckinThumbnail(timeData, resveCmpnFlag = false, cellIndex , firstMemoOpen) {
      this.firstMemoOpen = firstMemoOpen;
      // 새로 띄우는 경우, 상세 정보 및 섬네일 정보 로딩해야 함
      if (this.isSetDetailData(timeData)) {
        this.clearCheckinInfos();
        await this.setDetailData(timeData, resveCmpnFlag, cellIndex, true);
      }

      if (cellIndex === 13) {
        this.$EventBus.$emit("memoShortcutButtonClick", true);
      }

      // Checkin UI 설정
      this.checkinLayerStatusTabSetting(CHECKIN_LAYER_THUMBNAIL);
    },
    /**
     * 목록에서 체크인/체크아웃 셀 클릭 시 체크인(STEP 1)/체크아웃(STEP 2) 모드의 화면을 띄움
     */
    async showCheckinPage(timeData, stepIndex = 0, resveCmpnFlag = false, isPayInfoGridVisible = false , firstMemoOpen) {
      this.firstMemoOpen = firstMemoOpen;
      // 새로 띄우는 경우, 상세 정보 로딩해야 함
      if (this.isSetDetailData(timeData)) {
        this.clearCheckinInfos();
        await this.setDetailData(timeData, resveCmpnFlag);
      }

      // Checkin UI 설정
      this.checkinLayerStatusTabSetting(CHECKIN_LAYER_CONTENT, stepIndex);

      if (isPayInfoGridVisible) {
        this.$nextTick(() => {
          this.isPayInfoGridVisible = true;
        });
      }

      await this.payLock(
        stepIndex,
        false,
        this.divideDiv === "T" ? true : false
      );
    },
    selectRowForSearchName(gridRefName, isRefresh=false) {
      this.$nextTick(() => {
        if(isRefresh){
          this.$refs[gridRefName].refresh();
        }
        setTimeout(() => {
          let targetIdx = 0;
          if(this.savedSearchVisitName) {
            targetIdx = this.$refs[gridRefName].getBatchCurrentViewRecords().find(record => Object.values(record).find(val => {
              if(typeof val === 'string'){
                return val.includes(this.savedSearchVisitName);
              }
            }))?._rid - 1;
            if(targetIdx < 0 || isNaN(targetIdx)) {
              targetIdx = 0;
            }
          }
          this.$refs[gridRefName].selectRow(targetIdx);
        }, 300);
      });
    },
    checkinLayerStatusTabSetting(layerStatus, stepIndex) {
      // 섬네일 UI 오픈
      this.checkinLayerStatus = layerStatus;

      // STEP 설정
      if (stepIndex !== undefined) {
        this.stepIndex = stepIndex;
      }

      switch (layerStatus) {
        case CHECKIN_LAYER_THUMBNAIL:
          this.$emit("checkinLayerThumbnailOpen");
          break;
        case CHECKIN_LAYER_CONTENT:
          if (this.stepIndex === 0) {
            // STEP 01 상세 화면이 보여지는 순간 설정
            if(!this.isVisitGridCreate){
              this.isVisitGridCreate = true;
            }
            this.selectRowForSearchName("visitsGrid", true);
          } else if (this.stepIndex === 1) {
            if(this.originalTeamInfo.grpKind !== "FIT") {
              this.$refs.teamInfoTab.select(0);
            }else {
              this.$refs.teamInfoTab.select(1);
            }
            this.selectRowForSearchName("payGrid");
          }
          if (this.timeData?.memoFlag && this.firstMemoOpen) {
            this.$EventBus.$emit("memoShortcutButtonClick", true);
            this.firstMemoOpen = false;
          }
          this.$nextTick(()=>{
            this.$emit("checkinLayerContentOpen");
          });
          break;
        case CHECKIN_LAYER_DISPLAY_NONE:
          this.$emit("checkinLayerClose");
          break;
      }

      // focusing test
      if (
        // layerStatus === CHECKIN_LAYER_THUMBNAIL ||
        layerStatus === CHECKIN_LAYER_CONTENT
      ) {
        this.$nextTick(() => {
          this.$refs.startTime.focus();
        });
      }
    },
    /**
     * 썸네일에 사용된 thumbnailVisitInfoList 를 설정해 주는 method
     * 데이터 조회 시 / 섬네일 화면을 띄우는 경우(조회-재조회로 인한 grid load complete / checkin 화면에서 '아래 화살표' 클릭 시) 에 호출되여 그리드 데이터를 기반으로 갱신됨
     * 내장객 row 추가 시, 내장객 이름이 있는 경우에도 호출 : visitInfoList가 없으나, resveVisitInfoList에 데이터가 있어서 그리드에 추가하는 경우가 있기 때문
     * 내장객명이 있는 데이터만 섬네일 화면에 출력
     *
     * isVisitGridCreate 가 false(내장객 그리드 미생성)인 경우 : API에서 조회한 내장객 정보로부터 로딩
     * 그 외에는 내장객 그리드로부터 값을 loading
     */
    setThumbnailVisitInfoList: function (data) {
      // this.thumbnailVisitInfoList 설정
      this.thumbnailVisitInfoList = [];
      this.thumbnailPayInfoList = [];
      this.thumbnailStoreSaleInfoList = [];

      const storeGroupList = commonCodesGetCommonCodeByIdxAttrb(
        "STORE_GROUP",
        1,
        "Y"
      );

      if (data) {
        this.thumbnailVisitInfoList = data.map(item => ({
          ...item,
          greenFeeInfo: {
            totAmt: item.greenFee,
          },
        }));

        this.thumbnailPayInfoList = data.map(item => ({
          visitId: item.visitId,
          visitName: item.visitName,
          lockerNo: item.lockerNo,
          holeDiv: item.holeDiv,
          totAmt: item.saleAmt,
          noPayAmt: item.saleAmt - item.payAmt,
        }));

        storeGroupList.forEach(storeGroup => {
          this.thumbnailStoreSaleInfoList.push({
            storeGroupCode: storeGroup.comCode,
            storeGroupName: storeGroup.comName,
            totCnt: data.map(item => item[storeGroup.comCode.toLowerCase() + "Count"]).reduce((acc, cur) => acc + cur),
            totAmt: data.map(item => storeGroup.comCode.toLowerCase() === "front" ? item.greenFee : item[storeGroup.comCode.toLowerCase() + "Amt"]).reduce((acc, cur) => acc + cur),
          });
        });

        this.thumbnailStoreSaleInfoList.push({
          storeGroupCode: "%",
          storeGroupName: "합계",
          totCnt: this.thumbnailStoreSaleInfoList.map(item => item.totCnt).reduce((acc, cur) => acc + cur),
          totAmt: this.thumbnailStoreSaleInfoList.map(item => item.totAmt).reduce((acc, cur) => acc + cur),
        });
      } else {
        storeGroupList.forEach(storeGroup => {
          this.thumbnailStoreSaleInfoList.push({
            storeGroupCode: storeGroup.comCode,
            storeGroupName: storeGroup.comName,
            totCnt: 0,
            totAmt: 0,
          });
        });

        this.thumbnailStoreSaleInfoList.push({
          storeGroupCode: "%",
          storeGroupName: "합계",
          totCnt: 0,
          totAmt: 0,
        });
      }
    },
    /**
     * 내장객 그리드에서 우천취소/예약 가능 등 체크인 불가한 항목을 클릭했을 경우 호출함
     * 하단 비활성 상태로
     */
    closeCheckin() {
      this.checkinLayerStatusTabSetting(CHECKIN_LAYER_DISPLAY_NONE, 0);
      this.clearCheckinInfos();
      this.timeData = null;
      this.isVisitGridCreate = false;
    },
    /**
     * Step 01, 02, 03 영역 클릭 시 호출
     * @param index step 인덱스
     */
    async goStepPage(index) {
      if (this.stepIndex === 0 && index !== 0) {
        if (this.isTeamInfoAndvisitInfoListIsChanged()) {
          if (
            this.isCheckinLayerThumbnail ||
            await this.confirm(
              "수정 내역이 있습니다. 수정 내역이 초기화 됩니다. 진행하시겠습니까?"
            )
          ) {
            await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
          } else {
            return;
          }
        }
      }
      if (
        (this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL ||
          this.checkinLayerStatus === CHECKIN_LAYER_CONTENT) &&
        this.stepIndex !== index
      ) {
        this.checkinLayerStatusTabSetting(this.checkinLayerStatus, index);
      }

      if (this.$refs.teamInfoTab) {
        if (index === 1) {
          this.$refs.teamInfoTab.select(1);
          this.selectRowForSearchName("payGrid");
        } else {
          this.$refs.teamInfoTab.select(0);
          this.selectRowForSearchName("visitsGrid");
        }
      }
      if (index === 2) {
        this.isPayInfoGridVisible = false;
      }

      if (this.checkinLayerStatus !== CHECKIN_LAYER_THUMBNAIL) {
        await this.payLock(index, false, this.divideDiv === "T" ? true : false);
      }
      // this.$emit("dataRefresh"); 재조회를 태울경우 너무 로딩이 길어짐
    },
    async onPayLockCancelButtonClicked() {
      if (
        !(await this.confirm("해당 팀(단체)의 정산LOCK을 해제하시겠습니까?"))
      ) {
        return;
      }

      this.payLock(0, true, this.divideDiv === "T" ? true : false);
    },
    async checkPayLock(isErrorMessageView = true) {
      try {
        await GolfErpAPI.fetchPayLock(this.teamInfo.chkinId, isErrorMessageView);
      } catch (e) {
        if (e.message === "FRONT_LOCK") {
          return true;
        }

        throw e;
      }

      return false;
    },
    async payLock(stepIndex, compulsionFlag = false, teamFlag = false) {
      const chkinId = this.teamInfo?.chkinId || null;

      if (chkinId) {
        let errorMessageViewFlag = false;
        let isPaying = true;

        if (stepIndex === 1 || stepIndex === 2) {
          errorMessageViewFlag = true;
        } else {
          isPaying = false;
        }

        if (compulsionFlag) {
          errorMessageViewFlag = false;
        }

        try {
          await GolfErpAPI.fetchPayLock(chkinId, errorMessageViewFlag);
        } catch (e) {
          if (e.message === "FRONT_LOCK") {
            if (compulsionFlag) {
              const data = {
                id: chkinId,
                visitDate: this.teamInfo.visitDate,
                isPaying: isPaying,
                group: {
                  number: teamFlag ? null : this.teamInfo.grpNo,
                },
              };

              await GolfErpAPI.patchPayLock(data);
            } else {
              // await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
              // throw e;
            }
          }
          return;
        }

        const data = {
          id: chkinId,
          visitDate: this.teamInfo.visitDate,
          isPaying: isPaying,
          group: {
            number: teamFlag ? null : this.teamInfo.grpNo,
          },
        };

        await GolfErpAPI.patchPayLock(data);
      }
    },
    /**
     * 레이어 상단 접기/펼치기 버튼 클릭 시 호출
     */
    onLayerBtnClicked: async function () {
      switch (this.checkinLayerStatus) {
        case CHECKIN_LAYER_THUMBNAIL:
          await this.showCheckinPage(this.timeData, this.stepIndex, this.resveCmpnFlag, this.isPayInfoGridVisible, true);
          this.checkinLayerStatusTabSetting(CHECKIN_LAYER_CONTENT);
          await this.payLock(
            this.stepIndex,
            false,
            this.divideDiv === "T" ? true : false
          );
          break;
        case CHECKIN_LAYER_CONTENT:
          if (
            this.isTeamInfoAndvisitInfoListIsChanged() &&
            !(await this.confirm(
              "수정 내역이 있습니다. 수정 내역이 초기화 됩니다. 진행하시겠습니까?"
            ))
          ) {
            return;
          }

          // 락카번호 초기화
          if (this.$refs.visitsGrid) {
            const data = this.$refs.visitsGrid.getBatchChanges();

            await this.lockerReset(data);
          }

          this.checkinLayerStatusTabSetting(CHECKIN_LAYER_THUMBNAIL);
          await this.payLock(
            0,
            false,
            this.divideDiv === "T" ? true : false
          );
          if (this.isTeamInfoAndvisitInfoListIsChanged()) {
            this.$refs.visitsGrid.refresh();
          }
          break;
      }
    },
    /**
     * 닫기 버튼 클릭 핸들러
     */
    async onLayerCloseBtnClicked() {
      // STEP 01 의 데이터 변경여부 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      if (this.$refs.visitsGrid) {
        const data = this.$refs.visitsGrid.getBatchChanges();

        await this.lockerReset(data);
      }

      await this.payLock(0, false, this.divideDiv === "T" ? true : false);

      this.closeCheckin();
    },
    /**
     * Refresh(재조회) 버튼 핸들로
     */
    async onRefreshBtnClicked(args, isChangeCheck = true) {
      // STEP 01 의 데이터 변경여부 체크
      if (isChangeCheck && this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      let data = null;

      if (this.$refs.visitsGrid) {
        data = this.$refs.visitsGrid.getBatchChanges();
      }

      await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);

      if (this.$refs.visitsGrid) {
        this.lockerReset(data);
      }
    },
    lockerReset(batchData) {
      if (batchData.addedRecords.length > 0) {
        batchData.addedRecords.forEach(async (info) => {
          if (info.lockerNo) {
            await this.lockerStatusSettingPreviousLockerNumber(info.lockerNo);
          }
        });
      }
      if (batchData.changedRecords.length > 0) {
        batchData.changedRecords.forEach(async (info) => {
          const visitId = info.visitId;
          const visitInfo = this.visitInfoList.find(
            (args) => args.visitId === visitId
          );

          if (visitInfo) {
            if (info.lockerNo !== visitInfo.lockerNo) {
              await this.settingLockerNumber(
                visitInfo.lockerNo,
                info.lockerNo,
                null,
                false,
                visitInfo.visitId
              );
            }
          }
        });
      }
      if (batchData.deletedRecords.length > 0) {
        batchData.deletedRecords.forEach(async (info) => {
          const visitId = info.visitId;
          const visitInfo = this.visitInfoList.find(
            (args) => args.visitId === visitId
          );

          if (visitInfo) {
            if (info.lockerNo !== visitInfo.lockerNo) {
              await this.settingLockerNumber(
                visitInfo.lockerNo,
                info.lockerNo,
                null,
                false,
                visitInfo.visitId
              );
            }
          }
        });
      }
    },
    async getCheckinInfo(timeData, resveCmpnFlag) {
      if (timeData.resveId) {
        this.setResveId(timeData.resveId)
          .then(() => {
            // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
            // 메모 항목 클릭시에는 메모 자동 오픈.
            // if (cellIndex === 7) {
              // this.$EventBus.$emit("memoShortcutButtonClick", true);
            // }
          })
          .catch(() => {
            // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
            // this.$EventBus.$emit("closeRightMenu");
          });
      } else {
        // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경.
        // this.$EventBus.$emit("closeRightMenu");
      }
      // console.log(`chkinId: ${timeData.chkinId}, resveId: ${timeData.resveId}, resveCmpnFlag: ${resveCmpnFlag}, stepDiv: ${this.getStepDivParam(this.stepIndex)}, grpNo: ${timeData.grpNo}`)

      if (!!this.teamInfo && !!this.teamInfo.grpNo) {
        timeData.grpNo = this.teamInfo.grpNo;
      } else {
        if (this.teamInfo) {
          timeData.grpNo = null;
        }
      }

      // 팀/단체 여부 구분
      let grpNoParam = timeData.grpNo;
      if (this.divideDiv === "T") {
        grpNoParam = null;
      }

      if (timeData?.chkinStatus === "RAIN") {
        return;
      }

      try {
        const { value: responseValue } = await getCheckinInfo(
          timeData.chkinId,
          timeData.resveId,
          resveCmpnFlag,
          grpNoParam
        );
        this.timeId = timeData.timeId; // time 구분을 위함
        this.teamInfo = responseValue.teamInfo;
        this.teamInfo.promtnId =
          this.teamInfo.promtnId === undefined ? null : this.teamInfo.promtnId;
        this.teamInfo.promtnName =
          this.teamInfo.promtnName === undefined
            ? null
            : this.teamInfo.promtnName;
        this.teamInfo.benMembershipId =
          this.teamInfo.benMembershipId === undefined
            ? null
            : this.teamInfo.benMembershipId;
        this.teamInfo.benMembershipName =
          this.teamInfo.benMembershipName === undefined
            ? null
            : this.teamInfo.benMembershipName;

        // 카트 수량이 없거나 null인 경우 - default 값은 1
        if (
          this.teamInfo.cartQty === undefined ||
          this.teamInfo.cartQty === null
        ) {
          this.teamInfo.cartQty = 1;
        }

        this.originalTeamInfo = JSON.parse(JSON.stringify(this.teamInfo));

        if (this.teamInfo.grpNo) {
          if (timeData.grpNo === null || timeData.grpNo === undefined) {
            this.divideDiv = "G";
            timeData.grpNo = JSON.parse(JSON.stringify(this.teamInfo.grpNo));
            this.getCheckinInfo(timeData, this.resveCmpnFlag);
          }
        } else {
          if (timeData.grpNo) {
            this.divideDiv = "T";
            timeData.grpNo = null;
            this.getCheckinInfo(timeData, this.resveCmpnFlag);
          }
        }

        const hasProp = (arr, key) => Object.prototype.hasOwnProperty.call(arr, key);

        this.visitInfoList = responseValue.visitInfoList.map((r) => ({
          ...r,
          lockerSmsSendCount: r.lockerSmsSendCount > 0,
          mmbsCrtfcResult: r.mmbsCrtfcResult || "",
          dcContents: r.dcContents,
          cartDcAmt: hasProp(r, 'cartRentInfo')
              ? r.cartRentInfo.reduce((acc, cur) => acc + cur.dcAmt, 0)
              : 0,
          hasNormalDiscount: !!r.dcList?.find(
            ({ dcApplyDiv }) => dcApplyDiv === "NOR"
          ),
          hasCouponDiscount: !!r.dcList?.find(
            ({ dcApplyDiv }) => dcApplyDiv === "COUPON"
          ),
          promtnDelFlag: false
        }));

        // 요금 계산 초기화
        this.visitInfoList.forEach((visitInfo) => {
          visitInfo.greenFeeDc = this.calculateGreenFeeDc(
            visitInfo.paymtCode,
            visitInfo.greenFeeInfo
          );
        });

        this.originalVisitInfoList = JSON.parse(
          JSON.stringify(this.visitInfoList)
        );
        this.resveVisitInfoList = responseValue.resveVisitInfoList;

        this.isGridFormCreated = false;

        this.resveInfo = responseValue.resveInfo;
        this.originalResveInfo = JSON.parse(JSON.stringify(this.resveInfo));

        this.memberImageInfo = responseValue.memberImageInfo;
        this.originalMemberImageInfo = JSON.parse(
          JSON.stringify(this.memberImageInfo)
        );

        this.caddieInfoList = responseValue.caddieInfoList;
        this.originalCaddieInfoList = JSON.parse(
          JSON.stringify(this.caddieInfoList)
        );

        if (responseValue.paySettleTeamInfoList) {
          this.paySettleTeamInfoList = orderBy(responseValue.paySettleTeamInfoList.map(item => {
            item.payAmt = responseValue.payInfoList
              ?.filter(i => !i.delFlag && i.visitId === item.visitId)
              ?.reduce((acc, cur) => acc + cur.payAmt, 0);
            item.cardAmt = responseValue.payInfoList
                ?.filter(i => !i.delFlag && i.visitId === item.visitId && i.payDiv === 'CARD')
                ?.reduce((acc, cur) => acc + cur.payAmt, 0);
            item.cashAmt = responseValue.payInfoList
                ?.filter(i => !i.delFlag && i.visitId === item.visitId && i.payDiv === 'CASH')
                ?.reduce((acc, cur) => acc + cur.payAmt, 0);
            item.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", item.startCourse);
            return item;
          }), ["startTime", "courseCodeSortNo", "visitSno"]);
          this.paySettleTeamInfoDivideNumber = this.paySettleTeamInfoList.length;
        } else {
          this.paySettleTeamInfoList = [];
          this.paySettleTeamInfoDivideNumber = 0;
        }

        this.storeSaleInfoList = orderBy(responseValue.storeSaleInfoList.map(
          (storeSaleInfo) => ({
            groupInfoByStore: storeSaleInfo.storeName,
            groupInfoByDate: storeSaleInfo.salesDate,
            ...storeSaleInfo,
          })
        ), ["salesDate"]);
        this.payInfoList = responseValue.payInfoList.map((data) => ({
          ...data,
          approvalDivName: data.payDiv === 'CREDIT_DEF' ? data.approvalDiv : commonCodesGetComName("APPROVAL_DIV", data.approvalDiv),
          remarks: data.delFlag ? "[취소사유]".concat(data.delReason) : data.remarks,
          delStatus: data.delFlag ? "취소" : "정상",
        })).filter(data => {
          if(data.payDiv !== 'CREDIT_DEF') {
            return true;
          } else {
            if(data.delFlag) {
              return false;
            }
            return true;
          }
        });

        this.checkoutDiv = responseValue.checkoutDiv;
      } catch (e) {
        console.log("getCheckinInfo.err.===>", e);
        this.timeId = null;
      }
    },
    /**
     * 요금 계산
     */
    calculateGreenFeeDc(paymtCode, greenFeeInfo) {
      let greenFee = this.getGreenFee(paymtCode, greenFeeInfo);
      if (greenFee) {
        return greenFee.toLocaleString();
      } else {
        return "0";
      }
    },
    /**
     * 조회한 내장객 등록 데이터 순번 체크 및 4건이 안되는 경우 4건으로 맞춰 주기
     */
    checkVisitsInfoList: function () {
      // 데이터 순번 체크. 체크 실패 시 초기화
      this.checkAndResetVisitsInfoListSno();
    },
    async addVisitRowToFour() {
      // chkinId 가 없고, resveVisitInfoList 가 있는 경우 추가
      // if (this.teamInfo && this.teamInfo.chkinId === undefined) {
      //   this.resveVisitInfoList.forEach((reseveVisitInfo, idx) => {
      //     let addRecordObject = {
      //       visitId: null,
      //       startTime: reseveVisitInfo.startTime,
      //       startCourse: reseveVisitInfo.startCourse,
      //       visitName: reseveVisitInfo.visitName,
      //       visitSno: idx + 1, // 내장순번. 순서대로 설정
      //       lockerNo: "",
      //       bagName: reseveVisitInfo.bagName,
      //       sexCode: reseveVisitInfo.sexCode,
      //       membershipId: reseveVisitInfo.membershipId,
      //       memberNo: reseveVisitInfo.memberNo,
      //       memberDiv: reseveVisitInfo.memberDiv,
      //       memberGrade: reseveVisitInfo.memberGrade,
      //       paymtCode: reseveVisitInfo.paymtCode,
      //       holeDiv: this.teamInfo.teamHoleDiv,
      //       visitConfirmFlag: false, // 내장확정여부는 false. 락카 배정시 true 처리.
      //       joinGroupNo: "",
      //       contactTel: reseveVisitInfo.contactTel,
      //       nativeDiv: reseveVisitInfo.nativeDiv,
      //       chkinTime: null,
      //       chkoutTime: null,
      //       dcContents: "",
      //       greenFeeInfo: {},
      //       dcList: [],
      //       mmbsCrtfcResultFlag: reseveVisitInfo.mmbsCrtfcResultFlag,
      //       mmbsCrtfcResult: reseveVisitInfo.mmbsCrtfcResult,
      //       birthDay: reseveVisitInfo.birthDay,
      //       focusIdx: 1, // 여러 건의 내장객 추가 시, 한 번만 포커싱해야 하는 경우에만 추가. focus 해야 하는 index를 명시
      //       focusCurrentIdx: this.teamInfo.visitCnt, // 여러 건의 내장객 추가 시, 한 번만 포커싱해야 하는 경우에만 추가. 현재 추가한 row index
      //       couponCount: reseveVisitInfo.couponCount,
      //     };
      //     this.$refs.visitsGrid.addRecord(addRecordObject); // record add 순서가 영키지 않기 위해서 우선 add부터. 요금 설정은 batchAdd 이벤트에서 처리
      //   });
      // }

      // chkinId가 없고, 4건이 안되는 경우 row 추가
      if (this.teamInfo && this.teamInfo.chkinId === undefined) {
        //let numToAddVisitInfoList = this.teamInfo.visitCnt - this.visitInfoList.length - this.resveVisitInfoList.length;
        let numToAddVisitInfoList = this.teamInfo.visitCnt - this.visitInfoList.length ;
        if (numToAddVisitInfoList > 0) {
          for (let addVisitInfoListIdx = 0; addVisitInfoListIdx < numToAddVisitInfoList; addVisitInfoListIdx++) {
            //await this.addVisitsInfoRecord(this.visitInfoList.length + this.resveVisitInfoList.length + addVisitInfoListIdx + 1, addVisitInfoListIdx + 2, this.teamInfo.visitCnt);
            await this.addVisitsInfoRecord(this.visitInfoList.length+ addVisitInfoListIdx + 1, addVisitInfoListIdx + 2, this.teamInfo.visitCnt);
          }
          // 카트료 재 계산
          // this.getCartPriceAmt()
        }
      }

      // this.$refs.visitsGrid.clearSelection();
    },
    /**
     * 데이터 순번 체크. 순번이 잘못되어 있으면 순서대로(1,2,3,4) 다시 매긴다
     */
    checkAndResetVisitsInfoListSno: function () {
      if (!this.checkVisitsInfoListSno()) {
        // for (
        //   let visitInfoListIdx = 0;
        //   visitInfoListIdx < this.visitInfoList.length;
        //   visitInfoListIdx++
        // ) {
        //   this.$refs.visitsGrid.updateCell(
        //     visitInfoListIdx,
        //     "visitSno",
        //     visitInfoListIdx + 1
        //   );
        // }
      }
    },
    /**
     * 내장객 그리드에 1건의 내장객 추가
     */
    addVisitsInfoRecord(
      visitSno,
      focusIdx = null,
      focusCurrentIdx = null,
      cmpnInfo = null,
    ) {
      return new Promise((resolve) => {
        const defaultPaymtCode = commonCodeGetComCodeDefaultValue("PAYMT_CODE");

        let addRecordObject = {
          visitId: null,
          startTime: this.teamInfo.startTime,
          startCourse: this.teamInfo.startCourse,
          visitName: cmpnInfo?.visitName || "",
          visitSno: visitSno, // 내장순번. 순서대로 설정
          lockerNo: "",
          sexCode: cmpnInfo?.sexCode
            ? cmpnInfo.sexCode
            : commonCodeGetComCodeDefaultValue("SEX_CODE"),
          membershipId: cmpnInfo?.membershipId,
          memberNo: cmpnInfo?.memberNo,
          memberDiv: cmpnInfo?.memberDiv
            ? cmpnInfo.memberDiv
            : commonCodeGetComCodeDefaultValue("MEMBER_DIV"),
          memberGrade: cmpnInfo?.memberGrade
            ? cmpnInfo.memberGrade
            : commonCodeGetComCodeDefaultValue("MEMBER_GRADE"),
          paymtCode: defaultPaymtCode,
          holeDiv: this.teamInfo.teamHoleDiv,
          visitConfirmFlag: false, // 내장확정여부는 false. 락카 배정시 true 처리.
          joinGroupNo: "",
          contactTel: cmpnInfo?.contactTel,
          nativeDiv: commonCodeGetComCodeDefaultValue("NATIVE_DIV"),
          chkinTime: null,
          chkoutTime: null,
          dcContents: "",
          greenFeeInfo: {},
          dcList: [],
          focusIdx: focusIdx !== null ? focusIdx : undefined,
          focusCurrentIdx:
            focusCurrentIdx !== null ? focusCurrentIdx : undefined,
        };
        this.$refs.visitsGrid.addRecord(addRecordObject); // record add 순서가 영키지 않기 위해서 우선 add부터. 요금 설정은 batchAdd 이벤트에서 처리
        resolve();
      });
    },
    /**
     * 현재 그리드의 데이터 순번 체크
     * ex) 총 row가 3건이면 1, 2, 3이 하나씩 있어야 함
     */
    checkVisitsInfoListSno: function () {
      let retValue = true;
      let currentVisitGridVisitSnos = this.$refs.visitsGrid
        .getBatchCurrentViewRecords()
        .map((visitInfo) => visitInfo.visitSno);

      // 순번 컬럼 array 체크
      for (
        let visitInfoListIdx = 0;
        visitInfoListIdx < currentVisitGridVisitSnos.length;
        visitInfoListIdx++
      ) {
        const findedVisitInfos = currentVisitGridVisitSnos.filter(
          (visitSno) => visitSno === visitInfoListIdx + 1
        );
        if (findedVisitInfos.length !== 1) {
          retValue = false;
          break;
        }
      }
      return retValue;
    },
    /**
     * 데이터 초기화
     */
    clearCheckinInfos: function () {
      this.timeData = null;

      this.teamInfo = null;
      this.originalTeamInfo = null;
      this.visitInfoList = [];
      this.originalVisitInfoList = [];
      this.resveVisitInfoList = [];
      this.resveInfo = null;
      this.originalResveInfo = null;
      this.memberImageInfo = [];
      this.originalMemberImageInfo = [];
      this.caddieInfoList = [];
      this.originalCaddieInfoList = [];
      this.paySettleTeamInfoList = [];
      this.paySettleTeamInfoDivideNumber = 0;
      this.storeSaleInfoList = [];
      this.payInfoList = [];

      this.isGridFormCreated = false;
    },

    onTeamInfoGrpNameChanged: function (args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearTeamGrpInfo();
          return;
        }
        getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setTeamGrpInfos(groupList[0]);
            } else {
              this.onGroupPopupOpen(false, "TEMP");
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    setTeamGrpInfos: function (grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.teamInfo.grpNo = grpInfo.grpNo;
      this.teamInfo.grpKind = grpInfo.grpKind;
      this.teamInfo.grpName = grpInfo.grpName;
    },
    clearTeamGrpInfo: function () {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.teamInfo.grpNo = null;
      this.teamInfo.grpKind = "FIT";
      this.teamInfo.grpName = null;
    },
    onGroupPopupOpen: function (isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.teamInfo.grpName
        ? (groupPopupData.grpName = this.teamInfo.grpName)
        : null;
      groupPopupData.resveDates = [this.teamInfo.visitDate];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.teamInfo.grpName;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onDutchPayByItemPopupClose() {
      this.isDutchPayByItemPopupOpen = false;
      this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
    },
    onGroupPopupClosed: function (event) {
      this.isGroupPopupOpen = false;

      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setTeamGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setTeamGrpInfos(event.selectedGroup);
        } else {
          this.clearTeamGrpInfo();
        }
      }
    },
    smsSendPopupClosed() {
      this.isSmsSendPopupOpen = false;
    },
    onTeamInfoBenMembershipNameChanged: function (args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearTeamBenMembershipInfo();
          return;
        }
        getMemberList({ memberNameNo: args.value })
          .then((response) => {
            const memberList = response.value.memberList;

            if (memberList && memberList.length > 1) {
              // 2건 이상 검색 : 팝업을 띄운다
              this.onMemberPopupOpen(
                false,
                this.memberPopupType.BEN_MEMBERSHIP,
                args.value
              );
            } else if (memberList && memberList.length === 1) {
              // 1건 검색 : 회원 설정
              this.setTeamBenMembershipInfo(memberList[0]);
            } else {
              // 검색 건수 없음 : 미설정
              this.clearTeamBenMembershipInfo();
            }
          })
          .catch((error) => {
            console.log("getMemberList.err.===>", error);
          });
      }
    },
    setTeamBenMembershipInfo: function (memberInfo) {
      this.teamInfo.benMembershipId = memberInfo.membershipId;
      this.teamInfo.benMembershipName = memberInfo.memberName;
    },
    clearTeamBenMembershipInfo: function () {
      this.teamInfo.benMembershipId = null;
      this.teamInfo.benMembershipName = null;
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      selectedGridIdx = null,
      focusGridRowColumn = null,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;
      this.isCheckinLayerOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberGradeChanged = memberGradeChanged;
      memberData.bsnDate = this.bsnDate;
      if (selectedGridIdx !== null) {
        memberData.selectedIdx = selectedGridIdx;
      }
      if (focusGridRowColumn !== null) {
        memberData.focusGridRowColumn = focusGridRowColumn;
      }
      //지정락카 사용여부 체크 추가 (2024.05.09 tomas)
      memberData._isAppnLockerCheck = true;

      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    /**
     * 내장객 팝업 Open, API 호출 등으로 인해 focus가 사라질 때, 이전에 포커싱했던 컬럼으로 재 포커싱
     * @param currentRowIndex 이전에 포커싱했던 컬럼의 row index
     * @param editingCellField 이전에 포커싱했던 컬럼의 field명
     */
    reEditCellWithPreEditedCell(currentRowIndex, editingCellField) {
      if (currentRowIndex >= 0 && editingCellField) {
        this.$refs.visitsGrid.editCell(currentRowIndex, editingCellField);
      }
    },
    async memberSelectPopupClosed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.BEN_MEMBERSHIP) {
        // 회원특전
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          this.clearTeamBenMembershipInfo();
        }
      } else if (
        data.popupData.popupType === this.memberPopupType.VISIT_MEMBER
      ) {
        const {findVisitLists} = await GolfErpAPI.fetchCheckForTheSameVisitorName({
          visitDate: this.teamInfo.visitDate,
          visitName: data.popupData.memberNameNo,
          visitId: this.visitInfoList[this.visitsGridSelectedIdx]?.visitId
        });

        if (findVisitLists?.length > 0) {
          const addMessage = findVisitLists.map(visitorInfo =>
              `<br>시간/코스 : ${visitorInfo.startTime.substring(0,2)}:${visitorInfo.startTime.substring(2,4)}/${commonCodesGetComName("COURSE_CODE", visitorInfo.startCourse)}`
          ).join();
          if (!(await this.confirm("동일 이름의 내장자가 등록되어 있습니다." + addMessage + "<br>계속하시겠습니까?"))) {
            return;
          }
        } else {
          const visitsGridViewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();

          if (visitsGridViewRecords.length > 0) {
            if (visitsGridViewRecords.filter((record, index) => record.visitName === data.popupData.memberNameNo && index !== this.visitsGridSelectedIdx).length > 0) {
              if (!(await this.confirm("현재 팀의 내장객등록 데이터에 동일 이름의 내장자가 등록되어 있습니다.<br>계속하시겠습니까?"))) {
                return;
              }
            }
          }
        }
        // 내장객 팝업
        if (!data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우)
            await this.setVisitNonMemberInfo(data.popupData.selectedIdx);
          }
        }

        // 회원을 선택하지 않고 닫은 경우에는 현재 편집중이던 내장객 선택
        this.reEditCellWithPreEditedCell(
          data.popupData.selectedIdx,
          "visitName"
        );
      }
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
      const {findVisitLists} = await GolfErpAPI.fetchCheckForTheSameVisitorName({
        visitDate: this.teamInfo.visitDate,
        visitName: data.selectedRowData.memberName,
        visitId: this.visitInfoList[this.visitsGridSelectedIdx]?.visitId
      });

      this.reservationCheck(
          null,
          null,
          data.selectedRowData.membershipId
      );

      if (findVisitLists?.length > 0) {
        const addMessage = findVisitLists.map(visitorInfo =>
            `<br>시간/코스 : ${visitorInfo.startTime.substring(0,2)}:${visitorInfo.startTime.substring(2,4)}/${commonCodesGetComName("COURSE_CODE", visitorInfo.startCourse)}`
        ).join();
        if (!(await this.confirm("동일 이름의 내장자가 등록되어 있습니다." + addMessage + "<br>계속하시겠습니까?"))) {
          return;
        }
      } else {
        const visitsGridViewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();

        if (visitsGridViewRecords.length > 0) {
          if (visitsGridViewRecords.filter((record, index) => record.visitName === data.selectedRowData.memberName && index !== this.visitsGridSelectedIdx).length > 0) {
            if (!(await this.confirm("현재 팀의 내장객등록 데이터에 동일 이름의 내장자가 등록되어 있습니다.<br>계속하시겠습니까?"))) {
              return;
            }
          }
        }
      }

      if (data.popupData.popupType === this.memberPopupType.BEN_MEMBERSHIP) {
        // 회원특전
        if (data.selectedRowData) {
          this.setTeamBenMembershipInfo(data.selectedRowData);
        }
      } else if (
        data.popupData.popupType === this.memberPopupType.VISIT_MEMBER
      ) {
        // 내장객 팝업
        if (!data.selectedRowData.membershipId) {
          await this.setVisitNonMemberInfo(
            data.popupData.selectedIdx,
            data.selectedRowData
          );
        } else {
          try {
            await this.setVisitMemberInfo(
              data.selectedRowData,
              data.popupData.selectedIdx
            );
          } catch (e) {
            console.log("setVisitMemberInfo.err.===>", e);
          }
        }

        // 그리드 포커싱 데이터가 있는 경우 - 내장객 등록 그리드 포커싱
        this.reEditCellWithPreEditedCell(
          data.popupData.focusGridRowColumn.currentRowIndex,
          data.popupData.focusGridRowColumn.editingCellField
        );
      }
    },
    async changePayerPopupConfirmed(data, slipId) {
      this.changePayerPopupClosed();
      const storeSaleInfo = this.storeSaleInfoList.filter(
        (storeSaleInfo) => slipId === storeSaleInfo.slipId
      );
      const arg = {
        bsnDate: this.teamInfo.visitDate,
        salesTransMapId: storeSaleInfo[0].salesTransMapId,
      };

      await putPayerChange(slipId, data.visitId, storeSaleInfo[0].optFlag, arg);
      this.infoToast("지불자 변경을 완료했습니다");
      this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
    },
    async changePayerPopupConfirmed2(data, payId) {
      this.changePayerPopupClosed();
      await putPayerChange2(payId, data.visitId, this.teamInfo.visitDate);
      this.infoToast("정산자 변경을 완료했습니다");
      this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
    },
    /**
     * 캐디피 영수증 발행
     * @param caddieInfo
     */
    async openCaddieFee(caddieInfo) {
      if (await this.confirm("캐디피 영수증을 출력하시겠습니까?")) {
        let selectedRecords = null;
        if (this.stepIndex === 0) {
          selectedRecords = this.$refs.visitsGrid.getSelectedRecords();
        } else if (this.stepIndex === 1) {
          selectedRecords = this.$refs.payGrid.getSelectedRecords();
        } else if (this.stepIndex === 2) {
          selectedRecords = this.$refs.storeGrid.getSelectedRecords();
        } else {
          this.errorToast("선택된 내장객이 존재하지 않습니다.");
          return;
        }

        if (!(selectedRecords.length > 0)) {
          this.errorToast("선택된 내장객이 존재하지 않습니다.");
          return;
        }

        this.isCaddieFeePopupOpen = true;
        this.$nextTick(() => {
          this.$refs.caddieFeePopup.showCaddieFeePopup(
            selectedRecords[0].visitName,
            caddieInfo,
            this.teamInfo.visitDate
          );
        });
      }
    },
    caddieFeePopupClosed: function () {
      this.isCaddieFeePopupOpen = false;
    },
    async discountPopupClosed(args) {
      this.isDiscountPopupOpen = false;

      if (!args) {
        throw new Error("invalid argument in discountPopupClosed");
      }

      try {
        if (args.dcList) {
          let newDcContents = [];
          args.dcList.forEach(dcInfo => {
            newDcContents.push(dcInfo.dcContents);
          });

          this.$refs.visitsGrid.updateCell(
            args.rowIndex,
            "dcContents",
            newDcContents.join(", ")
          );
        }
        const viewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();
        const hasNormalDiscount = viewRecords[args.rowIndex].hasNormalDiscount;
        const hasCouponDiscount = viewRecords[args.rowIndex].hasCouponDiscount;

        if (args.dcList && args.greenFeeInfo) {
          const dcList = args.dcList.map((data, idx) => {
            data.dcUseDate = this.bsnDate.split("-").join("");
            data.dcSno = idx + 1;
            return data;
          });

          // greenFeeInfo, dcList 업데이트
          this.$refs.visitsGrid.updateCell(
            args.rowIndex,
            "greenFeeInfo",
            args.greenFeeInfo
          );
          this.$refs.visitsGrid.updateCell(args.rowIndex, "dcList", dcList);
          if (
            hasNormalDiscount !==
            !!dcList?.find(({ dcApplyDiv }) => dcApplyDiv === "NOR")
          ) {
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "hasNormalDiscount",
              !!dcList?.find(({ dcApplyDiv }) => dcApplyDiv === "NOR")
            );
          }

          if (
            hasCouponDiscount !==
            !!dcList?.find(({ dcApplyDiv }) => dcApplyDiv === "COUPON")
          ) {
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "hasCouponDiscount",
              !!dcList?.find(({ dcApplyDiv }) => dcApplyDiv === "COUPON")
            );
          }

          // 할인 내역 중 할인 방법이 "요금코드" 인 내역이 있을 경우 - 할인 내역에 설정한 요금코드를 내장객 "요금구분" 으로 업데이트
          const currentPaymtCode = this.$refs.visitsGrid.getBatchCurrentViewRecords()[
            args.rowIndex
          ].paymtCode;
          const paymtDcInfo = dcList.find(
            (dcInfo) => dcInfo.dcMethod === "PAYMT"
          );

          if (paymtDcInfo && paymtDcInfo.paymtCode !== currentPaymtCode) {
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "paymtCode",
              paymtDcInfo.paymtCode
            );
            await this.getFixedPaymentCodeAndUpdateGreenFee(args.rowIndex);
          } else {
            // 요금 계산
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "greenFeeDc",
              this.calculateGreenFeeDc(
                currentPaymtCode,
                args.greenFeeInfo,
                dcList
              )
            );
          }
          this.computeVisitGridAggregation();

          if (!viewRecords[args.rowIndex].dcContents) {
            let dcContents = "";
            args.dcList.map((data) => {
              if (dcContents) {
                dcContents += ", ";
              }
              dcContents += data.dcContents;
            });
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "dcContents",
              dcContents
            );
          }
        } else {
          const dcList = viewRecords[args.rowIndex].dcList;
          const couponDcList = dcList.filter(
            (data) => data.dcApplyDiv === "COUPON"
          );
          const norDcList = dcList.filter((data) => data.dcApplyDiv === "NOR");

          if (!(norDcList.length > 0)) {
            if (hasNormalDiscount) {
              this.$refs.visitsGrid.updateCell(
                args.rowIndex,
                "hasNormalDiscount",
                false
              );
            }
          }

          if (!(couponDcList.length > 0)) {
            if (hasCouponDiscount) {
              this.$refs.visitsGrid.updateCell(
                args.rowIndex,
                "hasCouponDiscount",
                false
              );
            }
          }
        }

        if (args.promtnDelFlag) {
          this.$refs.visitsGrid.updateCell(
            args.rowIndex,
            "promtnDelFlag",
            true
          );
        }
      } catch (e) {
        console.error(e);
        this.errorToast("요금 조회 중 오류가 발생하였습니다");
      } finally {
        // 할인 필드 포커싱
        await this.$nextTick();
        this.$refs.visitsGrid.selectCell({
          rowIndex: args.rowIndex,
          field : "discountIcon",
          /*cellIndex: this.visitsGridColumns.findIndex(
            (column) => column.field === "discountIcon"
          ),*/
        });
      }
    },
    visitsCopyPopupClosed(args) {
      this.isVisitsCopyPopupOpen = false;

      // 내장객 복사 처리
      if (args) {
        if (args.visitInfoList && args.visitInfoList.length > 0) {
          const gridRef = this.$refs["visitsGrid"];
          const records = gridRef.getBatchCurrentViewRecords();

          // 현재 레코드 모두 삭제
          if (0 < records?.length) {
            gridRef.batchCancel();
          }

          this.$nextTick(() => {
            const generator = function* () {
              const visitInfoList = JSON.parse(
                JSON.stringify(args.visitInfoList)
              );

              for (let i = 0; i < visitInfoList.length; i++) {
                const visit = visitInfoList[i];
                this.$refs.visitsGrid.addRecord({
                  visitId: null,
                  startTime: this.teamInfo.startTime,
                  startCourse: this.teamInfo.startCourse,
                  visitName: visit.visitName,
                  visitSno: i + 1, // 내장순번. 순서대로 설정
                  lockerNo: visit.lockerNo,
                  sexCode: visit.sexCode,
                  membershipId: visit.membershipId,
                  memberNo: visit.memberNo,
                  memberDiv: visit.memberDiv,
                  memberGrade: visit.memberGrade,
                  paymtCode: visit.paymtCode,
                  holeDiv: this.teamInfo.teamHoleDiv,
                  visitConfirmFlag: false, // 내장확정여부는 false. 락카 배정시 true 처리.
                  joinGroupNo: visit.joinGroupNo,
                  contactTel: visit.contactTel,
                  nativeDiv: visit.nativeDiv,
                  chkinTime: null,
                  chkoutTime: null,
                  dcContents: "",
                  greenFeeInfo: {},
                  dcList: [],
                  mmbsCrtfcResultFlag: visit.mmbsCrtfcResultFlag,
                  mmbsCrtfcResult: visit.mmbsCrtfcResult,
                  birthDay: visit.birthDay,
                  hasNormalDiscount: false,
                  hasCouponDiscount: false,
                  visitCopyFlag: true,
                });

                yield true;
              }

              yield false;
            };

            const gen = generator.apply(this, []);
            const hook = (_gen) => {
              if (_gen.next().value) {
                this.$nextTick(() => {
                  hook(_gen);
                });
              }
            };

            hook(gen);
          });

          // 카트료 재 계산
          // this.getCartPriceAmt()

          /*
        this.$refs.visitsGrid.getDataRows().forEach((row) => {
          console.log(row)
          this.$refs.visitsGrid.deleteRow(row)
        })
       */
        }
      }
    },
    saleInfoPopupClosed: function (args) {
      this.isSaleInfoPopupOpen = false;

      if (args !== undefined && args.isReload) {
        // 상세 조회
        this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
      }
    },
    divideByNPopupClosed: function (args) {
      this.isDivideByNPopupOpen = false;

      if (args !== undefined && args.isReload) {
        // 상세 조회
        this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
      }
    },
    partialSumPopupClosed: function (args) {
      this.isPartialSumPopupOpen = false;

      if (args !== undefined && args.isReload) {
        // 상세 조회
        this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
      }
    },
    payCancelPopupClosed: function (args) {
      this.isPayCancelPopupOpen = false;

      if (args !== undefined && args.isReload) {
        // 상세 조회
        this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
      }
    },
    payReceiptPopupClosed: function () {
      this.isPayReceiptPopupOpen = false;
    },
    paymentStatementPopupClosed() {
      this.isPaymentStatementPopupOpen = false;
    },
    cellBooleanEveryCheck(ref,field) {
      return this.$refs[ref].getBatchCurrentViewRecords().every((data)=> data[field]);
    },
    async payPopupClosed(payload) {
      this.isPayPopupOpen = false;
      await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);

      this.$nextTick(async () => {
        if (payload?.isPaid)
        {
          //선불정산
          if(payload?.isPrpayFrpyDiv === "0")
          {
            const visitRow  = this.visitInfoList.filter(item => item.visitId === payload.visitId)[0];
            const payRow    = this.paySettleTeamInfoList.filter(item => item.visitId === payload.visitId)[0];

            const lockerNo          = visitRow.lockerNo;
            const lockerReturnFlag  = payRow.lockerReturnFlag;
            const noPayAmt          = payRow.noPayAmt;
            //금액 0 , 락커반납 안함, 999,000 아닐경우 자동 락커 출력
            if(noPayAmt === 0 && !lockerReturnFlag && !['999', '000'].includes(lockerNo))
            {
              console.log('선불 후 락카 자동 발행', payload.visitId);
              const printSetData = {
                visitDate   : visitRow.visitDate,
                visitName   : visitRow.visitName,   //마스킹 없음
                originName  : visitRow.visitName,
                lockerNo    : visitRow.lockerPrtNo,
                startCourse : commonCodesGetComName( "COURSE_CODE", visitRow.startCourse ),
                startTime   : visitRow.startTime,
                sexName     : commonCodesGetComName("SEX_CODE", visitRow.sexCode),
                dwName      : moment(visitRow.visitDate).format("dd"),
                //dwCode      : this.bsnDateInfo?.dwCode,
                //sectorPrt   : visitRow.sector,
              };
              const config = {"useBillPrinter": true, "useKitchenPrinter": false,};
              await BillPrintUtil.getTsConfReceiptInfo.call(this, "LOCKER_KEY", true, printSetData, config);
            }
          }
          //후불정산
          else{
            const total = this.paySettleTeamInfoList.reduce(
                (previousValue, { noPayAmt }) => previousValue + noPayAmt,
                0
            );
            if (0 === total) {
              const allLockerReturnFlag = this.cellBooleanEveryCheck("payGrid","lockerReturnFlag");
              if(!allLockerReturnFlag  && (await this.confirm(`체크아웃 하시겠습니까?`))) {
                return this.checkOutConfirm();
              }
            }

            const currentIndex = this.$refs["payGrid"].getSelectedRowIndexes()[0];

            if (0 < total && this.personFlag) {
              // 1. 정산 후 본인 미정산액이 존재하면 본인 선택 유지
              // 2. 본인 미정산액이 없으면 미정산액이 존재하는 내장객 선택
              // 3. 미정산액이 존재하는 내장객이 없으면 본인 선택 유지.
              const visitId = this.paySettleTeamInfoList[currentIndex].visitId;
              const filterData = this.paySettleTeamInfoList.filter(
                  (data) => data.noPayAmt > 0
              );

              let selecteRowIndex = currentIndex;

              if (
                  filterData.length > 0 &&
                  filterData.filter((data) => data.visitId === visitId).length === 0
              ) {
                // // 정산이 끝나면 락카키 반납.
                // await saveLockerCaddieFee({
                //   visitId: this.paySettleTeamInfoList[currentIndex].visitId,
                //   lockerReturnFlag: true,
                //   visitOptFlag: this.paySettleTeamInfoList[currentIndex].visitOptFlag,
                //   lockerNo: this.paySettleTeamInfoList[currentIndex].lockerNo,
                //   bsnDate: this.teamInfo.visitDate,
                // })
                //   .then((response) => {
                //     this.paySettleTeamInfoList[currentIndex].visitOptFlag = response.value.visitOptFlag;
                //     this.paySettleTeamInfoList[currentIndex].lockerReturnFlag = true;
                //   })
                //   .catch(() => {});
                selecteRowIndex = this.$refs["payGrid"].getRowIndexByPrimaryKey(
                    filterData[0].visitId
                );
              }

              this.$refs["payGrid"].selectRow(selecteRowIndex);
              this.$refs["payGrid"].selectCell({
                rowIndex: selecteRowIndex,
                field : "noPayAmt",
                /*cellIndex: this.payGridColumns.findIndex(
                    (column) => column.field === "noPayAmt"
                ),*/
              });
            }
          }
        }
      });
    },
    lockerIssuePopupClosed() {
      this.isLockerIssuePopupOpen = false;
    },
    openReceiptPopupClosed() {
      this.isOpenReceiptPopupOpen = false;
    },
    reIssueReceiptPopupClosed() {
      this.isReIssueReceiptPopupOpen = false;
    },
    changePayerPopupClosed() {
      this.isChangePayerPopupOpen = false;
    },
    onCartDiscountPopupSubmit() {
      this.$emit("footerMessage", "카트 할인이 적용되었습니다.");
    },
    cartDiscountPopupClose() {
      this.isCartDiscountPopupOpen = false;
      this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
    },
    getStepDivParam: function (stepIndex) {
      switch (stepIndex) {
        case 0:
          return "VISIT";
        case 1:
          return "PAY";
        case 2:
          return "SALE";
        default:
          return null;
      }
    },
    visitsGridHeaderCellInfo: function (args) {
      if (
        args.cell.column.field === "visitName" ||
        args.cell.column.field === "sexCode" ||
        args.cell.column.field === "memberGrade" ||
        args.cell.column.field === "paymtCode" ||
        args.cell.column.field === "holeDiv"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }

      if (args.cell.column.field === "visitNameIcon") {
        args.node.classList.add(this.$t("className.grid.searchIconArea"));
      } else if (args.cell.column.field === "lockerNo") {
        args.node.innerText = '락카';
        args.node.style.textAlign = 'right';
        args.node.classList.add(this.$t("className.grid.requiredLockerHeader"));
      } else if (args.cell.column.field === "visitConfirmFlag") {
        args.node.innerText = '확정';
        args.node.style.textAlign = 'right';
        args.node.classList.add(this.$t("className.grid.confirmArea"));
      } else if (args.cell.column.field === "chkinTime") {
        args.node.classList.add(this.$t("className.frontCheckin.checkinTimeIcon"));
      } else if (args.cell.column.field === "chkoutTime") {
        args.node.classList.add(this.$t("className.frontCheckin.checkoutTimeIcon"));
      }

      if (
          args.cell.column.field === "hasNormalDiscount" ||
          args.cell.column.field === "hasCouponDiscount" ||
          args.cell.column.field === "discountIcon"
      ) {
        args.node.classList.add(this.$t("className.grid.nonePadding"));
      }

      if (args.cell.column.field === "paymtCode") {
        args.node.addEventListener("dblclick", this.visitsGridHeaderPaymtCodeDoubleClicked, false);
      } else if (args.cell.column.field === "holeDiv") {
        args.node.addEventListener("dblclick", this.visitsGridHeaderHoleDivDoubleClicked, false);
      } else if (args.cell.column.field === "prpayFlag") {
        args.node.addEventListener("dblclick", this.visitsGridHeaderPrpayFlagDoubleClicked, false);
      }
    },
    visitsGridQueryCellInfo: function (args) {
      const {
        cell,
        data,
        column: {
          field
        }
      } = args;
      if (
        cell.classList.contains("e-gridchkbox") ||
        field === "visitSno" ||
        field === "visitName" ||
        field === "lockerNo" ||
        field === "sexCode" ||
        field === "memberGrade" ||
        field === "paymtCode" ||
        field === "holeDiv" ||
        field === "mmbsCrtfcResultFlag" ||
        field === "visitConfirmFlag" ||
        field === "joinGroupNo" ||
        field === "contactTel" ||
        field === "nativeDiv" ||
        field === "hasNormalDiscount" ||
        field === "hasCouponDiscount" ||
        field === "dcContents" ||
        field === "prpayFlag"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      if (field === "hasCouponDiscount") {
        cell.classList.add("devHasCouponDiscount");
        if (data.couponCount > 0) {
          cell.style.backgroundColor = "#705DA8";
        }
      }

      // 수정영역 셀 처리
      // 검색 아이콘 영역
      if (field === "visitNameIcon") {
        cell.classList.add(this.$t("className.grid.searchIconArea"));
      }

      // 할인 아이콘 영역
      if (field === "discountIcon") {
        cell.classList.add(this.$t("className.grid.discountIconArea"));
      }

      if (field === "chkoutTime") {
        if (data.chkoutTime) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            "CHKOUT"
          );
          cell.style.color = "#ffffff";
        }
      }

      if (field === "startCourse") {
        cell.style.paddingLeft = "0";
        cell.style.paddingRight = "0";
        cell.style.textAlign = "center"; // Grid Column 설정의 textAlign이 paddingLeft/Right 설정 후 적용되지 않음.
      }

      // 컬럼 속성 추가 - 클릭 시 클릭한 컬럼 여부 파악을 위함
      cell.setAttribute("column-name", args.column.field);
    },
    getGridIdxByRowElement: function (trElement) {
      return this.$refs.visitsGrid
        .getDataRows()
        .findIndex((dataRow) => dataRow === trElement);
    },
    visitsGridCellSave(args) {
      switch (args.columnName) {
        case "sexCode":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
        case "memberGrade":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
        case "paymtCode":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
        case "holeDiv":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
        case "nativeDiv":
          this.setEjsDropdonwlistEditTemplateValue(args);
          break;
      }
      /*if (args.columnName === "visitName") {
        // test
        const editingCellField = this.$refs.visitsGrid.editingCellField;
        const currentRowIndex = this.$refs.visitsGrid.currentSelectedRowIndex;

        console.log(`editingCellField:${editingCellField}, currentRowIndex:${currentRowIndex}`);
      }*/
    },
    /**
     * dropdown을 적용한 template에서 값 변경 후 focus out 시 셀에 값을 설정해주기 위함
     */
    setEjsDropdonwlistEditTemplateValue(args) {
      args.value = args.cell.firstElementChild.getElementsByClassName(
        "e-dropdownlist"
      )[0].ej2_instances[0].itemData.comCode;
    },
    /**
     * focusing 한 컬럼이 비회원 처리로 인해 바뀔 컬럼인지 여부
     * @param selectedGridIdx : 편집중인 row index
     * @param currentRowIndex : focusing한 컬럼의 row index
     * @param editingCellField : focusing한 컬럼의 field명
     */
    isNonMemberSelectColumn(
      selectedGridIdx,
      currentRowIndex,
      editingCellField
    ) {
      if (
        selectedGridIdx === currentRowIndex &&
        (editingCellField === "sexCode" ||
          editingCellField === "contactTel" ||
          editingCellField === "memberGrade" ||
          editingCellField === "nativeDiv" ||
          editingCellField === "paymtCode")
      ) {
        return true;
      }
      return false;
    },
    async visitsGridCellSaved(args) {
      const { value, previousValue, columnName, cell, rowData } = args;
      if (columnName === "visitName") {
        // 내장객 정보
        // API 호출하여 비회원 여부 체크하는 로직 주석 처리 - 무조건 팝업 띄움
        const selectedGridIdx = this.getGridIdxByRowElement(
          cell.closest("tr")
        ); // 현재 편집중인 row index 값

        //if 재검색시 전에 검색된 데이터와 같으면 막는 조건
        if (previousValue !== value) {

          if (value.trim() === "") {
            // 빈 칸인 경우에는 비회원 설정
            // 빈 칸으로 편집 -> 성별/회원등급/요금구분 focusing 해도 바뀐 값으로 정상적으로 focusing 되므로, 따로 save, focusing 처리를 하지 않았음
            // 문제가 생기면 그리드 save -> setVisitNonMemberInfo -> 셀 포커싱
            this.setVisitNonMemberInfo(selectedGridIdx);
            return;
          }

          try {
            /*const {
              value: { memberList }
            } = await getMemberList({memberNameNo: args.value}, true);*/
            await this.$nextTick(); // this.$refs.visitsGrid.editingCellField 에 현재 선택하는 컬럼을 가져올 여유시간을 주기 위해 다음 tick까지 기다림

            // 현재 선택된 rowIndex, fieldName 가져오기. saveCell() 전에 가져와야 함
            const editingCellField = this.$refs.visitsGrid.editingCellField;
            const currentRowIndex = this.$refs.visitsGrid
              .currentSelectedRowIndex;

            // if (memberList && memberList.length > 0) {
            // 팝업 띄우기 전 save 처리
            this.$refs.visitsGrid.saveCell();

            // 2건 이상 검색 : 팝업을 띄운다 - 현재는 무조건 팝업을 띄움
            this.onMemberPopupOpen(
              false,
              this.memberPopupType.VISIT_MEMBER,
              value,
              selectedGridIdx,
              {
                editingCellField: editingCellField,
                currentRowIndex: currentRowIndex,
              }
            );
            /*} else { // 검색 건수 없음 : 비회원 설정
              // 현재 선택된 rowIndex, fieldName 가져오기. saveCell() 전에 가져와야 함
              const editingCellField = this.$refs.visitsGrid.editingCellField;
              const currentRowIndex = this.$refs.visitsGrid.currentSelectedRowIndex;

              // setVisitNonMemberInfo 에서 변경될 컬럼이면 save 먼저
              if(this.isNonMemberSelectColumn(selectedGridIdx, currentRowIndex, editingCellField)) {
                this.$refs.visitsGrid.saveCell();
              }

              await this.setVisitNonMemberInfo(selectedGridIdx);

              // setVisitNonMemberInfo 에서 변경된 컬럼이면 focusing
              if(this.isNonMemberSelectColumn(selectedGridIdx, currentRowIndex, editingCellField)) {
                this.reEditCellWithPreEditedCell(currentRowIndex, editingCellField);
              }
            }*/
          } catch (e) {
            console.log("getTimeInfo.err.===>", e);
            await this.errorToast("회원정보 조회 중 에러가 발생하였습니다");
          }
        }  //  args.previousValue !== args.value 에대한 스코프
        // else {
        //   console.log("같은데이터");
        // }
      }

      if (columnName === "visitSno") {
        // 힐마루 골프장용 내장객 순번 체크(힐마루는 내장객 순번 수정 가능하게 요청함)
        const visitSno = Number(value ? value : 0);
        if (visitSno === 0 || visitSno > this.teamInfo.visitCnt) {
          this.errorToast(`내장순번은 0보다 높거나 내장인원수(${this.teamInfo.visitCnt})보다 같거나 낮아야 합니다.`);
          const selectedGridIdx = this.getGridIdxByRowElement(
            cell.closest("tr")
          ); // 현재 편집중인 row index 값
          this.$refs.visitsGrid.updateCell(
            selectedGridIdx,
            "visitSno",
            previousValue
          );
          return;
        }

        // NO 편집하지 않기 때문에 주석 처리
        /*if (args.previousValue !== args.value) {
          const selectedGridIdx = this.getGridIdxByRowElement(
            args.cell.closest("tr")
          ); // 현재 편집중인 row index 값
          const currentGridDataRows = this.$refs.visitsGrid.getDataRows();
          const currentGridLength = currentGridDataRows.length;

          if (args.value <= 0 || args.value > currentGridLength) {
            alert("입력하실 수 없는 값입니다");
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "visitSno",
              args.previousValue
            );
          } else {
            this.reArrangeGridVisitSno(
              args.previousValue,
              args.value,
              selectedGridIdx
            );
            // 카트료 재 계산
            // this.getCartPriceAmt()
          }
        }*/
      }

      if (columnName === "lockerNo") {
        if (previousValue !== value) {
          const notSettingLockerNo = () => {
            const selectedGridIdx = this.getGridIdxByRowElement(
                cell.closest("tr")
            );
            this.$refs.visitsGrid.updateCell(
                selectedGridIdx,
                "lockerNo",
                previousValue
            );
          };
          this.checkInDuplicateName(rowData,notSettingLockerNo);
          this.visitDuplicateName(rowData,notSettingLockerNo);
          if( rowData.membershipId ) {
            this.reservationCheck(
                null,
                null,
                rowData.membershipId
            );
          }
          const selectedGridIdx = this.getGridIdxByRowElement(
            cell.closest("tr")
          ); // 현재 편집중인 row index 값
          await this.locketStatusSettingProcess(
            selectedGridIdx,
            value,
            rowData.visitId,
            previousValue,
            rowData.sexCode
          );
        }
        this.isAlreadyAssignedLockNoFlag = false;
      }

      if (columnName === "holeDiv") {
        this.teamInfo.teamHoleDiv = maxBy(
          this.$refs.visitsGrid
            .getBatchCurrentViewRecords()
            .map(({ holeDiv }) => ({
              holeDiv,
              holeWeight: holeDiv.includes("+")
                ? Number(holeDiv.replace(/\+/gi, "")) * 10 + 5
                : Number(holeDiv) * 10,
            })),
          "holeWeight"
        ).holeDiv;
      }
    },
    updateGridGreenFee(gridIdx, newGreenFeeInfo, dcList) {
      // 요금 재 계산
      calculateApplyAmt(newGreenFeeInfo, dcList);

      // 요금 update
      this.$refs.visitsGrid.updateCell(
        gridIdx,
        "greenFeeInfo",
        newGreenFeeInfo
      );
      this.$refs.visitsGrid.updateCell(gridIdx, "dcList", dcList);

      // 요금 계산
      this.$refs.visitsGrid.updateCell(
        gridIdx,
        "greenFeeDc",
        this.calculateGreenFeeDc(newGreenFeeInfo.paymtCode, newGreenFeeInfo)
      );

      this.computeVisitGridAggregation();
    },
    reArrangeGridVisitSno: function (fromValue, toValue, currentIdx) {
      let currentVisitInfo = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      currentVisitInfo.forEach((visit, visitIdx) => {
        let rowVisitSno = visit.visitSno;
        if (fromValue < toValue) {
          // 새로 바꾼 값이 더 큰 경우, 이전값 ~ (새로운값 -1) 값들을 -1
          if (
            fromValue < rowVisitSno &&
            rowVisitSno <= toValue &&
            visitIdx !== currentIdx
          ) {
            this.$refs.visitsGrid.updateCell(
              visitIdx,
              "visitSno",
              rowVisitSno - 1
            );
          }
        } else if (fromValue > toValue) {
          // 새로 바꾼 값이 더 작은 경우, 새로운 값 ~ (이전값 -1) 값들을 +1
          if (
            toValue <= rowVisitSno &&
            rowVisitSno < fromValue &&
            visitIdx !== currentIdx
          ) {
            this.$refs.visitsGrid.updateCell(
              visitIdx,
              "visitSno",
              rowVisitSno + 1
            );
          }
        }
      });
    },
    /**
     * 내장객 그리드에 내장객 정보 설정
     * @param memberInfo
     * @param selectedGridIdx
     */
    async setVisitMemberInfo(memberInfo, selectedGridIdx) {
      // 회원정보로 내장객 정보 셋팅 전 기존 데이터를 가져온다.
      const batchCurrentViewRecords = JSON.parse(JSON.stringify(this.$refs.visitsGrid.getBatchCurrentViewRecords()));

      // 회원 : 성별, 회원권ID, 회원번호, 회원구분, 회원등급, 연락처, 내국인여부. 이름은 추가해야 할 것 같아서 넣었음
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "visitName",
        memberInfo.memberName
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "bagName",
        memberInfo.bagName
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "sexCode",
        memberInfo.sexCode
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "membershipId",
        memberInfo.membershipId
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "memberNo",
        memberInfo.memberNo
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "memberDiv",
        memberInfo.memberDiv
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "memberGrade",
        memberInfo.memberGrade
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "couponCount",
        memberInfo.couponCount
      );

      if (this.$refs.visitsGrid.getRowByIndex(selectedGridIdx).getElementsByClassName("devHasCouponDiscount").length > 0) {
        this.$refs.visitsGrid.getRowByIndex(selectedGridIdx).getElementsByClassName("devHasCouponDiscount")[0].style.backgroundColor =
          memberInfo.couponCount > 0 ? "#705DA8" : "";
      }

      // 암호화데이터 열람 권한이 있어야 설정 가능
      if (this.commonMixinHasCiperTextGet) {
        this.$refs.visitsGrid.updateCell(
          selectedGridIdx,
          "contactTel",
          memberInfo.hpNo
        );
      }
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "nativeDiv",
        memberInfo.nativeDiv || "DMSTC"
      );

      if (selectedGridIdx < 0) {
        throw new Error("Invalid selectedGridIdx");
      }

      // 회원권 특전 요금코드 조회
      try {
        await this.getPaymentCodeAndUpdateGreenFee(selectedGridIdx);
      } catch (e) {
        throw new Error(e);
      }

      //지정락카 번호가 넘어온 경우
      if(memberInfo.lockerNo){
        this.$refs.visitsGrid.updateCell(
            selectedGridIdx,
            "lockerNo",
            memberInfo.lockerNo
        );
        await this.locketStatusSettingProcess(selectedGridIdx, memberInfo.lockerNo, batchCurrentViewRecords[selectedGridIdx].visitId, batchCurrentViewRecords[selectedGridIdx].lockerNo);
      }
      // 회원 선택시 회원정보의 성별에 따라 락카 자동배치.
      // 락카번호가 없거나 락카번호가 있으면서 기존 락카번호가 "000" 또는 "999"인 경우 락카 자동배치.
      else if (
          !batchCurrentViewRecords[selectedGridIdx]?.lockerNo ||
          (batchCurrentViewRecords[selectedGridIdx]?.lockerNo && ["000","999"].includes(batchCurrentViewRecords[selectedGridIdx]?.lockerNo))
      ) {
        await this.locketStatusSettingProcess(selectedGridIdx, memberInfo.sexCode === "F" ? "-" : "+", batchCurrentViewRecords[selectedGridIdx].visitId, batchCurrentViewRecords[selectedGridIdx].lockerNo);
      }


    },
    /**
     * 요금코드 금액 조회 후 재 계산 - 회원 선택, 내장객 추가, 회원등급 수정 시
     */
    async getPaymentCodeAndUpdateGreenFee(selectedGridIdx, memberGrade = null) {
      const visitsGrid = this.$refs.visitsGrid;
      let editingCellFieldAfterGetMemberBenefitPaymtCode;
      let currentRowIndexAfterGetMemberBenefitPaymtCode;

      let currentRowData = this.getVisitsGridCurrentRowData(selectedGridIdx);
      let rowDcList = currentRowData.dcList ? currentRowData.dcList : [];
      let rowGreenFeeInfo = currentRowData.greenFeeInfo
        ? currentRowData.greenFeeInfo
        : {};

      // 요금구분(코드) 조회
      let newPaymtCode = null;
      try {
        const newPaymtCodeValue = await GolfErpAPI.getMemberBenefitPaymtCode({
          bsnDate: this.teamInfo.visitDate,
          memberDiv: currentRowData.memberDiv,
          memberGrade: memberGrade ? memberGrade : currentRowData.memberGrade,
          membershipId: currentRowData.membershipId ? currentRowData.membershipId : "%",
          isBackground: true,
          grpNo: this.teamInfo.grpNo,
          selfFlag: this.teamInfo.selfFlag,
        });

        if (newPaymtCodeValue.status === "FAIL") {
          throw new Error(newPaymtCodeValue.data);
        }
        newPaymtCode = newPaymtCodeValue.data;
      } catch (e) {
        console.log("getMemberBenefitPaymtCode.err.===>", e);
        throw new Error(e);
      }

      if (newPaymtCode && currentRowData.membershipId) {
        if (commonCodesGetCommonCodeAttrbByCodeAndIdx("PAYMT_CODE", newPaymtCode, 2) === "AREA") {
          const membershipIds = [currentRowData.membershipId];
          const paymtCountData = await GolfErpAPI.fetchPaymtCodeCountByMembershipIds({
            visitDate: this.teamInfo.visitDate,
            membershipIds: membershipIds
          });

          if (paymtCountData.totalCount > 0) {
            this.infoToast(
              paymtCountData.contents + "<br>" +
              "이미 지역민 할인 적용 되셨습니다."
            );
          }
        }
      }

      if (newPaymtCode !== "NOT") {
        // 요금 조회
        const { value: paymentPriceResponse } = await getPaymentPrice(
          this.teamInfo.visitDate,
          this.teamInfo.startTime,
          currentRowData.holeDiv,
          memberGrade ? memberGrade : currentRowData.memberGrade,
          newPaymtCode,
          this.teamInfo.promtnId,
          true
        );

        // 회원등급 수정 후 요금구분 포커싱 시 포커싱 유지되도록 수정
        editingCellFieldAfterGetMemberBenefitPaymtCode =
          visitsGrid.editingCellField;
        currentRowIndexAfterGetMemberBenefitPaymtCode =
          visitsGrid.currentSelectedRowIndex;
        // console.log(`editingCellField:${editingCellFieldAfterGetMemberBenefitPaymtCode}, currentRowIndex:${currentRowIndexAfterGetMemberBenefitPaymtCode}`);

        if (
          editingCellFieldAfterGetMemberBenefitPaymtCode === "paymtCode" &&
          currentRowIndexAfterGetMemberBenefitPaymtCode === selectedGridIdx
        ) {
          visitsGrid.saveCell();
        }

        // 내장객 요금 구분 update
        this.$refs.visitsGrid.updateCell(
          selectedGridIdx,
          "paymtCode",
          paymentPriceResponse.applyPaymtCode
        );

        if (paymentPriceResponse.dcContents) {
          this.$refs.visitsGrid.updateCell(
            selectedGridIdx,
            "dcContents",
            paymentPriceResponse.dcContents
          );
        } else {
          this.$refs.visitsGrid.updateCell(
            selectedGridIdx,
            "dcContents",
            null
          );
        }

        if (paymentPriceResponse.promtnId && !currentRowData.promtnDelFlag) {
          // 요금 조회 API Response에 프로모션 할인 정보가 있으면
          // 프로모션 할인 추가
          switch (paymentPriceResponse.promtnDcMethod) {
            // 할인금액 / 할인율
            case "AMT":
            case "RATE":
              // 기존에 프로모션 할인 정보가 있으면 삭제 후 맨 위에 추가
              this.deletePromotionDiscount(rowDcList);
              rowDcList.unshift({
                _rid: 0, // v-for 로 사용할 key 값임
                dcApplyDiv: "PROMTN",
                dcUseNo: paymentPriceResponse.promtnId,
                dcMethod: paymentPriceResponse.promtnDcMethod,
                dcAmt: paymentPriceResponse.promtnDcAmt,
                applyAmt: 0,
                paymtCode: null,
                dcContents: paymentPriceResponse.promtnName,
                dcUseDate: this.bsnDate.split("-").join(""),
                dcSno: 0,
              });
              break;
            case "PAYMT":
              // 기존 할인 내역 중, 프로모션 할인이 아닌 요금코드 할인이 있으면 에러 메시지 출력 후 추가하지 않음. 없으면 추가
              // eslint-disable-next-line no-case-declarations
              const currentPaymtDcInfo = rowDcList.find(
                (dcInfo) =>
                  dcInfo.dcApplyDiv !== "PROMTN" && dcInfo.dcMethod === "PAYMT"
              );
              if (currentPaymtDcInfo) {
                this.errorToast(
                  "요금코드 할인 내역이 있어서 프로모션 할인 정보를 추가할 수 없습니다"
                );
              } else {
                // 없으면, 기존에 프로모션 할인 정보가 있으면 삭제 후 맨 위에 추가
                this.deletePromotionDiscount(rowDcList);
                rowDcList.unshift({
                  _rid: 0, // v-for 로 사용할 key 값임
                  dcApplyDiv: "PROMTN",
                  dcUseNo: paymentPriceResponse.promtnId,
                  dcMethod: paymentPriceResponse.promtnDcMethod,
                  dcAmt: 0,
                  applyAmt: paymentPriceResponse.promtnPrice,
                  paymtCode: paymentPriceResponse.applyPaymtCode,
                  dcContents: paymentPriceResponse.promtnName,
                  dcUseDate: this.bsnDate.split("-").join(""),
                  dcSno: 0,
                });
              }
              break;
          }
        } else {
          // 프로모션이랑 상관 없는 경우, 프로모션 할인 정보 제거
          this.deletePromotionDiscount(rowDcList);
        }

        // 요금 재 계산 후 설정
        let newGreenFeeInfo = rowGreenFeeInfo;
        if (paymentPriceResponse.promtnId) {
          if (paymentPriceResponse.promtnDcMethod === "PAYMT") {
            newGreenFeeInfo.saleAmt = paymentPriceResponse.applyPrice;
          } else {
            newGreenFeeInfo.saleAmt = paymentPriceResponse.noPrice; // 프로모션 할인이 적용된 경우. 프로모션 할인은 할인 내역에 추가하여 적용하므로, 총 금액은 아무 할인도 적용되지 않은 noPrice를 사용
          }
        } else {
          newGreenFeeInfo.saleAmt = paymentPriceResponse.applyPrice; // 이벤트 할인이 적용된 경우. 이벤트 할인은 할인 내역으로 관리하지 않으므로, 이벤트 할인이 적용된 applyPrice를 사용
        }
        this.updateGridGreenFee(selectedGridIdx, newGreenFeeInfo, rowDcList);
      } else {
        // 미적용인 경우
        // 모든 할인 정보 제거
        rowDcList = [];

        // 요금설정 0원
        let newGreenFeeInfo = rowGreenFeeInfo;
        newGreenFeeInfo.saleAmt = 0;
        this.updateGridGreenFee(selectedGridIdx, newGreenFeeInfo, rowDcList);

        // 카트대여료 0원
        this.$refs.visitsGrid.updateCell(selectedGridIdx, "cartAmt", 0);
      }

      // 회원등급 수정 후 요금구분 포커싱 시 포커싱 유지되도록 수정
      if (
        editingCellFieldAfterGetMemberBenefitPaymtCode === "paymtCode" &&
        currentRowIndexAfterGetMemberBenefitPaymtCode === selectedGridIdx
      ) {
        visitsGrid.editCell(selectedGridIdx, "paymtCode");
      }
    },
    /**
     * 요금코드 금액 조회 후 재 계산 - 요금구분, 홀구분 수정 시
     */
    async getFixedPaymentCodeAndUpdateGreenFee(
      selectedGridIdx,
      paymtCode = null,
      holeDiv = null
    ) {
      let currentRowData = this.getVisitsGridCurrentRowData(selectedGridIdx);
      let rowDcList = currentRowData.dcList ? JSON.parse(JSON.stringify(currentRowData.dcList)) : [];
      let rowGreenFeeInfo = currentRowData.greenFeeInfo
        ? currentRowData.greenFeeInfo
        : {};
      let paymtCodeParam = paymtCode ? paymtCode : currentRowData.paymtCode;
      let holeDivParam = holeDiv ? holeDiv : currentRowData.holeDiv;

      if (paymtCode && currentRowData.membershipId) {
        if (commonCodesGetCommonCodeAttrbByCodeAndIdx("PAYMT_CODE", paymtCode, 2) === "AREA") {
          const membershipIds = [currentRowData.membershipId];
          const paymtCountData = await GolfErpAPI.fetchPaymtCodeCountByMembershipIds({
            visitDate: this.teamInfo.visitDate,
            membershipIds: membershipIds
          });

          if (paymtCountData.totalCount > 0) {
            this.infoToast(
              paymtCountData.contents + "<br>" +
              "이미 지역민 할인 적용 되셨습니다."
            );
          }
        }
      }

      // 요금구분이 미적용이 아닌 경우, 요금 조회 API 호출
      let newPrice = 0;
      if (paymtCodeParam !== "NOT") {
        // 요금 조회
        const {
          value: {
            price: fixedPrice,
            dcContents: dcContents
          }
        } = await getFixedPaymentPrice(
          this.teamInfo.visitDate,
          holeDivParam,
          paymtCodeParam,
          this.teamInfo.rainFlag,
        );
        newPrice = fixedPrice;

        // 할인 내역 중 요금코드 할인이 있는 경우, 수정한 요금구분 값 != 할인 요금코드값 이면 할인내역 삭제
        const promotionPaymtDcInfo = rowDcList.find(
          (dcInfo) => dcInfo.dcMethod === "PAYMT"
        );
        if (
          promotionPaymtDcInfo &&
          promotionPaymtDcInfo.paymtCode !== paymtCodeParam
        ) {
          const promotionPaymtDcInfoIdx = rowDcList.findIndex(
            (dcInfo) => dcInfo.dcMethod === "PAYMT"
          );
          if (promotionPaymtDcInfoIdx !== -1) {
            this.errorToast("요금구분 수정으로 요금코드 할인이 삭제됩니다");
            rowDcList.splice(promotionPaymtDcInfoIdx, 1);
          }
        // } else if (
        //   promotionPaymtDcInfo &&
        //   this.originalTeamInfo.teamHoleDiv !== this.teamInfo.teamHoleDiv
        // ) {
        //   const promotionPaymtDcInfoIdx = rowDcList.find(
        //     (dcInfo) => dcInfo.dcMethod === "PAYMT"
        //   );
        //   if (promotionPaymtDcInfoIdx !== -1) {
        //     this.errorToast("팀 홀구분 수정으로 요금코드 할인이 삭제됩니다");
        //     rowDcList.splice(promotionPaymtDcInfoIdx, 1);
        //   }
        }

        // 요금 설정
        let newGreenFeeInfo = rowGreenFeeInfo;
        newGreenFeeInfo.saleAmt = newPrice;
        this.updateGridGreenFee(selectedGridIdx, newGreenFeeInfo, rowDcList);

        if (dcContents) {
          this.$refs.visitsGrid.updateCell(selectedGridIdx, "dcContents", dcContents);
        } else {
          this.$refs.visitsGrid.updateCell(selectedGridIdx, "dcContents", null);
        }
      } else {
        // 미적용
        // 모든 할인 정보 제거
        rowDcList = [];

        // 요금설정 0원
        let newGreenFeeInfo = rowGreenFeeInfo;
        newGreenFeeInfo.saleAmt = 0;
        this.updateGridGreenFee(selectedGridIdx, newGreenFeeInfo, rowDcList);

        // 카트대여료 0원
        this.$refs.visitsGrid.updateCell(selectedGridIdx, "cartAmt", 0);
      }
    },
    deletePromotionDiscount(dcList) {
      const promotionDcListIndex = dcList.findIndex(
        (dcInfo) => dcInfo.dcApplyDiv === "PROMTN"
      );
      if (promotionDcListIndex !== -1) {
        dcList.splice(promotionDcListIndex, 1);
      }
    },
    /**
     * 검색된 내장객이 없거나, 입력 - focus out으로 내장객 팝업을 띄운 후 선택하지 않고 닫은 경우 - 내장객 비회원 처리
     * @param selectedGridIdx
     */
    async setVisitNonMemberInfo(selectedGridIdx, memberInfo = null) {
      if (selectedGridIdx < 0) {
        throw new Error("invalid selectedGridIdx in setVisitNonMemberInfo");
      }

      const batchCurrentViewRecords = JSON.parse(JSON.stringify(this.$refs.visitsGrid.getBatchCurrentViewRecords()));

      // 비회원 : 성별 = 남, 회원권ID, 회원번호, 연락처 NULL, 회원구분 = '비회원', 회원등급 = '일반', 요금구분 = '비회원', 내국인여부 = '내국인'
      const memberDivNonMember = "NOM";
      const memberGradeNonMember = "NOR";

      if (memberInfo) {
        // 회원정보 팝업에서 비회원을 선택한 경우에는 내장객 이름 설정
        this.$refs.visitsGrid.updateCell(
          selectedGridIdx,
          "visitName",
          memberInfo.memberName
        );

        //지정락카 번호가 넘어온 경우
        if(memberInfo.lockerNo){
          this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "lockerNo",
              memberInfo.lockerNo
          );
          await this.locketStatusSettingProcess(selectedGridIdx, memberInfo.lockerNo, batchCurrentViewRecords[selectedGridIdx].visitId, batchCurrentViewRecords[selectedGridIdx].lockerNo);
        }
      }

      this.$refs.visitsGrid.updateCell(selectedGridIdx, "membershipId", null);
      this.$refs.visitsGrid.updateCell(selectedGridIdx, "memberNo", null);
      this.$refs.visitsGrid.updateCell(selectedGridIdx, "couponCount", 0);
      if (this.$refs.visitsGrid.getRowByIndex(selectedGridIdx).getElementsByClassName("devHasCouponDiscount").length > 0) {
        this.$refs.visitsGrid.getRowByIndex(selectedGridIdx).getElementsByClassName("devHasCouponDiscount")[0].style.backgroundColor = "";
      }

      // 암호화데이터 열람 권한이 있어야 설정 가능
      if (this.commonMixinHasCiperTextGet) {
        this.$refs.visitsGrid.updateCell(selectedGridIdx, "contactTel", ""); // null로 설정하면 update 되지 않음
      }
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "memberDiv",
        memberDivNonMember
      );
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "memberGrade",
        memberGradeNonMember
      );
      this.$refs.visitsGrid.updateCell(selectedGridIdx, "paymtCode", "NOM");
      this.$refs.visitsGrid.updateCell(selectedGridIdx, "nativeDiv", "DMSTC");

      // 회원권 특전 요금코드 조회 및 요금 조회
      try {
        await this.getPaymentCodeAndUpdateGreenFee(selectedGridIdx);
      } catch (e) {
        console.error(e);
        this.errorToast("요금 조회 중 오류가 발생하였습니다");
      }
    },
    visitsGridClicked(args) {
      if (!args.column || !this.isTeamInfoEnable) {
        return;
      }

      switch (args.column.field) {
        case "visitNameIcon": // 검색 아이콘 클릭
          // eslint-disable-next-line no-case-declarations
          let currentVisitInfo = this.$refs.visitsGrid.getBatchCurrentViewRecords()[
            args.rowIndex
          ];
          if (currentVisitInfo) {
            // 팝업 띄우기 전 save 처리
            this.$refs.visitsGrid.saveCell();

            this.onMemberPopupOpen(
              true,
              this.memberPopupType.VISIT_MEMBER,
              currentVisitInfo.visitName,
              args.rowIndex,
              {
                // 팝업 띄운 후 "내장객" 포커싱
                editingCellField: "visitName",
                currentRowIndex: args.rowIndex,
              }
            );
          }
          break;
        case "discountIcon":
          // 할인 아이콘 클릭
          // 할인 팝업 연동
          // eslint-disable-next-line no-case-declarations
          let currentRowData = this.getVisitsGridCurrentRowData(args.rowIndex);
          if (currentRowData.paymtCode === "NOT") {
            this.errorToast(
              "요금구분이 미적용인 경우에는 할인 설정하실 수 없습니다"
            );
            return;
          }

          if (
            JSON.stringify(this.teamInfo) !==
            JSON.stringify(this.originalTeamInfo)
          ) {
            this.errorToast(
              "수정된 팀정보 내용이 존재합니다.<br>저장후 할인 적용하실 수 있습니다."
            );
            return;
          }

          this.isDiscountPopupOpen = true;
          // eslint-disable-next-line no-case-declarations
          let visitInfo = {
            startCourse: currentRowData.startCourse, // 출발코스
            startTime: currentRowData.startTime, // 출발시간
            holeDiv: currentRowData.holeDiv, // 홀구분
            visitName: currentRowData.visitName, // 내장객명
            lockerNo: currentRowData.lockerNo, // 락카번호
            memberGrade: currentRowData.memberGrade, // 회원등급
            paymtCode: currentRowData.paymtCode, // 요금구분
            membershipId: currentRowData.membershipId, // 회원권ID
            memberNo: currentRowData.memberNo, // 회원번호
            bsnDate: this.teamInfo.visitDate,
          };
          this.$nextTick(() => {
            this.$refs.discountPopup.showDiscountPopup(
              visitInfo,
              currentRowData.greenFeeInfo,
              currentRowData.dcList,
              args.rowIndex,
              this.teamInfo.teamChkoutFlag
            );
          });
          break;
      }
    },
    onVisitsGridActionComplete(args) {
      if (args.requestType === "refresh") {
        // 그리드 로딩 완료 시점. API에서 visitInfoList가 항상 한 건 이상 조회되므로, 이와 같이 판단함
        if (args.rows) {
          this.checkVisitsInfoList();
        }
        //내장객 그리드 초기 4행 설정
        if (this.teamInfo && this.teamInfo.chkinId === undefined) {
          this.addVisitRowToFour();
        } else {
          // 선택했던 row의 내장객 셀 focus.
          // 타이머로 처리하지 않으면 이후 "추가" 시 / Refresh 시에 오동작
          if (!this.isPopupOpened) {
            setTimeout(() => {
              this.focusSelectedRow(this.visitsGridSelectedIdx);
            }, 300);
          }
        }

        // 내장객 그리드 집계 정보 계산
        this.computeVisitGridAggregation();

        // preLockerNo 초기화.
        this.preLockerNo = null;
      }
    },
    focusSelectedRow(rowIndex, field = null) {
      const currentGridRowLength = this.$refs.visitsGrid.getDataRows().length;

      // 그리드에 row가 없을 때는 포커싱하지 않음
      if (currentGridRowLength <= 0) {
        return;
      }

      // rowIndex가 0보다 작거나, rowIndex가 현재 length보다 큰 경우에는 rowIndex를 0으로 설정
      if (rowIndex < 0 || currentGridRowLength < rowIndex + 1) {
        rowIndex = 0;
      }

      console.log("### focus ###");

      this.$refs.visitsGrid.editCell(rowIndex, field || "visitName");
    },
    /**
     * 내장객 그리드 집계 정보 계산
     * 호출시점
     *  - grid refresh
     *  - grid batchAdd
     *  - grid batchDelete
     *  - grid batchCancel
     *  - 'greenFeeInfo' cell update 때마다
     */
    computeVisitGridAggregation: function () {
      // 총 명수
      const visitGridDataList = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      this.visitsLength = visitGridDataList.length;

      // 총 금액
      this.visitsTotalPay = visitGridDataList.reduce((totTotAmt, visit) => {
        if (visit.greenFeeInfo && visit.greenFeeInfo.totAmt !== undefined) {
          return totTotAmt + visit.greenFeeInfo.totAmt;
        } else {
          return totTotAmt;
        }
      }, 0);
    },
    async onVisitsGridBatchAdd(args) {
      // 체크인 레이어 썸네일 모드 및 체크인 상태가 예약일 때 요금 조회 안함
      if (
        this.isCheckinLayerThumbnail &&
        this.timeData.chkinStatus === "RESVE"
      ) {
        return;
      }

      // record가 추가되었을 때 요금구분 및 요금 조회
      const visitsGrid = this.$refs.visitsGrid;
      const addedRowIndex = visitsGrid
        .getDataRows()
        .findIndex((dataRow) => dataRow === args.row);

      // 요금 조회
      try {
        await this.getPaymentCodeAndUpdateGreenFee(addedRowIndex);
      } catch (e) {
        console.error(e);
        this.errorToast("요금 조회 중 오류가 발생하였습니다");
      }

      // 체크인되지 않은 신규 팀일 경우 add된 레코드를 originalVisitInfoList에 넣어준다.
      if (
        this.teamInfo.chkinId === null ||
        this.teamInfo.chkinId === undefined
      ) {
        const batchChangesData = this.$refs.visitsGrid.getBatchChanges();
        const filterData = batchChangesData.addedRecords.filter(
          (data) => data.visitCopyFlag
        );

        // 내장객 복사로 add된 데이터는 originalVisitInfoList에 넣지 않는다.
        if (!(filterData.length > 0)) {
          this.originalVisitInfoList = JSON.parse(
            JSON.stringify(
              this.getAddedRecordsWithVisitName(
                batchChangesData.addedRecords.map(data => ({
                  ...data,
                  lockerNo: this.resveCmpnFlag ? "" : data.lockerNo
                }))
              )
            )
          );
          if (
            this.resveCmpnFlag &&
            args.defaultData.visitName &&
            this.checkinLayerStatus === CHECKIN_LAYER_CONTENT
          ) {
            this.$refs.visitsGrid.updateCell(addedRowIndex, "lockerNo", "000");
          }
        }
      }

      // 락커 설정
      if (!this.isAlreadyAssignedLockNoFlag) {
        if (args.defaultData.lockerNo.trim() !== "") {
          await this.locketStatusSettingProcess(
            addedRowIndex,
            args.defaultData.lockerNo.trim()
          );
        }
      }

      // focus
      if (this.rowIndexAfterTakeCmpnMember === null) {
        if (args.defaultData.focusCurrentIdx !== undefined) {
          // 체크인과 같이 여러 건의 row를 추가하는 경우, 제일 첫 번째 row의 내장객 란에 focus
          if (args.defaultData.focusIdx === args.defaultData.focusCurrentIdx) {
            this.focusSelectedRow(0);
          }
        } else {
          this.focusSelectedRow(addedRowIndex);
        }
      } else {
        this.addedCmpnMemberCount++;
        const gridInstace = this.$refs.visitsGrid;
        const batchCurrentViewRecords = gridInstace.getBatchCurrentViewRecords();
        const currentViewRecords = gridInstace.getCurrentViewRecords();
        const len = batchCurrentViewRecords.length - currentViewRecords.length;
        if (this.addedCmpnMemberCount === len) {
          this.focusSelectedRow(this.rowIndexAfterTakeCmpnMember, "lockerNo");
          this.addedCmpnMemberCount = 0;
          this.rowIndexAfterTakeCmpnMember = null;
        }
      }
    },
    onVisitsGridKeyPressed() {
      // 아래와 같이 하면 기본 동작을 취소할 수 있음
      /*
      if (args.code === 'Escape') {
        args.cancel = true
      }
     */
      // 임시로 Enter Key 막음. TODO : 버그(https://www.syncfusion.com/support/directtrac/incidents/273572) 수정되면 이 코드 삭제
      /*if (args.code === "Enter" || args.code === "NumpadEnter") {
        args.cancel = true;
        this.$refs.visitsGrid.saveCell();
        this.$refs.visitsGrid.selectRow(this.$refs.visitsGrid.selectedRowIndex);
      }*/
    },
    async takeCompanionMemberClicked() {
      const gridInstace = this.$refs.visitsGrid;
      const batchCurrentViewRecords = gridInstace.getBatchCurrentViewRecords();
      this.isTakeCompanionMemberPopupOpen = true;
      await this.$nextTick();
      await this.$refs.takeCompanionMemberPopup.showPopup(
        this.resveInfo,
        this.teamInfo,
        batchCurrentViewRecords,
      );
    },
    async membershipSeizureCheck(memberInfo) {
      // 회원만 체크
      if (!memberInfo.membershipId) {
        return true;
      }

      const membershipSeizures = (await GolfErpAPI.fetchMembershipSeizure(memberInfo.membershipId)).filter(item => item.seizrStatus === "SEIZR");

      if (
        !membershipSeizures ||
        !(membershipSeizures.length > 0)
      ) {
        return true;
      }

      if (membershipSeizures.length > 0) {
        const seizure = orderBy(membershipSeizures, "confDate", ["desc"])[0];
        return await this.confirm(
          `회원번호 ${memberInfo.memberNo}는 이용정지중인 회원권입니다.<br>사유:${seizure.remarks || ""}<br>계속 진행하시겠습니까?`
        );
      }

      return true;
    },
    /**
     * 내장객 및 팀 정보 저장
     */
    onSaveVisitInfoClicked: async function () {
      if (!this.validateFormRefs(this.teamInfoValidateRefList)) {
        return;
      }

      if (!this.$refs.visitsGrid.validate()) {
        return;
      }

      const batchCurrentViewRecordLength = this.$refs.visitsGrid.getBatchCurrentViewRecords()?.length || 0;
      if (this.teamInfo.visitCnt < batchCurrentViewRecordLength) {
        this.$refs.visitCnt.focus();
        this.errorToast("내장인원수를 등록된 내장객 수 미만으로 수정하실 수 없습니다");
        return;
      }

      // 변경 여부 체크
      if (!this.isTeamInfoAndvisitInfoListIsChanged()) {
        this.errorToast("변경한 내용이 없습니다");
        return;
      }

      // 내장 순번 체크
      const visits = this.$refs.visitsGrid.getBatchCurrentViewRecords().filter(item => item.visitName);
      const visitSnos = visits.map(visit => visit.visitSno);
      const visitSnosGroupBy = groupBy(visitSnos);
      const visitSnosMaxBy = maxBy(visitSnos);

      if (Object.values(visitSnosGroupBy).some(value => value.length > 1)) {
        this.errorToast("내장순번에 중복된 숫자가 있습니다");
        return;
      }

      if (visitSnosMaxBy > visits.length) {
        this.errorToast("내장순번을 순서대로 입력해주시기 바랍니다");
        return;
      }

      // 입장료 체크
      const belowMinGreenFeeVisit = this.$refs.visitsGrid
        .getBatchCurrentViewRecords()
        .find((record) => {
          if (parseFloat(record.greenFeeDc.replace(/,/g, "")) < minGreenFee) {
            return true;
          }
          return false;
        });
      if (belowMinGreenFeeVisit) {
        this.errorToast(`입장료는 ${minGreenFee}원 이상이어야 합니다`);
        return;
      }

      // 내장객 내 max 홀 구분 값을 팀 홀 구분 값으로 설정
      this.teamInfo.teamHoleDiv = this.getMaxHoleDiv();

      // 확정 체크한 내장객이 4명 이상인지 여부 확인
      const batchCurrentViewRecords = this.getAddedRecordsWithVisitName(
        this.$refs.visitsGrid.getBatchCurrentViewRecords()
      );
      const visitConfirmFlagTrueLength = batchCurrentViewRecords.filter(
        (record) => record.visitConfirmFlag === true
      ).length;

      if (batchCurrentViewRecords.length >= this.teamInfo.visitCnt) {
        // 저장할 내장객이 4명 이상 : 확정 체크한 내장객이 4명 이상이면 부가정보 - 내장확정에 체크한 후 저장.
        if (visitConfirmFlagTrueLength >= this.teamInfo.visitCnt) {
          this.teamInfo.visitConfirmFlag = true;
        } else {
          this.teamInfo.visitConfirmFlag = false;
        }
      }

      let saveData = {};

      if (this.teamInfo.chkinId) {
        saveData.teamInfo = {
          chkinId: this.teamInfo.chkinId,
          visitDate: this.teamInfo.visitDate, // 일마감 여부 체크를 위해 visitDate 필요.
          ...deepDiffs(this.originalTeamInfo, this.teamInfo),
        };

        if (
          saveData.teamInfo.benMembershipName !== undefined &&
          !saveData.teamInfo.benMembershipName
        ) {
          saveData.teamInfo.benMembershipId = "";
        }

        if (
          saveData.teamInfo.grpName !== undefined &&
          !saveData.teamInfo.grpName
        ) {
          saveData.teamInfo.grpName = "";
          saveData.teamInfo.grpNo = "";
        }

        if (
            saveData.teamInfo.visitCnt !== undefined &&
            !saveData.teamInfo.visitCnt
        ) {
          saveData.teamInfo.visitCnt = 4;
        }

        if (
          saveData.teamInfo.promtnName !== undefined &&
          !saveData.teamInfo.promtnName
        ) {
          saveData.teamInfo.promtnId = 0;
        }
      } else {
        saveData.teamInfo = this.teamInfo;

        if (!saveData.teamInfo.promtnName) {
          saveData.teamInfo.promtnId = 0;
        }
        if (!saveData.teamInfo.grpName) {
          saveData.teamInfo.grpNo = "";
        }
        if (!saveData.teamInfo.benMembershipName) {
          saveData.teamInfo.benMembershipId = "";
        }
      }

      const gridRef = this.$refs.visitsGrid;
      const batchChangesData = gridRef.patchBatchChanges();
      const currentViewRecords = gridRef.getCurrentViewRecords();

      saveData.addedRecords = this.getAddedRecordsWithVisitName(
        batchChangesData.addedRecords
      );

      // 신규 내장객 등록시 회원권 압류 체크
      if (saveData.addedRecords.length > 0) {
        for (const record of saveData.addedRecords) {
          if (!(await this.membershipSeizureCheck(record))) {
            return;
          }
        }
      }

      saveData.changedRecords = batchChangesData.changedRecords
        .filter(
          ({ _rid }) =>
            !!currentViewRecords.find(
              (currentViewRecord) => _rid === currentViewRecord._rid
            )
        )
        .map((changedRecord) => {
          const target = currentViewRecords.find(
            (currentViewRecord) => changedRecord._rid === currentViewRecord._rid
          );
          return {
            ...changedRecord,
            visitId: target.visitId,
            optFlag: target.optFlag,
            visitSno: changedRecord.visitSno ? changedRecord.visitSno : target.visitSno,
          };
        });

      // 내장 정보 등록 저장 API
      await GolfErpAPI.patchTeamOrVisitors(
        saveData.teamInfo,
        saveData.addedRecords,
        saveData.changedRecords,
        this.resveInfo.resveId,
        getDeviceInfo().posNo
      )
        .then(async (response) => {
          this.SET_FOOTER_MESSAGE(this.$t("main.popupMessage.saved"));
          this.SET_FOOTER_MESSAGE_ICON("alert");

          // 내장객 이름이 없는데 락카는 배정되어 있는 경우 - empty 설정
          const currentGridVisitInfoList = this.$refs.visitsGrid.getBatchCurrentViewRecords();
          for (
            let visitIdx = 0;
            visitIdx < currentGridVisitInfoList.length;
            visitIdx++
          ) {
            const currentVisitName =
              currentGridVisitInfoList[visitIdx].visitName;
            if (
              currentVisitName === null ||
              currentVisitName === undefined ||
              currentVisitName.trim() === ""
            ) {
              const currentLockerNo =
                currentGridVisitInfoList[visitIdx].lockerNo;
              if (!isReservedLockerNo(currentLockerNo)) {
                let data = {
                  bsnDate: this.teamInfo.visitDate, // 영업일자
                  lockerNo: currentLockerNo, // 락카번호
                  isVisitorUse: true,
                };
                lockerStatusSetting("EMPTY", data, true).catch((e) => {
                  this.errorToast(
                    `${currentLockerNo} 번 락카 반납이 실패하였습니다`
                  ); // 실패 시 toast 남김
                  console.log("lockerStatusSetting.err. in save ===>", e);
                });
              }
            }
          }

          // 저장후 받아오는 chkinId와 resveId 셋팅.
          if (response.chkinId) {
            this.timeData.chkinId = response.chkinId;
          } else {
            this.timeData.chkinId = null;
          }
          if (response.resveId) {
            this.timeData.resveId = response.resveId;
          } else {
            this.timeData.resveId = null;
          }

          if (!!saveData.teamInfo && !!saveData.teamInfo.grpNo) {
            if (
              this.timeData.grpNo === null ||
              this.timeData.grpNo === undefined
            ) {
              this.timeData.grpNo = JSON.parse(
                JSON.stringify(saveData.teamInfo.grpNo || this.teamInfo.grpNo)
              );
              this.timeData.grpKind = JSON.parse(
                JSON.stringify(saveData.teamInfo.grpKind || this.teamInfo.grpKind)
              );
              this.timeData.grpName = JSON.parse(
                JSON.stringify(saveData.teamInfo.grpName || this.teamInfo.grpName)
              );

              if (this.divideDiv === "T") {
                this.divideDiv = "G";
              }
            } else {
              if (this.timeData.grpNo !== saveData.teamInfo.grpNo) {
                this.timeData.grpNo = JSON.parse(
                  JSON.stringify(saveData.teamInfo.grpNo || this.teamInfo.grpNo)
                );
                this.timeData.grpKind = JSON.parse(
                  JSON.stringify(saveData.teamInfo.grpKind || this.teamInfo.grpKind)
                );
                this.timeData.grpName = JSON.parse(
                  JSON.stringify(saveData.teamInfo.grpName || this.teamInfo.grpName)
                );
              }
            }
          } else {
            this.timeData.grpNo = null;
            this.timeData.grpKind = null;
            this.timeData.grpName = null;

            if (this.divideDiv === "G") {
              this.divideDiv = "T";
            }
          }

          if (!this.timeData.chkinId && !this.timeData.resveId) {
            // 저장후 chkinId와 resveId가 둘다 없는 경우 Layer 닫고 내장객 현황 타임목록 Refresh.
            this.$emit("dataRefresh");
            this.closeCheckin();
          } else {
            // 상세 조회
            await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
            this.$emit("dataRefresh");
          }
        })
        .catch((error) => {
          console.log("saveCheckin.err.===>", error);
          if (error.message === "NOT_EXPECTED_ADMISSION_FEES") {
            this.errorPopup(
              "요금 단가 정보가 변경되었습니다. 요금을 재 조회합니다"
            );

            const currentVisitInfo = this.$refs.visitsGrid.getBatchCurrentViewRecords();
            currentVisitInfo.forEach(async (visitInfo, idx) => {
              try {
                await this.getFixedPaymentCodeAndUpdateGreenFee(idx);
              } catch (e) {
                console.error(e);
                this.errorToast("요금 조회 중 오류가 발생하였습니다");
              }
            });
          }
        });
    },
    /**
     * getBatchChanges().addedRecords 중 visitName(내장객명)이 있는 데이터의 array만 return
     */
    getAddedRecordsWithVisitName: function (batchChangesAddedRecords) {
      let addedRecordsWithVisitName = [];
      batchChangesAddedRecords.forEach((batchChangesAddedRecord) => {
        if (
          !(batchChangesAddedRecord.visitName === null) &&
          !(batchChangesAddedRecord.visitName === undefined) &&
          !(batchChangesAddedRecord.visitName.trim() === "")
        ) {
          addedRecordsWithVisitName.push(batchChangesAddedRecord);
        }
      });
      return addedRecordsWithVisitName;
    },
    /**
     * 내장객 등록 추가
     */
    onAddVisitInfoClicked: function () {
      const currentGridDataRows = this.$refs.visitsGrid.getDataRows();
      const currentGridLength = currentGridDataRows.length;

      if (currentGridLength >= this.teamInfo.visitCnt) {
        this.errorToast("내장인원수보다 많은 내장객을 추가하실 수 없습니다");
      } else {
        this.addVisitsInfoRecord(currentGridLength + 1);

        // 카트료 재 계산
        // this.getCartPriceAmt()

        // this.$refs.visitsGrid.clearSelection();
      }
    },
    async onDeleteVisitInfoClicked() {
      const deletedRows = this.$refs.visitsGrid.getSelectedRecords();
      if (!deletedRows || deletedRows.length === 0) {
        this.errorToast("삭제할 내장객을 선택해주세요.");
        return;
      }

      const deletedIdx = this.visitsGridSelectedIdx; // Single row select 그리드이기 때문에, 현재 선택된 row 만 지울 수 있음
      if (deletedIdx >= 0) {
        if (this.teamInfo?.chkinId) {
          this.onVisitDeleteConfirmPopupOpen(deletedRows, deletedIdx);
        } else {
          if (!(await this.confirm("내장객을 삭제하시겠습니까?"))) {
            return;
          }

          this.$refs.visitsGrid.deleteRecord();
        }

        // 카트료 재 계산
        // this.getCartPriceAmt()
      } else {
        this.errorToast("내장객을 선택해 주십시오");
      }
    },
    onVisitsGridBeforeBatchDelete: function () {
      this.$refs.visitsGrid.getSelectedRows().forEach((selectedRow) => {
        const currentVisitInfo = this.$refs.visitsGrid.getBatchCurrentViewRecords(); // reArrangeGridVisitSno 에서 그리드 값을 갱신해 주기 때문에, forEach 안에서 조회해야만 한다
        const currentSelectedRowIdx = this.$refs.visitsGrid
          .getDataRows()
          .findIndex((dataRow) => dataRow === selectedRow);

        // 락카 상태 저장 API 호출 - EMPTY 처리
        // this.lockerStatusSettingPreviousLockerNumber(selectedRow.cells[VISIT_GRID_LOCKER_NO_COLUMN_IDX].innerHTML)
        this.lockerStatusSettingPreviousLockerNumber(
          currentVisitInfo[currentSelectedRowIdx].lockerNo,
          currentVisitInfo[currentSelectedRowIdx].visitId
        );

        // visitSno 재구성
        // this.reArrangeGridVisitSno(selectedRow.cells[VISIT_GRID_VISIT_SNO_COLUMN_IDX].innerHTML, VISIT_GRID_VISIT_SNO_COLUMN_IDX + 1, -1)
        this.reArrangeGridVisitSno(
          currentVisitInfo[currentSelectedRowIdx].visitSno,
          this.teamInfo.visitCnt + 1,
          -1
        );
      });
    },
    onVisitsGridBatchDelete(args) {
      if (!args.rowData.visitId) {
        // 추가된 row를 삭제하는 경우
        const focusRowIndex = this.getFocusRowIndexAfterDelete(args.rowIndex);
        this.focusSelectedRow(focusRowIndex);
      }
      this.computeVisitGridAggregation();
    },
    getFocusRowIndexAfterDelete(rowIndex) {
      const currentGridRowLength = this.$refs.visitsGrid.getDataRows().length;
      let returnRowIndex = -1;

      if (currentGridRowLength > 0) {
        if (rowIndex > 0) {
          returnRowIndex = rowIndex - 1;
        } else if (rowIndex === 0) {
          returnRowIndex = 0;
        }
      }

      return returnRowIndex;
    },
    onVisitsGridBatchCancel: function () {
      // 데이터 순번 체크. 체크 실패 시 초기화
      this.checkAndResetVisitsInfoListSno();

      // 카트료 재 계산
      // this.getCartPriceAmt()

      // this.$refs.visitsGrid.clearSelection();

      this.computeVisitGridAggregation();
    },
    onPartnerCertificationButtonClicked() {
      const rowDatas = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      const rowIndex = this.$refs.visitsGrid.getSelectedRowIndexes();

      if (rowIndex === null || rowIndex === undefined) {
        this.errorToast(this.$t("front.popupMessage.notSelectedVisit"));
        return;
      }

      if (rowDatas === null || rowDatas === undefined) {
        this.errorToast(this.$t("front.popupMessage.notSelectedVisit"));
        return;
      }

      if (rowIndex.length <= 0 || rowDatas.length <= 0) {
        this.errorToast(this.$t("front.popupMessage.notSelectedVisit"));
        return;
      }

      const rowData = rowDatas[rowIndex];

      let popupData = {
        popupCloseFlag: true,
        memberName: rowData.visitName,
        birthDay: rowData.birthDay === undefined ? null : rowData.birthDay,
        rowIndex: this.$refs.visitsGrid.currentSelectedRowIndex,
      };

      this.isPartnerCertificationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.partnerCertificationPopup.showPartnerCertificationPopup(
          popupData
        );
      });
    },
    onVisitsGridCellEdit(args) {
      setTimeout(
        () => {
          this.isGridFormCreated = true;
        },
        0,
        args
      );
    },
    async onLockerPublishClicked() {
      const selectedRowIndex = this.$refs.visitsGrid.getSelectedRowIndexes();
      const selectedRows = this.$refs.visitsGrid.getBatchCurrentViewRecords().filter((data, idx) => idx === selectedRowIndex[0]);
      if (selectedRows.length < 1) {
        this.errorToast("락카를 발행할 내장객을 선택해 주세요");
        return;
      }

      const lockerIssueSelectedRows = selectedRows.filter(
        (item) => item.lockerNo !== "000" && item.lockerNo !== "999"
      );
      if (
        !Array.isArray(lockerIssueSelectedRows) ||
        !lockerIssueSelectedRows.length
      ) {
        //빈배열 체크
        this.errorToast(
          "000 혹은 999번 락카키를 제외한 락카만 발행이 가능합니다"
        );
        return;
      }
      const currentViewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      const lockerIssueSelectedChangedRows = currentViewRecords.filter(
        (item) => item.lockerNo !== "000" && item.lockerNo !== "999" && item.lockerNo
      );

      if (lockerIssueSelectedChangedRows.length === 0) {
        this.errorToast("발행 가능한 락카키가 존재하지 않습니다");
        return;
      }

      lockerIssueSelectedChangedRows.forEach(data => {
        data.visitDate = data.visitDate || this.teamInfo.visitDate;
      });

      const lockerIssueInfoObj = {
        resveId: this.resveInfo.resveId,
        chkinId: this.teamInfo.chkinId,
        startCourse: this.teamInfo.startCourse,
        startTime: this.teamInfo.startTime,
        benMembershipName: this.resveInfo.resveName,
        grpName: this.teamInfo.grpName,
        visitId: lockerIssueSelectedRows[0].visitId,
        _rid: lockerIssueSelectedRows[0]._rid,
        dwCode: this.bsnDateInfo?.dwCode
      };
      console.log(lockerIssueSelectedChangedRows,lockerIssueInfoObj);
      this.isLockerIssuePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.lockerIssuePopup.showLockerIssuePopup(
          lockerIssueSelectedChangedRows,
          lockerIssueInfoObj
        );
      });
    },
    onLockerViewClicked() {
      const data = this.$refs.visitsGrid.getSelectedRecords();

      if (data.length === 0) {
        this.errorToast(this.$t("front.popupMessage.notSelectedVisit"));
        return;
      }

      if (this.preLockerNo === null) {
        this.preLockerNo = data[0].lockerNo;
      }

      this.isLockerStatusPopupOpen = true;

      // 팝업 띄우기 전 save 처리
      this.$refs.visitsGrid.saveCell();

      this.$nextTick(() => {
        this.$refs.lockerStatusPopup.showLockerStatusPopup(
          this.teamInfo.visitDate,
          true
        );
      });
    },
    async onVisitsCopyClicked() {
      if (this.visitInfoList.length > 0) {
        this.errorToast("내장객 멤버가 이미 있으므로 복사하실 수 없습니다");
        return;
      }
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
        const gridRef = this.$refs["visitsGrid"];
        const data = gridRef.getBatchChanges();

        this.lockerReset(data);

        const records = gridRef.getBatchCurrentViewRecords();

        // 현재 레코드 모두 삭제
        if (0 < records?.length) {
          gridRef.batchCancel();
        }

        this.addVisitRowToFour();
      }
      let visitDateParam = new Date(this.teamInfo.visitDate);
      visitDateParam.setDate(visitDateParam.getDate() - 1);

      this.isVisitsCopyPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.visitsCopyPopup.showVisitsCopyPopup(
          getFormattedDate(visitDateParam),
          this.resveInfo.resveName
        );
      });
    },
    async onCartDiscountClicked() {
      const selectedRecords = this.$refs.visitsGrid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        return;
      }

      const { chkinId, startCourse } = selectedRecords[0];
      if (!chkinId) {
        this.errorToast("체크인 후 가능합니다");
        return;
      }

      if (
        this.isTeamInfoAndvisitInfoListIsChanged() &&
        !(await this.confirm(
          "저장하지 않은 변경내역이 존재합니다. 그대로 진행하시겠습니까?"
        ))
      ) {
        return;
      }

      this.isCartDiscountPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.cartDiscountPopup.showPopup(
          this.bsnDate,
          chkinId,
          this.teamInfo.grpNo,
          startCourse
        );
      });
    },
    async onNormalRentClicked() {
      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      const currentSelectedRecords = this.$refs.visitsGrid.getSelectedRecords();
      if (currentSelectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
      } else if (currentSelectedRecords.length > 1) {
        this.errorToast("한 명의 내장객만 선택해 주십시오");
      } else {
        const selectedRecord = currentSelectedRecords[0];
        if (selectedRecord.visitId === null) {
          this.errorToast("저장된 내장객을 선택해 주십시오");
        } else {
          let popupData = {
            visitId: selectedRecord.visitId,
            bsnDate: this.teamInfo.visitDate,
            startTime: selectedRecord.startTime,
            startCourse: selectedRecord.startCourse,
            visitName: selectedRecord.visitName,
            lockerNo: selectedRecord.lockerNo,
          };

          this.isSaleInfoPopupOpen = true;
          this.$nextTick(() => {
            this.$refs.saleInfoPopup.showSaleInfoPopup(popupData);
          });
        }
      }
    },
    //EDS-8 Roy:20240315 SMS 전송 기능 추가
    async onSendSmsClicked(){
      try {
        const selectedRow = this.$refs.visitsGrid.getSelectedRecords();

        if (!(selectedRow.length > 0)) {
          this.infoToast("내장객을 선택해 주시기 바랍니다");
          return;
        }
        const smsSendData = {
          smsSendType: "NOTE",
          rcverName: selectedRow[0].visitName,
          rcverInfo: selectedRow[0].contactTel,
          sendKey: "0",
          attrIdx:4  //SMS_SEND_TYPE / SMS전송타입 속성 4 번째 값을 사용하여 전송타입을 갖고옴
        };
        //조편성 요청 ( GRP_LIST) 에서 필요정보 추가
        smsSendData.resveName= this.resveInfo.resveName;
        smsSendData.resveTel= this.resveInfo.contactTel;
        smsSendData.resveCourse= this.teamInfoOptions.startCourseOptions.find((course) => course.comCode === this.resveInfo.resveCourse)?.comName;
        smsSendData.resveTime= this.resveInfo.resveTime;
        smsSendData.resveCourseTime= `${smsSendData.resveCourse} ${smsSendData.resveTime}`;
        smsSendData.resveDate = this.teamInfo.visitDate;

        this.isSmsSendPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
        });
      } catch (e) {
        console.error('e.===>', e);
        this.errorToast(e.message);
      }
    },
    getGreenFee: function (paymtCode, greenFeeInfo) {
      // greenFeeInfo.totAmt는 할인 적용 후 금액임
      if (
        paymtCode !== "NOT" &&
        greenFeeInfo !== undefined &&
        greenFeeInfo !== null &&
        greenFeeInfo.totAmt !== null &&
        greenFeeInfo.totAmt !== undefined
      ) {
        return greenFeeInfo.totAmt;
      } else {
        return null;
      }
    },
    /*
    cartAmtValueAccessor: function (field, data, column) {
      let cartAmt
      if (data.visitId === null) {
        cartAmt = null
      } else {
        cartAmt = data.cartRentInfo.reduce((p, cartAmtInfo) => {
          return p + cartAmtInfo.totAmt
        }, 0)
      }
      return cartAmt
    },
    */
    /*
    getDiscountedPrice (fee, dcList) {
      let retFee = fee

      const payMtDc = dcList.find((dc) => dc.dcMethod === 'PAYMT')
      if (payMtDc !== undefined) { // 요금코드건이 하나라도 있으면 그 요금으로 설정
        retFee = payMtDc.applyAmt
      } else { // 금액할인, 할인율 적용
        dcList.forEach((dcInfo) => {
          if (dcInfo.dcMethod === 'AMT') { // 금액할인
            retFee -= dcInfo.dcAmt
          } else if (dcInfo.dcMethod === 'RATE') { // 할인율
            retFee = Math.round(retFee * (100 - dcInfo.dcAmt) / 100)
          }
        })
      }

      return retFee
    },
    */
    /**
     * 팀 홀구분 변경 시 내장객의 모든 홀 구분 값 변경 & 입장료 재 계산
     * @param args
     */
    async onTeamInfoTeamHoleDivChanged(args) {
      if (args.e !== null) {
        try {
          this.isTeamInfoHoleDivKeydownCaptureLock = true;

          const currentVisitInfo = this.$refs.visitsGrid.getBatchCurrentViewRecords();

          // 요금코드 조회가 끝난 후 finally를 타야 하기 때문에 foreach를 쓰면 안됨
          for (
            let dataRowIdx = 0;
            dataRowIdx < currentVisitInfo.length;
            dataRowIdx++
          ) {
            // 홀 구분 설정
            this.$refs.visitsGrid.updateCell(dataRowIdx, "holeDiv", args.value);

            // 요금코드 금액 조회 API 호출 & 요금 재 계산
            await this.getFixedPaymentCodeAndUpdateGreenFee(dataRowIdx);
          }
        } catch (e) {
          console.error(e);
          this.errorToast("요금 조회 중 오류가 발생하였습니다");
        } finally {
          this.isTeamInfoHoleDivKeydownCaptureLock = false;
        }

        // 카트료 재 계산
        // this.getCartPriceAmt()
      }
    },
    /**
     * 팀 정보, 내장객 현황 데이터 변경 유무 체크
     * 단, 추가한 내장객 중 내장객명이 없는 경우는 무시
     *
     */
    isTeamInfoAndvisitInfoListIsChanged() {
      // #1. 팀 정보 비교
      let isModified =
        JSON.stringify(this.teamInfo) !== JSON.stringify(this.originalTeamInfo);

      // #2. 바뀌지 않았다면 내장객 그리드 비교. 내장객 그리드가 생성된 경우에만 비교
      if (isModified === false && this.isVisitGridCreate) {
        const batchChangesData = this.$refs.visitsGrid.getBatchChanges();

        if (
          (this.getAddedRecordsWithVisitName(batchChangesData.addedRecords)
            .length > 0 &&
            JSON.stringify(this.originalVisitInfoList) !==
              JSON.stringify(
                this.getAddedRecordsWithVisitName(batchChangesData.addedRecords)
              )) ||
          batchChangesData.deletedRecords.length > 0 ||
          batchChangesData.changedRecords.length > 0
        ) {
          isModified = true;
        }
      }
      // if (isModified === false && this.isVisitGridCreate) {
      //   const batchChangesData = this.$refs.visitsGrid.getBatchChanges();
      //   if (
      //     this.getAddedRecordsWithVisitName(batchChangesData.addedRecords)
      //       .length > 0 ||
      //     batchChangesData.deletedRecords.length > 0 ||
      //     batchChangesData.changedRecords.length > 0
      //   ) {
      //     isModified = true;
      //   }
      // }

      return isModified;
    },
    async checkinTeamChange(data) {
      if (!this.teamInfo.grpNo) {
        return;
      }

      this.timeData = {
        chkinId: data.chkinId,
        resveId: data.resveId,
        timeId: data.timeId,
        grpNo: data.grpNo,
      };

      await this.payLock(0, true, this.divideDiv === "T" ? true : false);

      this.divideDiv = this.divideDiv === "T" ? "G" : "T";

      await this.onRefreshBtnClicked();

      await this.payLock(this.stepIndex, false, this.divideDiv === "T" ? true : false);
    },
    async payGridCheckboxChanged (args) {
      let otpFlag = null;
      this.currentPayGridEditingCellIndex = -1;
      if (
          (args.columnName === "lockerReturnFlag" ||
              args.columnName === "caddieFeePymntFlag") &&
          args.value !== args.rowData[args.columnName]
      ) {
        // 수정한 row index 가져오기
        const modifiedRowIndex = this.$refs.payGrid.getRowIndexByPrimaryKey(
            args.rowData.visitId
        );

        if (args.columnName === "lockerReturnFlag") {
          // 락카반납 / 캐디피 지급 유무 저장 API 호출
          await saveLockerCaddieFee({
            visitId: args.rowData.visitId,
            lockerReturnFlag: args.value,
            visitOptFlag: args.rowData.visitOptFlag,
            lockerNo: args.rowData.lockerNo,
            bsnDate: this.teamInfo.visitDate,
          })
              .then((response) => {
                otpFlag = response.value.visitOptFlag;
                this.$refs.payGrid.updateCell(
                    modifiedRowIndex,
                    "visitOptFlag",
                    response.value.visitOptFlag
                ); // api에서 받은 값으로 세팅
                this.$refs.payGrid.batchSave();
              })
              .catch(() => {
                this.$refs.payGrid.batchCancel();
              });
        } else if (args.columnName === "caddieFeePymntFlag") {
          // 락카반납 / 캐디피 지급 유무 저장 API 호출
          await saveLockerCaddieFee({
            visitId: args.rowData.visitId,
            caddieFeePymntFlag: args.value,
            visitOptFlag: args.rowData.visitOptFlag,
            lockerNo: args.rowData.lockerNo,
            bsnDate: this.teamInfo.visitDate,
          })
              .then((response) => {
                otpFlag = response.value.visitOptFlag;
                this.$refs.payGrid.updateCell(
                    modifiedRowIndex,
                    "visitOptFlag",
                    response.value.visitOptFlag
                ); // api에서 받은 값으로 세팅
                this.$refs.payGrid.batchSave();
              })
              .catch(() => {
                this.$refs.payGrid.batchCancel();
              });
        }
        if(otpFlag) {
          const selectRow = this.$refs.payGrid.getSelectedRowIndexes()[0];
          this.paySettleTeamInfoList[selectRow]['visitOptFlag'] = otpFlag;
          this.paySettleTeamInfoList[selectRow][args.columnName] = args.value;
        }

      }

    },
    async onPayGridRecordClick(args) {
      if (
        args.column.field === "startTime" ||
        args.column.field === "startCourse"
      ) {
        await this.checkinTeamChange(args.rowData);
      }
    },
    payGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "startTime" ||
        args.cell.column.field === "startCourse"
      ) {
        args.node.classList.add(this.$t("className.grid.exchangeArea"));
      }

      if (args.cell.column.field === "lockerNo") {
        args.node.classList.add(this.$t("className.grid.lockerArea"));
      } else if (args.cell.column.field === "noPayAmt") {
        args.node.innerText = "미정산액";
        args.node.style.textAlign = "center";
        args.node.style.fontWeight = "bold";
        args.node.style.backgroundColor = "rgb(237, 246, 250)";
        args.node.classList.add(this.$t('className.grid.devVerticalLine'));
      } else if (args.cell.column.field === "cardAmt" || args.cell.column.field === "caddieFeePymntFlag") {
        args.node.classList.add(this.$t('className.grid.devVerticalLine'));
      } else if (args.cell.column.field === "chkoutTime") {
        args.node.classList.add(this.$t("className.frontCheckin.checkoutTimeIcon"));
      }

      if (args.cell.column.field === "cardAmt") {
        args.node.classList.add(this.$t("className.grid.cardArea"));
      } else if (args.cell.column.field === "cashAmt") {
        args.node.classList.add(this.$t("className.grid.cashArea"));
      }
    },
    payGridQueryCellInfo: function (args) {
      const {
        column: {
          field
        },
        cell,
        data
      } = args;

      // 수정영역 셀 처리
      if (
        field === "lockerReturnFlag" ||
        field === "caddieFeePymntFlag"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      // 구분선 처리 - 체크인ID가 달라질 경우에 class 추가
      const payTeamInfoIdx = this.paySettleTeamInfoList.findIndex(
        (payTeamInfo) => payTeamInfo.visitId === data.visitId
      );
      if (payTeamInfoIdx > 0) {
        if (
          this.paySettleTeamInfoList[payTeamInfoIdx].chkinId !==
          this.paySettleTeamInfoList[payTeamInfoIdx - 1].chkinId
        ) {
          cell.classList.add(
            this.$t("className.grid.devReservationPartDivLine")
          );
        }
      }

      // 미정산액 color 표시
      if (field === "noPayAmt") {
        cell.style.fontWeight = "bold";
      }

      // C/O 텍스트 표시
      if (field === "chkoutTime") {
        if (data.chkoutTime) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            "CHKOUT"
          );
          cell.style.color = "#ffffff";
        }
      }

      if (
        field === "noPayAmt" ||
        field === "cardAmt" ||
        field === "caddieFeePymntFlag"
      ) {
        cell.classList.add(this.$t("className.grid.devVerticalLine"));
      }

      if (
        field === "startTime" ||
        field === "startCourse"
      ) {
        cell.classList.add(this.$t("className.grid.exchangeArea"));
      }

      if (field === "startCourse") {
        cell.style.paddingLeft = "0";
        cell.style.paddingRight = "0";
        cell.style.textAlign = "center"; // Grid Column 설정의 textAlign이 paddingLeft/Right 설정 후 적용되지 않음.
      }

      if (field === "payAmt") {
        if (data.prpayFlag) {
          cell.style.backgroundColor = "#fff200";
        }
      }
    },
    payGridCellEdit: function (args) {
      this.currentPayGridEditingCellIndex = args.columnObject.index;
    },
    payGridTeamNumberAggregateFn: function () {
      let teamChkinIdList = this.paySettleTeamInfoList.reduce((p, teamInfo) => {
        if (p.find((pElement) => pElement === teamInfo.chkinId) === undefined) {
          p.push(teamInfo.chkinId);
        }
        return p;
      }, []);
      return teamChkinIdList.length + "팀";
    },
    payGridTotalCountAggregateFn: function () {
      return this.paySettleTeamInfoList.length + "명";
    },
    payGridDataSourceSumDivideByN: function (columnName) {
      let total = this.paySettleTeamInfoList.reduce((p, teamInfo) => {
        if (teamInfo[columnName] !== undefined) {
          p += teamInfo[columnName];
        }
        return p;
      }, 0);
      return Math.round(total / this.paySettleTeamInfoDivideNumber);
    },
    payGridGreenFeeDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("greenFeeAmt");
    },
    payGridCartAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("cartAmt");
    },
    payGridRentAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("rentAmt");
    },
    payGridGrillAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("grillAmt");
    },
    payGridTeeAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("teeAmt");
    },
    payGridShopAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("shopAmt");
    },
    payGridEtcAmtDivideByNAggregateFn() {
      return this.payGridDataSourceSumDivideByN("etcAmt");
    },
    payGridCfrontAmtDivideByNAggregateFn() {
      return this.payGridDataSourceSumDivideByN("cfrontAmt");
    },
    payGridTotAmtDivideByNAggregateFn: function () {
      return this.payGridDataSourceSumDivideByN("totAmt");
    },
    payGridViewDivide(paySettleTeamInfoDivideNumber) {
      this.paySettleTeamInfoDivideNumber = paySettleTeamInfoDivideNumber;
      this.$refs.payGrid.refresh();
    },
    async checkOutConfirm() {
      let state;
      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if(commonCodesGetStandardInfo('lockerReturnFlag')){
        if(!this.teamInfo.teamChkoutFlag) {
          if ((await this.confirm("락카 반납을 하시겠습니까?"))) {
            state = true;
          }else {
            state = false;
          }
        }
      } else {
        state = true;
      }
      let data = {
        paySettleTeamInfoList: this.paySettleTeamInfoList,
        bsnDate: this.teamInfo.visitDate,
        isLockerReturnFlag : state || false
      };
      // console.log(JSON.stringify(data, null, 2))

      if (!this.teamInfo.teamChkoutFlag) {
        if (await this.checkPayLock()) {
          await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
          return;
        }
      }

      await checkOut(!this.teamInfo.teamChkoutFlag, data);

      if (this.teamInfo.teamChkoutFlag) {
        this.infoToast("체크아웃 해제 처리되었습니다");
      } else {
        this.infoToast("체크아웃 처리되었습니다");
      }
      // 상세 조회
      await this.$emit('dataRefresh');
      await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
    },
    storeGridFootTotalAmtAggregateFn: function () {
      let total = this.storeSaleInfoList.reduce((totAmt, storeInfo) => {
        if (storeInfo.storeSaleAttrb === "FOOD") {
          totAmt += storeInfo.totAmt;
        }
        return totAmt;
      }, 0);
      return total;
    },
    async getInfoByTeamOrGrp() {
      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          // 원복
          this.$nextTick(() => {
            if (this.divideDiv === "T") {
              this.divideDiv = "G";
            } else if (this.divideDiv === "G") {
              this.divideDiv = "T";
            }
          });
          return;
        }
      }

      // 팀/단체 radio 버튼 클릭 시 전체 재조회
      this.getCheckinInfo(this.timeData, this.resveCmpnFlag);

      if (this.divideDiv === "T") {
        await this.payLock(0);
      }

      await this.payLock(
        this.stepIndex,
        false,
        this.divideDiv === "T" ? true : false
      );
    },
    storeGridHeaderCellInfo(args) {
      if (args.cell.column.field === "payerNameIcon") {
        args.node.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    storeGridQueryCellInfo(args) {
      if (args.column.field === "priceDiv" || args.column.field === "dcAmt") {
        // 단가구분 - 공통코드 color value. 할인금액 - 단가구분 color와 동일
        args.cell.style.color = commonCodesGetColorValue(
          "PRICE_DIV",
          args.data.priceDiv
        );
      } else if (args.column.field === "payerName") {
        // 지불자 - 정산자 ID와 지불자 ID가 다르면 파랑. 선불이 true이면 회색. 회색이 우선순위
        if (args.data.prpayFrpyFlag) {
          args.cell.style.color = "#A0A0A0";
        } else if (args.data.visitId !== args.data.payerId) {
          args.cell.style.color = "#0000FF";
        }
      } else if (
        args.column.field === "payerNameIcon" &&
        !args.data.prpayFrpyFlag
      ) {
        // 검색 아이콘. 선불이 true이면 표시하지 않음
        args.cell.classList.add(this.$t("className.grid.searchIconArea"));
      } else if (args.column.field === "divideDiv") {
        // 분할 - 공통코드 color vlaue
        args.cell.style.backgroundColor = commonCodesGetColorValue(
          "DIVIDE_DIV",
          args.data.divideDiv
        );
      } else if (args.column.field === "productName" || args.column.field === "totAmt") {
        args.cell.style.color = commonCodesGetColorValue(
          "NOTAX_CODE",
          args.data.notaxCode
        );
      }
      if ([
        "saleQty",
        "saleAmt",
        "dcAmt",
        "totAmt",
      ].includes(args.column.field) && args.data[args.column.field] === 0) {
        args.cell.innerText = "-";
      }
    },
    storeGridClicked: async function (args) {
      if (
        args.column.field === "payerNameIcon" &&
        !args.rowData.prpayFrpyFlag
      ) {
        if (!this.isTeamInfoEnable || this.areAllTeamCheckout) {
          this.errorToast("모든 팀이 체크아웃 상태입니다");
          return;
        }

        if (await this.checkPayLock(false)) {
          this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
          return;
        }

        // 검색아이콘 클릭 시 팝업. 선불 여부가 true이면 팝업을 띄우지 않음
        this.isChangePayerPopupOpen = true;
        let popupData = {};
        if (this.divideDiv === "G") {
          //단체인 경우 grpNo 파라미터로 넘겨주기
          popupData = {
            chkinId: this.teamInfo.chkinId,
            bsnDate: this.teamInfo.visitDate,
            grpNo: this.teamInfo.grpNo,
            slipId: args.rowData.slipId,
            salesTransMapId: args.rowData.salesTransMapId,
          };
        } else if (this.divideDiv === "T") {
          //팀인 경우
          popupData = {
            chkinId: this.teamInfo.chkinId,
            bsnDate: this.teamInfo.visitDate,
            slipId: args.rowData.slipId,
            salesTransMapId: args.rowData.salesTransMapId,
          };
        }

        this.$nextTick(() => {
          this.$refs.changePayerPopup.showChangePayerPopup(popupData);
        });
      }
    },
    payInfoGridHeaderCellInfo: function (args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if (field === "dropdownMenu") {
        node.classList.add(this.$t("className.grid.contextMenuArea"));
      }
      else if (field === "payerNameIcon") {
        node.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    payInfoGridQueryCellInfo: function (args) {
      const {
        column: {
          field
        },
        cell,
        data
      } = args;

      if (field === "delStatus") {
        if (data.delFlag) {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "CANCEL"
          );
        } else {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "OK"
          );
        }
      } else if (field === "approvalDiv") {
        // 정산 상태
        cell.style.color = commonCodesGetColorValue(
          "APPROVAL_DIV",
          data.approvalDiv
        );
      } else if (field === "remarks") {
        if (data.delFlag) {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "CANCEL"
          );
        }
      } else if (field === "payAmt") {
        // 정산 금액
        if (data.delFlag) {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "CANCEL"
          );
        } else {
          cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "OK"
          );
        }

        // 취소선 처리 TODO : 취소 여부 판단을 위해 common code 내 별도 필드 추가하여 구분할 예정. 일단 하드코딩
        if (data.delFlag) {
          cell.style.setProperty("text-decoration", "line-through");
        }
      } else if (field === "cardNo") {
        const cardNo = data["cardNo"];
        const payDiv = data["payDiv"];
        if (payDiv === "CARD") {
          cell.innerText =
            cardNo.substring(0, 4) +
            "-" +
            cardNo.substring(4, 8) +
            "-" +
            cardNo.substring(8, 12) +
            "-" +
            cardNo.substring(12, 16);
        }
      } else if (field === "startCourse") {
        cell.style.paddingLeft = "0";
        cell.style.paddingRight = "0";
        cell.style.textAlign = "center"; // Grid Column 설정의 textAlign이 paddingLeft/Right 설정 후 적용되지 않음.
      }else if (field === "payerNameIcon") {
        // 검색 아이콘. 선불이 true이면 표시하지 않음
        cell.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    payInfoGridClicked: async function (args) {
      if (args.column.field === "payerNameIcon") {
        if (!this.isTeamInfoEnable || this.areAllTeamCheckout) {
          this.errorToast("모든 팀이 체크아웃 상태입니다");
          return;
        }
        if (await this.checkPayLock(false)) {
          this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
          return;
        }
        // 검색아이콘 클릭 시 팝업.
        this.isChangePayerPopupOpen = true;
        let popupData = {};
        if (this.divideDiv === "G") {
          //단체인 경우 grpNo 파라미터로 넘겨주기
          popupData = {
            chkinId: this.teamInfo.chkinId,
            bsnDate: this.teamInfo.visitDate,
            grpNo: this.teamInfo.grpNo,
            slipId: args.rowData.slipId,
            salesTransMapId: args.rowData.salesTransMapId,
            changeDiv : "PAY",
            payId : args.rowData.payId,
            visitId : args.rowData.visitId,
          };
        } else if (this.divideDiv === "T") {
          //팀인 경우
          popupData = {
            chkinId: this.teamInfo.chkinId,
            bsnDate: this.teamInfo.visitDate,
            slipId: args.rowData.slipId,
            salesTransMapId: args.rowData.salesTransMapId,
            changeDiv : "PAY",
            payId : args.rowData.payId,
            visitId : args.rowData.visitId,
          };
        }
        this.$nextTick(() => {
          this.$refs.changePayerPopup.showChangePayerPopup(popupData);
        });
      }
    },
    onPayInfoGridActionComplete: function (args) {
      if (!this.isApprovalDivFiltered && args.requestType === "refresh") {
        this.isApprovalDivFiltered = true;
      }
    },
    payInfopayAmtTotalCaptionAggregateFn: function () {
      let totalAmt = this.payInfoList
        .filter(({ delFlag }) => !delFlag)
        .reduce((p, payInfo) => p + payInfo.payAmt, 0);

      return totalAmt;
    },
    async onPayInfoGridMenuSelected(args) {
      if (args.menu === PAY_INFO_GRID_MENU_CANCEL && args.data.teamChkoutFlag) {
        this.errorToast("이미 체크아웃된 팀입니다");
        return;
      }

      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      if (args.menu === PAY_INFO_GRID_CREDIT_DEF_VERBOSE) {
        const {approvalDivName, creditDefTrackId, creditDefGCDiv} = args.data;

        let routeData;
        switch(creditDefGCDiv) {
          //객실
          case 'C' :
            routeData = this.$router.resolve({
              name: 'RoomCheckIn',
              query: {
                arrivalDate: approvalDivName,
                rresveNo: creditDefTrackId,
              }
            });
            break;
          //골프
          case 'G' :
            routeData = this.$router.resolve({
              name: 'frontCheckinVisit',
              query: {
                bsnDate: approvalDivName,
                chkinId: creditDefTrackId,
              }
            });
            break;
        }
        if(routeData) {
          openNewWindow(routeData);
        }
      } else if (args.menu === PAY_INFO_GRID_MENU_CANCEL) {
        // // 정산상태 정상, 단말승인 체크
        // if (
        //   args.data.approvalDiv !== "OK" &&
        //   args.data.approvalDiv !== "T_OK"
        // ) {
        //   this.errorToast(
        //     "정산상태가 정상승인, 단말승인일 경우에만 정산 취소가 가능합니다"
        //   );
        //   return;
        // }

        if (args.data.delFlag) {
          this.errorToast("이미 취소된 데이터입니다.");
          return;
        }

        if (args.data.storeCode !== "FRONT" && args.data.prpayFrpyDiv === "0") {
          this.errorToast(
            "선불정산된 자료는 내장객 현황에서 입금 취소할 수 없습니다"
          );
          return;
        }

        this.isPayCancelPopupOpen = true;
        let popupDate = {
          salesDiv: "SALES",
          chkinId: args.data.chkinId,
          visitId: args.data.visitId,
          payId: args.data.payId,
          payDate: moment(args.data.payDate, "YYYYMMDD").format("YYYY-MM-DD"),
          cardId: args.data.cardId,
          cashId: !args.data.delFlag && args.data.approvalDiv !== "CANCEL" ? args.data.cashId : null,
          useId: args.data.depositUseId,
          payDiv: args.data.payDiv,
          bsnDate: this.teamInfo.visitDate,
          payAmt: args.data.payAmt,
          visitName: args.data.visitName,
          teamGrpFlag: this.divideDiv === "G",
          grpNo: this.teamInfo.grpNo,
          storeCode: args.data.storeCode,
          isOnlyPayDataCancel:
            args.data.approvalDiv !== "OK" && args.data.approvalDiv !== "T_OK"
              ? true
              : false,
        };
        this.$nextTick(() => {
          this.$refs.payCancelPopup.showPayCancelPopup(popupDate);
        });
      } else if (args.menu === PAY_INFO_GRID_MENU_REPUBLIC) {
        // if (args.data.approvalDiv === "CANCEL") {
        //   this.errorToast("정산취소된 영수증입니다");
        //   return;
        // }
        if (
          args.data.payDiv === "DEPOSIT" ||
          args.data.payDiv === "PRECARD" ||
          (args.data.payDiv === "GIFT" && args.data.depositUseId)
        ) {
          this.isDepositUseReceiptPopupOpen = true;

          this.$nextTick(() => {
            this.$refs.depositUseReceiptPopup.showDepositUseReceiptPopup(
              args.data.depositUseId
            );
          });
        } else {
          this.isReIssueReceiptPopupOpen = true;
          this.$nextTick(() => {
            this.$refs.reIssueReceiptPopup.showReIssueReceiptPopup({
              salesDiv: "SALES",
              payId:
                args.data.payDiv === "CARD" || args.data.payDiv === "EASY_PAY"
                  ? args.data.cardId
                  : args.data.payId,
              payDiv: args.data.payDiv,
              isPayCash: false,
              isCashCancel: args.data.payDiv === "CASH" && !args.data.delFlag && args.data.approvalDiv === "CANCEL" ? true : false
            });
          });
        }
      }
    },
    async onDivideByNClicked() {
      if (this.isPopupOpened) {
        return;
      }

      if (await this.checkPayLock(false)) {
        this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
        return;
      }

      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isDivideByNPopupOpen = true;

      await this.payLock(
        this.stepIndex,
        true,
        this.divideDiv === "T" ? true : false
      );

      let popupDate = {
        bsnDate: this.teamInfo.visitDate,
        chkinId: this.teamInfo.chkinId,
        grpNo: this.divideDiv === "G" ? this.teamInfo.grpNo : null,
        divideDiv: this.divideDiv,
      };
      this.$nextTick(() => {
        this.$refs.divideByNPopup.showDivideByNPopup(popupDate);
      });
    },
    async onNewPartialSumPopupClicked() {
      if (this.isPopupOpened) {
        return;
      }

      if (await this.checkPayLock(false)) {
        this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
        return;
      }

      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isNewPartialSumPopup = true;

      await this.payLock(
        this.stepIndex,
        true,
        this.divideDiv === "T" ? true : false
      );

      const selectedRecords = this.$refs.payGrid.getSelectedRecords();

      let popupDate = {
        bsnDate: this.teamInfo.visitDate,
        chkinId: this.teamInfo.chkinId,
        grpNo: this.divideDiv === "G" ? this.teamInfo.grpNo : null,
        divideDiv: this.divideDiv,
        visitId: selectedRecords.length > 0 ? selectedRecords[0].visitId : null
      };
      this.$nextTick(() => {
        this.$refs.newPartialSumPopup.showPopup(popupDate);
      });
    },
    async onPartialSumPopupClicked() {
      if (this.isPopupOpened) {
        return;
      }

      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isPartialSumPopupOpen = true;

      await this.payLock(
        this.stepIndex,
        true,
        this.divideDiv === "T" ? true : false
      );

      const selectedRecords = this.$refs.payGrid.getSelectedRecords();

      let popupDate = {
        bsnDate: this.teamInfo.visitDate,
        chkinId: this.teamInfo.chkinId,
        grpNo: this.divideDiv === "G" ? this.teamInfo.grpNo : null,
        divideDiv: this.divideDiv,
        visitId: selectedRecords.length > 0 ? selectedRecords[0].visitId : null
      };
      this.$nextTick(() => {
        this.$refs.partialSumPopup.showPartialSumPopup(popupDate);
      });
    },
    onPaymentStatementPopupClicked() {
      this.isPaymentStatementPopupOpen = true;
      let popupData = {
        bsnDate: this.teamInfo.visitDate,
        chkinId: this.teamInfo.chkinId,
        grpNo: this.divideDiv === "G" ? this.teamInfo.grpNo : null,
      };
      this.$nextTick(() => {
        this.$refs.paymentStatementPopup.showPopup(popupData);
      });
    },
    onPayReceiptPopupClicked: function () {
      let selectedRecords = this.$refs.payGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
        return;
      }

      this.isPayReceiptPopupOpen = true;
      let popupData = {
        bsnDate: this.teamInfo.visitDate,
        chkinId: selectedRecords[0].chkinId,
        grpNo: this.teamInfo.grpNo,
        grpName: this.teamInfo.grpName,
        startTime: selectedRecords[0].startTime,
        startCourse: commonCodesGetComName("COURSE_CODE", selectedRecords[0].startCourse),
        visitName: selectedRecords[0].visitName,
        lockerNo: selectedRecords[0].lockerNo,
        visitId: selectedRecords[0].visitId,
      };
      this.$nextTick(() => {
        this.$refs.payReceiptPopup.showPayReceiptPopup(popupData);
      });
    },
    onOpenReceiptClicked() {
      let selectedRecords = this.$refs.payGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
        return;
      }

      this.isOpenReceiptPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.openReceiptPopup.showOpenReceiptPopup({
          visitId: selectedRecords[0].visitId,
          visitName: selectedRecords[0].visitName,
        });
      });
    },
    async payProcess() {
      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      let selectedRecords = this.$refs.payGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
        return;
      }

      let chkinIds = [];
      this.paySettleTeamInfoList.forEach(data => {
        if (chkinIds.findIndex(item => item === data.chkinId) === -1) {
          chkinIds.push(data.chkinId);
        }
      });

      const { isPending, storeCode, visitId } = await GolfErpAPI.fetchIsCompulsoryPayment(chkinIds);

      if (isPending) {
        const visitName = this.paySettleTeamInfoList.filter(data => data.visitId === visitId)[0]?.visitName;
        if (!(await this.confirm(
          visitName + "님이 " + commonCodesGetComName("STORE_CODE", storeCode) + "에서 정산 중입니다.<br>" +
          "강제 진행하시겠습니까?"
        ))) {
          return;
        }

        await GolfErpAPI.postCompulsoryPayment(chkinIds);
      }

      await this.payLock(
        this.stepIndex,
        true,
        this.divideDiv === "T" ? true : false
      );

      this.getCheckinInfo(this.timeData, this.resveCmpnFlag).then(() => {
        let popupData = {
          personFlag: this.personFlag, // 개인정산여부
          bsnDate: this.teamInfo.visitDate, // 영업일자
          chkinId: selectedRecords[0].chkinId, // 체크인ID
          grpNo: this.divideDiv === "G" ? this.teamInfo.grpNo : null, // 단체번호
          visitId: selectedRecords[0].visitId, // 내장객ID
          storeCode: "FRONT", // 매장코드. Front 화면에서는 'FRONT' 고정
          salesId: null, // 매출ID (매장코드가 FRONT일 경우엔 null)
          visitName: selectedRecords[0].visitName,
          lockerNo: selectedRecords[0].lockerNo,
          prpayFrpyDiv: "1", // Front 화면에서는 1 고정
          salesDiv: "SALES", // Front 화면에서는 'SALES' 고정
        };
        this.isPayPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.payPopup.showPopup(popupData);
        });
      });
    },
    /**
     * 내장객등록 그리드에서 현재 편집중인 row data를 가져옴
     */
    getVisitsGridCurrentRowData: function (rowIndex) {
      return this.$refs.visitsGrid.getBatchCurrentViewRecords()[rowIndex];
    },
    async locketStatusSettingProcess(
      selectedGridIdx,
      newLockerNo,
      visitId, // 신규 추가 (락카 상태 EMPTY로 업데이트시 락카 리턴 플래그 체크하는데 본인은 제외하기 위함)
      previousLockerNo = "",
      currentSexCode = "",
    ) {

      const trimedNewLockerNo = newLockerNo.trim();
      // 999, 000, ""(Empty String): 예전 락커 반납 & 성별 변경
      if (isReservedLockerNo(newLockerNo)) {
        // 성별 변경
        if (trimedNewLockerNo === "000" && currentSexCode === "F") {
          // 000 입력 시 성별 여 --> 남
          this.$refs.visitsGrid.updateCell(selectedGridIdx, "sexCode", "M");
        } else if (trimedNewLockerNo === "999" && currentSexCode === "M") {
          // 000 입력 시 성별 남 --> 여
          this.$refs.visitsGrid.updateCell(selectedGridIdx, "sexCode", "F");
        }
        // 반납
        await this.lockerStatusSettingPreviousLockerNumber(previousLockerNo, visitId);
      }
      // #1. 새로운 락커 번호를 CHECKIN 상태로 설정
      else {
        // +: 남자 자동, - : 여자 자동
        const lockerAutoAssignCode = ["+", "-", "*"];
        if (lockerAutoAssignCode.includes(trimedNewLockerNo)) {
          const lockerDiv = {
            "+": "M",
            "-": "F",
            "*": "A",
          }[trimedNewLockerNo];
          try {
            // 락카 키 자동 발행
            // (자동 발행시 isStatusUpdate = true 이면 락카 상태까지 배정으로 UPDATE)
            // (isStatusUpdate = true 이면서 이전 락카번호까지 넘겨주면 이전 락카번호는 빈락카 상태로 UPDATE)
            const autoLockerKeyInfoResponse = await GolfErpAPI.fetchAutoLockerBoard(
              {
                bsnDate: this.teamInfo.visitDate,
                lockerDiv,
                previousLockerNo,
                isStatusUpdate: true,
                visitId,
              }
            );

            if (!autoLockerKeyInfoResponse.lockerBoard) {
              this.lockerErrorProcess(
                "배정할 번호가 없습니다",
                selectedGridIdx,
                previousLockerNo
              );
              return;
            }

            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "lockerNo",
              autoLockerKeyInfoResponse.lockerBoard.lockerNo
            );
            this.$refs.visitsGrid.updateCell(
                selectedGridIdx,
                "lockerPrtNo",
                autoLockerKeyInfoResponse.lockerBoard.lockerPrtNo
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sector",
              autoLockerKeyInfoResponse.lockerBoard.sector
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sectorPrtMale",
              autoLockerKeyInfoResponse.lockerBoard.sectorPrtMale
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sectorPrtFemale",
              autoLockerKeyInfoResponse.lockerBoard.sectorPrtFemale
            );

            // ## 락카 키 저장 완료 후 성별 설정
            //   1) 현재 선택된 rowIndex, fieldName 가져오기. saveCell() 전에 가져와야 함
            const editingCellField = this.$refs.visitsGrid.editingCellField;
            const currentRowIndex = this.$refs.visitsGrid
              .currentSelectedRowIndex;

            //   2) "성별" 필드에 focusing 된 경우, saveCell()
            if (editingCellField === "sexCode") {
              this.$refs.visitsGrid.saveCell();
            }

            if (trimedNewLockerNo === "+" && currentSexCode === "F") {
              // + 입력시 여 -> 남
              this.$refs.visitsGrid.updateCell(selectedGridIdx, "sexCode", "M");
            } else if (trimedNewLockerNo === "-" && currentSexCode === "M") {
              // - 입력시 남 -> 여
              this.$refs.visitsGrid.updateCell(selectedGridIdx, "sexCode", "F");
            }

            //   3) "성별" 필드에 focusing 되었던 경우, 재 포커싱
            if (editingCellField === "sexCode") {
              this.reEditCellWithPreEditedCell(
                currentRowIndex,
                editingCellField
              );
            }

            //   4) 맨 밑 row의 락커에서 + 또는 - 를 친 후 엔터키를 쳤을 경우는 셀 포커싱
            //      (락카 셀 포커싱이 아닌 백네임 맨위로 포커싱 이동되도록 수정)
            if (this.isEnterKeyDownInLockerNoBottomAfterPlusMinus) {
              this.isEnterKeyDownInLockerNoBottomAfterPlusMinus = false;
              this.$refs.visitsGrid.editCell(currentRowIndex, "bagName");
              // const totalVisitsGridNumber = this.$refs.visitsGrid.getDataRows()
              //   .length;
              // this.$refs.visitsGrid.editCell(
              //   totalVisitsGridNumber - 1,
              //   "lockerNo"
              // );
            }

            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "visitConfirmFlag",
              true
            );
          } catch (e) {
            // getAutoLockerKeyInfo API에서 오류가 나면 다음과 같은 버그 발생
            // case ) 락카에 +/- 입력 -> '내장객' 과 같은 input 셀에 focus -> 여기 catch 문의 lockerErrorProcess 메소드 실행 ->
            //        락카 셀에 대한 cellEdit 이벤트 발생 -> recordClick 이벤트가 발생(? 보통은 발생하지 않아야 함) -> cellSave 이벤트 발생 -> 포커싱 사라짐
            // cf) API 호출시에만 발생하지 않으면 되므로, 일단 기록만 해 둠
            this.lockerErrorProcess(
              "락커 번호 자동 부여 실패하였습니다",
              selectedGridIdx,
              previousLockerNo
            );
          }
        } else {
          // 락카 번호 입력
          try {
            const currentVisitsList = this.$refs.visitsGrid.getBatchCurrentViewRecords();
            const visitId = currentVisitsList[selectedGridIdx].visitId;

            let lockerOverlap = false;
            currentVisitsList.forEach((visit, idx) => {
              if (
                idx != selectedGridIdx &&
                visit.lockerNo === trimedNewLockerNo
              ) {
                lockerOverlap = true;
              }
            });

            const newLockerInfo = await GolfErpAPI.fetchLockerBoard({
              bsnDate: this.teamInfo.visitDate,
              lockerNo: trimedNewLockerNo,
              previousLockerNo: previousLockerNo,
              visitId: visitId,
            });

            if (newLockerInfo?.lockerBoard?.isLockerUse) {
              if (!(await this.confirm("이미 사용중인 락카번호입니다.<br>사용하시겠습니까?"))) {
                this.lockerErrorProcess(
                  "이미 사용중인 락카번호입니다.",
                  selectedGridIdx,
                  previousLockerNo
                );
                return;
              }
            }

            if (lockerOverlap) {
              if (!(await this.confirm("이미 사용중인 락카번호입니다.<br>사용하시겠습니까?"))) {
                this.lockerErrorProcess(
                  "이미 사용중인 락카번호입니다.",
                  selectedGridIdx,
                  previousLockerNo
                );
                return;
              }
            }

            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sector",
              newLockerInfo.lockerBoard.sector
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sectorPrtMale",
              newLockerInfo.lockerBoard.sectorPrtMale
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sectorPrtFemale",
              newLockerInfo.lockerBoard.sectorPrtFemale
            );
            this.$refs.visitsGrid.updateCell(
                selectedGridIdx,
                "lockerPrtNo",
                newLockerInfo.lockerBoard.lockerPrtNo
            );
            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "sexCode",
              newLockerInfo.lockerBoard.lockerDiv
            );

            this.$refs.visitsGrid.updateCell(
              selectedGridIdx,
              "visitConfirmFlag",
              true
            );
          } catch (e) {
            // getLockerKeyInfo 에서 오류가 나면 다음과 같은 버그 발생
            // case ) 락카에 +/- 입력 -> '내장객' 과 같은 input 셀에 focus -> 여기 catch 문의 lockerErrorProcess 메소드 실행 ->
            //        락카 셀에 대한 cellEdit 이벤트 발생 -> recordClick 이벤트가 발생(? 보통은 발생하지 않아야 함) -> cellSave 이벤트 발생 -> 포커싱 사라짐
            // cf) API 호출시에만 발생하지 않으면 되므로, 일단 기록만 해 둠
            this.lockerErrorProcess(
              "락커 번호 변경에 실패하였습니다",
              selectedGridIdx,
              previousLockerNo
            );
          }
        }
      }
    },
    async settingLockerNumber(
      newLockerNo,
      previousLockerNo,
      selectedGridIdx,
      isUpdateLockerNo = false,
      visitId = null,
    ) {
      let data = {
        bsnDate: this.teamInfo.visitDate, // 영업일자
        lockerNo: newLockerNo, // 락카번호
        resveName: null, // 예약자명
        resveId: null, // 예약ID
        resveSno: null, // 예약순번
        remarks: null, // 비고
        visitId: visitId, // 내장객ID
        isVisitorUse: true,
      };

      try {
        if (newLockerNo !== "000" && newLockerNo !== "999") {
          await lockerStatusSetting("ASSIGN", data, true);
        }

        // isUpdateLockerNo 가 true이면, 그리드 update
        if (isUpdateLockerNo) {
          this.$refs.visitsGrid.updateCell(
            selectedGridIdx,
            "lockerNo",
            newLockerNo
          );
        }
        // #2-1. 성공 시 이전 락커번호가 있으면 이전 락커번호를 EMPTY 상태로 설정
        await this.lockerStatusSettingPreviousLockerNumber(previousLockerNo, visitId);
        return true;
      } catch (e) {
        console.log("lockerStatusSetting.err.===>", e);
        // #2-2. 실패하면 이전 값으로 돌리고 끝
        this.lockerErrorProcess(
          "사용할 수 없는 락카 번호입니다",
          selectedGridIdx,
          previousLockerNo
        );
        return false;
      }
    },
    /**
     * 락카 처리 중 에러 발생 시 처리 : toast를 띄우고, 락카 번호를 예전 값으로 되돌린 후 락카 셀 focusing
     * @param errorMessage toast 에러 메시지
     * @param selectedGridIdx 편집중인 락카 row index
     * @param previousLockerNo 예전 락카번호
     */
    lockerErrorProcess(errorMessage, selectedGridIdx, previousLockerNo) {
      this.errorToast(errorMessage);
      // saveCell을 먼저 하고 update를 해야 update한 값이 남는다
      this.$refs.visitsGrid.saveCell();
      this.$refs.visitsGrid.updateCell(
        selectedGridIdx,
        "lockerNo",
        previousLockerNo
      );
      this.$refs.visitsGrid.editCell(selectedGridIdx, "lockerNo");
    },
    async lockerStatusSettingPreviousLockerNumber(previousLockerNo, visitId) {
      if (isReservedLockerNo(previousLockerNo)) {
        return;
      }
      let data = {
        bsnDate: this.teamInfo.visitDate, // 영업일자
        lockerNo: previousLockerNo, // 락카번호
        resveName: null, // 예약자명
        resveId: null, // 예약ID
        resveSno: null, // 예약순번
        remarks: null, // 비고
        isVisitorUse: true,
        visitId: visitId,
      };

      try {
        await lockerStatusSetting("EMPTY", data, true); // 성공하면 조용히 끝
      } catch (e) {
        this.errorToast(`${previousLockerNo} 번 락카 반납이 실패하였습니다`); // 실패 시 toast 남김
        console.log("lockerStatusSetting.err.===>", e);
      }
    },
    async onDivideCancelMenuSelect(args) {
      if (await this.checkPayLock(false)) {
        this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
        return;
      }

      // 데이터 변경사항 체크
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      let data = {
        visitDate: this.teamInfo.visitDate, // 내장일자
      };
      if (this.divideDiv === "G") {
        data.grpNo = this.teamInfo.grpNo;
      } else if (this.divideDiv === "T") {
        data.chkinId = this.teamInfo.chkinId;
      }
      // 분할 취소 API 호출(전체취소만)
      // divideCancel(encodeURIComponent(args.item.value), data)
      divideCancel(encodeURIComponent("ALL"), data)
        .then(() => {
          this.infoToast("분할 취소 처리되었습니다");
          // 상세 조회
          this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
        })
        .catch((error) => {
          console.log("divideCancel.err.===>", error);
        });
    },
    onOutFlagChange(args) {
      let currentVisitsList = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      if (Array.isArray(currentVisitsList) && currentVisitsList.length > 0) {
        currentVisitsList.forEach((visit, index) => {
          if (args.target.checked) {
            if (!visit.visitConfirmFlag) {
              this.$refs.visitsGrid.updateCell(index, "visitConfirmFlag", true);
            }
          } else {
            if (visit.visitConfirmFlag) {
              this.$refs.visitsGrid.updateCell(
                index,
                "visitConfirmFlag",
                false
              );
            }
          }
        });
      }
    },
    isCheckinLayerStatisIsContent() {
      return this.checkinLayerStatus === CHECKIN_LAYER_CONTENT;
    },
    async onLockerStatusPopupClose(lockerNo, lockerDiv, sector, sectorPrtMale, sectorPrtFemale, lockerPrtNo) {
      this.isLockerStatusPopupOpen = false;

      if (lockerNo) {
        const preLockerNo = JSON.parse(JSON.stringify(this.preLockerNo));
        const rowIndex = this.$refs.visitsGrid.getSelectedRowIndexes();

        this.$refs.visitsGrid.updateCell(rowIndex, "lockerNo", lockerNo);
        this.$refs.visitsGrid.updateCell(rowIndex, "lockerPrtNo", lockerPrtNo);
        if (sector) {
          this.$refs.visitsGrid.updateCell(rowIndex, "sector", sector);
        }
        if (sectorPrtMale) {
          this.$refs.visitsGrid.updateCell(rowIndex, "sectorPrtMale", sectorPrtMale);
        }
        if (sectorPrtFemale) {
          this.$refs.visitsGrid.updateCell(rowIndex, "sectorPrtFemale", sectorPrtFemale);
        }

        if (lockerDiv === "F" || lockerDiv === "M") {
          this.$refs.visitsGrid.updateCell(rowIndex, "sexCode", lockerDiv);
        }

        await this.settingLockerNumber(lockerNo, preLockerNo, rowIndex, false);

        this.preLockerNo = lockerNo;
      }
    },
    onVisitGridRowSelected(args) {
      const data = this.$refs.visitsGrid.getBatchCurrentViewRecords();

      this.preLockerNo = data[args.rowIndex].lockerNo;

      // rowIndex 기반으로 row selecting
      this.visitsGridSelectedIdx = args.rowIndex;
    },
    lockVisitEjsDropdownListEditKeyDown(args) {
      if (args.eventState) {
        args.eventState.lockKeyDown = true;
      }
    },
    releaseVisitEjsDropdownListEditKeyDown(args) {
      if (args.eventState) {
        args.eventState.lockKeyDown = false;
      }
    },
    /**
     * 내장객 목록 내 홀 구분 값 중 max 홀 구분 값 가져오기(기준 : IDX === 3인 ATTRB)
     */
    getMaxHoleDiv() {
      const visitGridHoleDivList = this.$refs.visitsGrid
        .getBatchCurrentViewRecords()
        .filter((record) => {
          if (
            !(record.visitName === null) &&
            !(record.visitName === undefined) &&
            !(record.visitName.trim() === "")
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((record) => record.holeDiv);
      /*if(selectedGridIdx > -1 && selectedGridIdxValue !== null) {
        visitGridHoleDivList[selectedGridIdx] = selectedGridIdxValue; // 지금 바뀐 값은 getBatchCurrentViewRecords() 에 반영되지 않았으므로
      }*/

      let maxHoleDivAttrbValueByIdx3 = visitGridHoleDivList.reduce(
        (maxAttrbObject, visitHoleDiv) => {
          const currentHoleDivAttrbValueByIdx3 = commonCodesGetCommonCodeAttrbByCodeAndIdx(
            "HOLE_DIV",
            visitHoleDiv,
            3
          );
          if (maxAttrbObject.maxAttrbIdx3 === null) {
            return {
              comCode: visitHoleDiv,
              maxAttrbIdx3: currentHoleDivAttrbValueByIdx3,
            };
          } else {
            if (maxAttrbObject.maxAttrbIdx3 >= currentHoleDivAttrbValueByIdx3) {
              return maxAttrbObject;
            } else {
              return {
                comCode: visitHoleDiv,
                maxAttrbIdx3: currentHoleDivAttrbValueByIdx3,
              };
            }
          }
        },
        { comCode: null, maxAttrbIdx3: null }
      );

      return maxHoleDivAttrbValueByIdx3.comCode;
    },
    async onVisitEjsDropdownListEditTemplateChanged(args) {
      if (!args) {
        throw new Error(
          "invalid args in onVisitEjsDropdownListEditTemplateChanged"
        );
      }

      const selectedGridIdx = args.rowIndex;

      switch (args.columnName) {
        case "memberGrade":
          // eslint-disable-next-line no-case-declarations
          const editingCellField = this.$refs.visitsGrid.editingCellField;
          // eslint-disable-next-line no-case-declarations
          const currentRowIndex = this.$refs.visitsGrid.currentSelectedRowIndex;

          if (args.value !== "NOR") {
            // 현재 선택된 rowIndex, fieldName 가져오기. saveCell() 전에 가져와야 함
            const viewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();
            const visitName =
              viewRecords.length > 0
                ? viewRecords[args.rowIndex].visitName
                : null;

            // 회원등급이 일반이 아니면 동명이인 회원 검색 팝업 호출.
            this.onMemberPopupOpen(
              false,
              this.memberPopupType.VISIT_MEMBER,
              visitName,
              args.rowIndex,
              {
                editingCellField: editingCellField,
                currentRowIndex: currentRowIndex,
              },
              true
            );
          } else {
            await this.setVisitNonMemberInfo(currentRowIndex);
          }

          this.$EventBus.$emit("loaderOn");
          // 요금 조회
          try {
            this.lockVisitEjsDropdownListEditKeyDown(args);
            await this.getPaymentCodeAndUpdateGreenFee(
              selectedGridIdx,
              args.value
            );
          } catch (e) {
            console.error(e);
            this.errorToast("요금 조회 중 오류가 발생하였습니다");
          } finally {
            this.releaseVisitEjsDropdownListEditKeyDown(args);
            this.$EventBus.$emit("loaderOff");
          }

          // 카트료 재 계산
          // this.getCartPriceAmt()
          break;
        case "paymtCode":
          // 요금 조회
          try {
            this.lockVisitEjsDropdownListEditKeyDown(args);
            await this.getFixedPaymentCodeAndUpdateGreenFee(
              selectedGridIdx,
              args.value
            );
          } catch (e) {
            console.error(e);
            this.errorToast("요금 조회 중 오류가 발생하였습니다");
          } finally {
            this.releaseVisitEjsDropdownListEditKeyDown(args);
          }

          // 카트료 재 계산
          // this.getCartPriceAmt()
          break;
        case "holeDiv":
          try {
            this.lockVisitEjsDropdownListEditKeyDown(args);

            // 내장객 내 max 홀 구분 값을 팀 홀 구분 값으로 설정 --> 하지 않음

            // 요금 조회
            await this.getFixedPaymentCodeAndUpdateGreenFee(
              selectedGridIdx,
              null,
              args.value
            );

            // 카트료 재 계산
            // this.getCartPriceAmt()
          } catch (e) {
            console.error(e);
            this.errorToast(
              "팀 홀 구분값 조정 또는 요금 조회 중 오류가 발생하였습니다"
            );
          } finally {
            this.releaseVisitEjsDropdownListEditKeyDown(args);
          }
          break;
      }
    },
    onGridCheckboxChanged(args) {
      if (!args) {
        throw new Error("invalid args in onGridCheckboxChanged");
      }

      const selectedGridIdx = args.rowIndex;

      switch (args.columnName) {
        case "mmbsCrtfcResultFlag":
          if (args.value) {
            // 체크 시 인증 팝업 띄우기
            let popupData = {
              popupCloseFlag: true,
              memberName: args.rowData.visitName,
              birthDay:
                args.rowData.birthDay === undefined
                  ? null
                  : args.rowData.birthDay,
              rowIndex: selectedGridIdx,
            };

            this.isPartnerCertificationPopupOpen = true;
            this.$nextTick(() => {
              this.$refs.partnerCertificationPopup.showPartnerCertificationPopup(
                popupData
              );
            });
          } else {
            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "mmbsCrtfcResult",
              null
            );
          }
          break;
        case "visitConfirmFlag":
          // eslint-disable-next-line no-case-declarations
          const batchCurrentViewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();
          // eslint-disable-next-line no-case-declarations
          let visitConfirmFlagTrueListLength = batchCurrentViewRecords.filter(
            (record, recordIndex) =>
              record.visitConfirmFlag && recordIndex !== selectedGridIdx
          ).length;

          // 지금 체크한 row 값은 getBatchCurrentViewRecords 에 아직 반영되지 않았으므로, 체크했으면 length + 1
          if (args.value) {
            visitConfirmFlagTrueListLength++;
          }

          if (visitConfirmFlagTrueListLength >= this.teamInfo.visitCnt) {
            this.teamInfo.visitConfirmFlag = true;
          } else {
            this.teamInfo.visitConfirmFlag = false;
          }
          break;
        case "hasNormalDiscount":
          if (!args.value) {
            const {
              dcList,
            } = this.$refs.visitsGrid.getBatchCurrentViewRecords()[
              args.rowIndex
            ];

            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "dcList",
              dcList.filter(({ dcApplyDiv }) => dcApplyDiv !== "NOR")
            );
          } else {
            if (
              JSON.stringify(this.teamInfo) !==
              JSON.stringify(this.originalTeamInfo)
            ) {
              this.errorToast(
                "수정된 팀정보 내용이 존재합니다.<br>저장후 할인 적용하실 수 있습니다."
              );
              return;
            }

            // 할인 팝업 연동
            let currentRowData = this.getVisitsGridCurrentRowData(
              args.rowIndex
            );
            if (currentRowData.paymtCode === "NOT") {
              this.errorToast(
                "요금구분이 미적용인 경우에는 할인 설정하실 수 없습니다"
              );
              return;
            }

            this.isDiscountPopupOpen = true;
            let visitInfo = {
              startCourse: currentRowData.startCourse, // 출발코스
              startTime: currentRowData.startTime, // 출발시간
              holeDiv: currentRowData.holeDiv, // 홀구분
              visitName: currentRowData.visitName, // 내장객명
              lockerNo: currentRowData.lockerNo, // 락카번호
              memberGrade: currentRowData.memberGrade, // 회원등급
              paymtCode: currentRowData.paymtCode, // 요금구분
              membershipId: currentRowData.membershipId, // 회원권ID
              memberNo: currentRowData.memberNo, // 회원번호
              bsnDate: this.teamInfo.visitDate,
            };
            this.$nextTick(() => {
              this.$refs.discountPopup.showDiscountPopup(
                visitInfo,
                currentRowData.greenFeeInfo,
                currentRowData.dcList,
                args.rowIndex,
                this.teamInfo.teamChkoutFlag,
                "NOR"
              );
            });
          }

          break;
        case "hasCouponDiscount":
          if (!args.value) {
            const {
              dcList,
            } = this.$refs.visitsGrid.getBatchCurrentViewRecords()[
              args.rowIndex
            ];

            this.$refs.visitsGrid.updateCell(
              args.rowIndex,
              "dcList",
              dcList.filter(({ dcApplyDiv }) => dcApplyDiv !== "COUPON")
            );
          } else {
            if (
              JSON.stringify(this.teamInfo) !==
              JSON.stringify(this.originalTeamInfo)
            ) {
              this.errorToast(
                "수정된 팀정보 내용이 존재합니다.<br>저장후 할인 적용하실 수 있습니다."
              );
              return;
            }

            // 할인 팝업 연동
            let currentRowData = this.getVisitsGridCurrentRowData(
              args.rowIndex
            );
            if (currentRowData.paymtCode === "NOT") {
              this.errorToast(
                "요금구분이 미적용인 경우에는 할인 설정하실 수 없습니다"
              );
              return;
            }

            this.isDiscountPopupOpen = true;
            let visitInfo = {
              startCourse: currentRowData.startCourse, // 출발코스
              startTime: currentRowData.startTime, // 출발시간
              holeDiv: currentRowData.holeDiv, // 홀구분
              visitName: currentRowData.visitName, // 내장객명
              lockerNo: currentRowData.lockerNo, // 락카번호
              memberGrade: currentRowData.memberGrade, // 회원등급
              paymtCode: currentRowData.paymtCode, // 요금구분
              membershipId: currentRowData.membershipId, // 회원권ID
              memberNo: currentRowData.memberNo, // 회원번호
              bsnDate: this.teamInfo.visitDate,
            };
            this.$nextTick(() => {
              this.$refs.discountPopup.showDiscountPopup(
                visitInfo,
                currentRowData.greenFeeInfo,
                currentRowData.dcList,
                args.rowIndex,
                this.teamInfo.teamChkoutFlag,
                "COUPON"
              );
            });
          }

          break;
      }
    },
    depositUseReceiptPopupClosed() {
      this.isDepositUseReceiptPopupOpen = false;
    },
    partnerCertificationPopupClosed(popupData) {
      this.isPartnerCertificationPopupOpen = false;

      const rowIndex = popupData.rowIndex;
      const code = popupData.code;

      if (code === null) {
        this.$refs.visitsGrid.updateCell(
          rowIndex,
          "mmbsCrtfcResultFlag",
          false
        );
        this.$refs.visitsGrid.updateCell(rowIndex, "mmbsCrtfcResult", null);
      } else {
        this.$refs.visitsGrid.updateCell(rowIndex, "mmbsCrtfcResultFlag", true);
        this.$refs.visitsGrid.updateCell(rowIndex, "mmbsCrtfcResult", code);
      }

      this.$refs.visitsGrid.editCell(rowIndex, "mmbsCrtfcResultFlag");
    },
    onTeamInfoHoleDivKeydownCapture(e) {
      if (this.isTeamInfoHoleDivKeydownCaptureLock) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    onPayInfoGridSectionBodyKeyDown(e) {
      switch (e.keyCode) {
        case 27: // ESC - 모든 동작 취소
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onStoreGridSectionBodyKeyDown(e) {
      switch (e.keyCode) {
        case 27: // ESC - 모든 동작 취소
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onPayGridSectionBodyKeyDown(e) {
      switch (e.keyCode) {
        case 27: // ESC - 모든 동작 취소
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onVisitGridSectionBodyKeyDown(e) {
      const visitsGrid = this.$refs.visitsGrid;
      const editingCellField = visitsGrid.editingCellField;
      const currentRowIndex = visitsGrid.currentSelectedRowIndex;
      const totalVisitsGridNumber = visitsGrid.getDataRows().length;
      const dropdownListColumns = [
        "sexCode",
        "memberGrade",
        "paymtCode",
        "holeDiv",
        "nativeDiv",
      ];

      switch (e.keyCode) {
        case 9: // TAB
          if (editingCellField === "dcContents" && !e.shiftKey) {
            // 맨 마지막 할인내용 - tab 키
            if (currentRowIndex === totalVisitsGridNumber - 1) {
              // 가장 마지막 할인내용에 focus - 이벤트 취소
              e.stopPropagation();
              e.preventDefault();
            } else {
              // 아래 row의 내장객에 focus
              visitsGrid.editCell(currentRowIndex + 1, "visitName");
            }
          } else if (editingCellField === "visitName" && !e.shiftKey) {
            // 내장객 - tab 키 - lockerNo
            visitsGrid.editCell(currentRowIndex, "lockerNo");
          } else if (editingCellField === "lockerNo" && e.shiftKey) {
            // 락카 - shift tab 키 - 내장객
            e.stopPropagation();
            e.preventDefault();
            visitsGrid.editCell(currentRowIndex, "visitName");
          }
          break;

        case 13: // ENTER
          switch (editingCellField) {
            case "visitName": // 내장객
              if (
                visitNameLockerNoEnterKey ===
                visitNameLockerNoEnterKeyRule.HORIZONTAL
              ) {
                // 내장객 -> 락카 -> 밑의 row 내장객 -> 락카
                e.stopPropagation();
                e.preventDefault();
                visitsGrid.editCell(currentRowIndex, "lockerNo");
              } else if (
                visitNameLockerNoEnterKey ===
                visitNameLockerNoEnterKeyRule.VERTICAL
              ) {
                // 내장객 -> 내장객 -> .. --> 맨 밑의 내장객 -> 첫 번째 row의 락카 -> 락카 -> ... -> 맨 밑의 락카
                if (currentRowIndex === totalVisitsGridNumber - 1) {
                  // 가장 마지막 Row
                  e.stopPropagation();
                  e.preventDefault();
                  visitsGrid.editCell(0, "lockerNo");
                } else {
                  e.stopPropagation();
                  e.preventDefault();
                  visitsGrid.editCell(currentRowIndex + 1, "visitName");
                }
              }
              break;
            case "lockerNo": // 락카
              if (currentRowIndex === totalVisitsGridNumber - 1) {
                // 가장 마지막 Row에 포커스
                e.stopPropagation();
                e.preventDefault();

                const afterLockerNo = e.target.value.trim();

                // + 또는 -를 입력하는 경우 - 값이 다시 바뀔것이기 때문에 isEnterKeyDownInLockerNoBottomAfterPlusMinus 값만 true로 바꾸고 focusing은 하지 않음. 셀 focusing은 cellSaved 이벤트 핸들러에서
                if (afterLockerNo === "+" || afterLockerNo === "-") {
                  this.isEnterKeyDownInLockerNoBottomAfterPlusMinus = true;
                  visitsGrid.saveCell();

                  if (totalVisitsGridNumber < this.teamInfo.visitCnt) {
                    this.onAddVisitInfoClicked();
                  }
                } else {
                  visitsGrid.saveCell();
                  if (totalVisitsGridNumber < this.teamInfo.visitCnt) {
                    this.onAddVisitInfoClicked();
                  } else {
                    // 락카 셀 포커싱이 아닌 백네임 맨위로 포커싱 이동되도록 수정.
                    visitsGrid.editCell(currentRowIndex, "bagName");
                    // visitsGrid.editCell(currentRowIndex, editingCellField);
                  }
                }
              } else if (currentRowIndex < totalVisitsGridNumber - 1) {
                // - 가장 마지막 Row가 아닌 경우 : Enter 키 rule에 따라 이동
                e.stopPropagation();
                e.preventDefault();

                if (
                  visitNameLockerNoEnterKey ===
                  visitNameLockerNoEnterKeyRule.HORIZONTAL
                ) {
                  // 내장객 -> 락카 -> 밑의 row 내장객 -> 락카
                  visitsGrid.editCell(currentRowIndex + 1, "visitName");
                } else if (
                  visitNameLockerNoEnterKey ===
                  visitNameLockerNoEnterKeyRule.VERTICAL
                ) {
                  // 내장객 -> 내장객 -> .. --> 맨 밑의 내장객 -> 첫 번째 row의 락카 -> 락카 -> ... -> 맨 밑의 락카
                  visitsGrid.editCell(currentRowIndex + 1, "lockerNo");
                }
              }
              break;
            case "sexCode": // 성별
            case "memberGrade": // 회원등급
            case "paymtCode": // 요금구분
            case "holeDiv": // 홀구분
            case "nativeDiv": // 내국인
              // eslint-disable-next-line no-case-declarations
              let editRowIndex = currentRowIndex;
              if (currentRowIndex < totalVisitsGridNumber - 1) {
                // 바로 아래의 row edit
                editRowIndex++;
              }
              // timer를 쓰지 않기 위해, target에 event handler를 추가함 - 그리드의 기본 동작을 끝내고, 아래의 핸들러를 실행하여 cell editing
              e.target.addEventListener(
                "keydown",
                () => {
                  visitsGrid.saveCell();
                  visitsGrid.editCell(editRowIndex, editingCellField);
                },
                {
                  once: true,
                }
              );
              break;
            default:
              if (currentRowIndex === totalVisitsGridNumber - 1) {
                // 가장 마지막 Row에 포커스 - 이벤트 취소 후 save & focus
                // timer를 쓰지 않기 위해, target에 event handler를 추가함 - 그리드의 기본 동작을 끝내고, 아래의 핸들러를 실행하여 cell editing
                e.target.addEventListener(
                  "keydown",
                  () => {
                    visitsGrid.saveCell();
                    visitsGrid.editCell(currentRowIndex, editingCellField);
                  },
                  {
                    once: true,
                  }
                );

                /*setTimeout(() => {
                  visitsGrid.editCell(currentRowIndex, editingCellField);
                }, 10);*/
              }
              break;
          }
          e.preventDefault();
          break;
        case 38: // UP ARROW
          if (editingCellField) {
            if (
              !dropdownListColumns.includes(editingCellField) &&
              currentRowIndex > 0
            ) {
              // dropdown 제외. 1st row에 포커싱 시 제외
              e.stopPropagation();
              e.preventDefault();
              visitsGrid.editCell(currentRowIndex - 1, editingCellField);
            }
          }
          break;
        case 40: // DOWN ARROW
          if (editingCellField) {
            if (
              !dropdownListColumns.includes(editingCellField) &&
              currentRowIndex < totalVisitsGridNumber - 1
            ) {
              // dropdown 제외. 마지막 row에 포커싱 시 제외
              e.stopPropagation();
              e.preventDefault();
              visitsGrid.editCell(currentRowIndex + 1, editingCellField);
            }
          }
          break;
        case 27: // ESC - 모든 동작 취소
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onVisitGridClick(e) {
      if (e.srcElement) {
        const columnName = e.srcElement.getAttribute("column-name");
        if (columnName) {
          switch (columnName) {
            case "sexCode":
            case "memberGrade":
            case "paymtCode":
            case "holeDiv":
            case "nativeDiv":
              this.gridVisitEjsDropdownlistEditTemplateEventBus.$emit(
                "click-open",
                columnName
              );
              break;
          }
        }
      }
    },
    onWindowKeypress(event) {
      if (this.isPopupOpened) {
        return;
      }

      if (
        keypressEventEqualsShortcut(
          event,
          SHORTCUT_CHECK_IN_OUT_LAYER_THUMBNAIL.shortcut
        )
      ) {
        if (this.checkinLayerStatus === CHECKIN_LAYER_CONTENT) {
          this.onLayerBtnClicked();
          this.$emit('focusBySelectedRow');
        }
      } else if (
        keypressEventEqualsShortcut(
          event,
          SHORTCUT_CHECK_IN_OUT_LAYER_CONTENT.shortcut
        )
      ) {
        if (this.checkinLayerStatus === CHECKIN_LAYER_THUMBNAIL) {
          this.onLayerBtnClicked();
        }
      }

      if (this.activeDialog || !this.isShowContent(null)) {
        return;
      }

      if (keypressEventEqualsShortcut(event, SHORTCUT_CHECKIN.shortcut)) {
        this.goStepPage(0);
      } else if (
        keypressEventEqualsShortcut(event, SHORTCUT_CHECKOUT.shortcut)
      ) {
        this.goStepPage(1);
      }
    },
    payInfoHeaderClick() {
      this.isPayInfoGridVisible = !this.isPayInfoGridVisible;
    },
    visitsGridHeaderPaymtCodeDoubleClicked() {
      const viewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();

      if (viewRecords.length > 0) {
        const paymtCode = viewRecords[0].paymtCode;

        viewRecords.forEach(async (data, idx) => {
          this.$refs.visitsGrid.updateCell(idx, "paymtCode", paymtCode);

          // 요금 조회
          try {
            this.lockVisitEjsDropdownListEditKeyDown({
              eventState: {},
            });
            await this.getFixedPaymentCodeAndUpdateGreenFee(
              idx,
              paymtCode
            );

            // 카트료 재 계산
            // this.getCartPriceAmt()
          } catch (e) {
            console.error(e);
            this.errorToast("요금 조회 중 오류가 발생하였습니다");
          } finally {
            this.releaseVisitEjsDropdownListEditKeyDown({
              eventState: {},
            });
          }
        });
      }
    },
    visitsGridHeaderHoleDivDoubleClicked() {
      const viewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();

      if (viewRecords.length > 0) {
        const holeDiv = viewRecords[0].holeDiv;

        viewRecords.forEach(async (data, idx) => {
          this.$refs.visitsGrid.updateCell(idx, "holeDiv", holeDiv);

          try {
            this.lockVisitEjsDropdownListEditKeyDown({
              eventState: {},
            });

            // 내장객 내 max 홀 구분 값을 팀 홀 구분 값으로 설정 --> 하지 않음

            // 요금 조회
            await this.getFixedPaymentCodeAndUpdateGreenFee(
              idx,
              null,
              holeDiv
            );

            // 카트료 재 계산
            // this.getCartPriceAmt()
          } catch (e) {
            console.error(e);
            this.errorToast(
              "팀 홀 구분값 조정 또는 요금 조회 중 오류가 발생하였습니다"
            );
          } finally {
            this.releaseVisitEjsDropdownListEditKeyDown({
              eventState: {},
            });
          }
        });
      }
    },
    visitsGridHeaderPrpayFlagDoubleClicked() {
      const viewRecords = this.$refs.visitsGrid.getBatchCurrentViewRecords();

      if (viewRecords.length > 0) {
        const prpayFlag = viewRecords[0].prpayFlag;

        viewRecords.forEach((data, idx) => {
          this.$refs.visitsGrid.updateCell(idx, "prpayFlag", prpayFlag);
        });
      }
    },
    async holeAddButtonClicked() {
      const resveId = this.resveInfo.resveId;
      const chkinId = this.teamInfo.chkinId;

      if (!resveId || !chkinId) {
        this.errorToast("홀 추가하실 수 없는 타임입니다.");
        return;
      }

      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const holeAddData = {
        startTime: this.teamInfo.startTime,
        startCourse: this.teamInfo.startCourse,
        resveName: this.resveInfo.resveName,
        grpNo: this.teamInfo.grpNo,
        grpName: this.teamInfo.grpName,
        grpKind: this.teamInfo.grpKind,
        chkinId: chkinId,
        resveId: resveId,
        contactTel: this.resveInfo.contactTel,
        optFlag: this.teamInfo.optFlag
      };

      this.isHoleAddPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.holeAddPopup.showHoleAddPopup(
            this.bsnDate,
            holeAddData
        );
      });
    },
    onHoleAddPopupClosed(event) {
      this.isHoleAddPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.$emit("dataRefresh");
      }
    },
    onVisitDeleteConfirmPopupClosed() {
      this.isVisitDeleteConfirmPopupOpen = false;
    },
    onVisitDeleteConfirmPopupConfirm(event) {
      if (event) {
        // 선택할 row 설정
        this.visitsGridSelectedIdx = this.getFocusRowIndexAfterDelete(
          event.deletedIdx
        );

        // 상세 조회
        this.getCheckinInfo(event.timeData, this.resveCmpnFlag);
        this.$emit("dataRefresh");
      }
    },
    onVisitDeleteConfirmPopupDataRefresh() {
      this.$emit("dataRefresh");
      this.closeCheckin();
    },
    onVisitDeleteConfirmPopupOpen(deletedRows, deletedIdx) {
      const args = {
        deletedRows,
        deletedIdx,
        visitInfoList: this.visitInfoList,
        teamInfo: this.teamInfo,
        resveId: this.resveInfo.resveId
      };

      this.isVisitDeleteConfirmPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.visitDeleteConfirmPopup.showPopup(args);
      });
    },
    async onPrepaymentButtonClicked() {
      if (this.isTeamInfoAndvisitInfoListIsChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      let selectedRecords = this.$refs.visitsGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("내장객을 선택해 주십시오");
        return;
      }

      if (!selectedRecords[0].visitId) {
        this.errorToast("내장객을 등록 후 정산바랍니다!");
        return;
      }

      this.getCheckinInfo(this.timeData, this.resveCmpnFlag).then(() => {
        let popupData = {
          personFlag: true, // 개인정산여부
          bsnDate: this.teamInfo.visitDate, // 영업일자
          chkinId: selectedRecords[0].chkinId, // 체크인ID
          grpNo: this.teamInfo.grpNo ? this.teamInfo.grpNo : null, // 단체번호
          visitId: selectedRecords[0].visitId, // 내장객ID
          storeCode: "FRONT", // 매장코드. Front 화면에서는 'FRONT' 고정
          salesId: null, // 매출ID (매장코드가 FRONT일 경우엔 null)
          visitName: selectedRecords[0].visitName,
          lockerNo: selectedRecords[0].lockerNo,
          prpayFrpyDiv: "0", // Front 화면에서는 1 고정 (선불정산은 0)
          salesDiv: "SALES", // Front 화면에서는 'SALES' 고정
        };
        this.isPayPopupOpen = true;

        this.$nextTick(() => {
          this.$refs.payPopup.showPopup(popupData);
        });
      });
    },
    onTakeCompanionMemberPopupClosed() {
      this.isTakeCompanionMemberPopupOpen = false;
    },
    onMemberVisitStatusPopupClosed() {
      this.isMemberVisitStatusPopup = false;
    },
    onNewPartialSumPopupClosed(isSave) {
      this.isNewPartialSumPopup = false;

      if (isSave) {
        this.onRefreshBtnClicked();
      }
    },
    onMemberInfoPopupClosed() {
      this.isMemberInfoPopupOpen = false;
      this.memberInfoMemberId = null;
      this.memberInfoMemberNo = null;
    },
    async onSelectPaySumMenuItems({item :{value}}) {
      if (await this.checkPayLock(false)) {
        this.errorToast("해당 팀은 다른 직원이 처리 중입니다");
        return;
      }

      let paramData, selectedRecord;
      const totalVisitIds = this.$refs.payGrid.getCurrentViewRecords().map(record => ({visitId: record.visitId}));

      switch(value) {
        case "ALL":
          selectedRecord = this.$refs.payGrid.getSelectedRecords()[0];
          paramData = {
            bsnDate: this.teamInfo.visitDate,
            chkinId: this.teamInfo.chkinId,
            partialSumList: [{
              partialSumDetailList: totalVisitIds,
              payerId: selectedRecord.visitId
            }],
            partialSumStoreList: commonCodesGetCommonCode("STORE_GROUP").map((code) => code.comCode),
          };
          await savePartialSum(paramData);
          this.infoToast("전체합산 처리되었습니다.");
          await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
          break;
        case "PARTIAL":
          await this.onPartialSumPopupClicked();
          break;
      }
    },
    onTakeCompanionMemberPopupConfirm(data) {
      const gridInstace = this.$refs.visitsGrid;
      const batchCurrentViewRecords = gridInstace.getBatchCurrentViewRecords()
        ?.filter(item => item.lockerNo);
      const hasChkinId = !!this.teamInfo.chkinId;
      let countToAdd = 0;
      let nextNo = 1;
      // 이미 부여 받은 락카 번호가 있으면 api 검사 하지 않음
      this.isAlreadyAssignedLockNoFlag = true;
      gridInstace.batchCancel();
      batchCurrentViewRecords
        ?.forEach(item => {
          if (!item.chkinId || !hasChkinId) {
            gridInstace.addRecord(item);
            countToAdd++;
            nextNo++;
          }
        });
      if (hasChkinId) {
        const len = batchCurrentViewRecords.length;
        nextNo = len + 1;
        countToAdd = len + data.length;
      } else {
        countToAdd += data.length;
      }
      const maxVisitCount = this.teamInfo.visitCnt;
      if (maxVisitCount < countToAdd) {
        this.errorToast("내장인원수보다 많은 동반자를 추가하실 수 없습니다");
      } else {
        data
          ?.forEach(item =>
            this.addVisitsInfoRecord(nextNo++, null, null, item));
      }
      if (!hasChkinId) {
        for (let i = nextNo; i <= maxVisitCount; i++) {
          this.addVisitsInfoRecord(i);
        }
      }
      const hasNoneLockerNo = (gridInstace.getBatchCurrentViewRecords()
        ?.filter(item => !item.lockerNo || item.lockerNo === '') || []);
      if (hasNoneLockerNo.length > 0) {
        const _rid = hasNoneLockerNo[0]?._rid;
        if (_rid) {
          this.rowIndexAfterTakeCmpnMember = (gridInstace.getBatchCurrentViewRecords()
            ?.findIndex(item => item._rid === _rid) || 0);
        }
      }
    },
    async onShowMemberImageClick(data) {
      if (!data.memberId) {
        return;
      }
      this.memberInfoMemberId = data.memberId;
      this.memberInfoMemberNo = data.memberNo;

      this.isMemberInfoPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.memberInputPopup.show();
      });

      /*
      if (!data.membershipId) {
        return;
      }
      data.visitDate = this.teamInfo.visitDate;

      this.isMemberVisitStatusPopup = true;
      await this.$nextTick();
      this.$refs.memberVisitStatusPopup.showPopup(data);*/
    },

    // blackList 관련
    async blacklistPopupOpen(blacklistList) {
      this.isBlacklistPopupOpen = true;
      await this.$nextTick();
      await this.$refs.blacklistPopup.showBlackListPopup({
        reservationTimeList: null,
        reservationInfo: null,
        blacklistList
      });
    },
    onBlacklistPopupClose() {
      this.isBlacklistPopupOpen = false;
    },

    async reservationCheck(contactTel, grpNo, membershipId, resveNo) {
      if (!contactTel && !grpNo && !membershipId) {
        return;
      }

      // 위약 체크
      const {
        value: {
          penalties = [],
          membershipAnnualFeeMessage,
        }
      } = await getReservationCheck(
          contactTel,
          grpNo,
          membershipId,
          this.teamInfo.visitDate,
          "DAY",
          resveNo,
          true,
      );
      // 가족 연회비 체크
      if (membershipAnnualFeeMessage) {
        this.errorToast(membershipAnnualFeeMessage);
      }

      // 내장 체크
      const chkInPenalties = penalties.filter( data => data.penltyLmttScope === "GOLF" ||  "VISIT");

      if (0 < chkInPenalties.length) {
        await this.blacklistPopupOpen(penalties);
      }
    },
    async onChangeCartKind() {
      const isLimousine = this.teamInfo.cartKind === "LIMOUSINE" && this.teamInfo.cartKind !== this.originalTeamInfo.cartKind;
      if(!isLimousine) {
        return;
      }
      const params = {
        resveDate: this.teamInfo.visitDate,
        cartKind: this.teamInfo.cartKind,
      };
      const cartAbleCnt = await GolfErpAPI.fetchReservationCartCount(params);
      const isOverCartCount = cartAbleCnt - this.teamInfo.cartQty < 0;
      if(isOverCartCount) {
        this.errorToast("사용 가능한 카트 수량을 초과하였습니다.");
      }
    },
    async onChangeCartQty() {
      await this.onChangeCartKind();
    },
    onChangeSelfFlag() {
      /* 셀프팀 선택여부에 따른 카트 변경 기능 제거
      const isSelfTeam = this.teamInfo.selfFlag;
      isSelfTeam
          ? this.teamInfo.cartKind = "SELF"
          : this.teamInfo.cartKind = this.resveInfo.cartKind;
      */
      // 셀프팀 체크에 따른 내장객 요금구분 변경
      const records = this.$refs.visitsGrid.getBatchCurrentViewRecords();
      for (let i = 0; i < records.length; i++) {
        this.getPaymentCodeAndUpdateGreenFee(i);
      }
    },
    /**
     *  내장팀 변경이력 조회
     */
    async viewHistory() {
      if (this.timeData.resveId) {
        const rawHistories = await GolfErpAPI.getCheckinHistory({resveId:this.timeData.resveId});
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              VisitTeam: {
                __name__: "내장팀 정보",
                chkinId          :  {name: "체크인ID", type: "string", methods: ["create", "update", "delete"],},
                visitDate        :  {name: "내장일자", type: "string", methods: ["create", "update", "delete"],},
                startCourse      :  {name: "출발코스", type: "string", methods: ["create", "update", "delete"],},
                startTime        :  {name: "출발시간", type: "string", methods: ["create", "update", "delete"],},
                partDiv          :  {name: "부구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "PART_DIV"},
                teamHoleDiv      :  {name: "팀 홀구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "HOLE_DIV"},
                resveKind        :  {name: "예약종류", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "RESVE_KIND"},
                resveChannel     :  {name: "예약채널", type: "string", methods: ["create", "update", "delete"],},
                resveName        :  {name: "예약자명", type: "string", methods: ["create", "update", "delete"],},
                membershipId     :  {name: "회원권ID", type: "string", methods: ["create", "update", "delete"],},
                memberNo         :  {name: "회원번호", type: "string", methods: ["create", "update", "delete"],},
                memberDiv        :  {name: "회원구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "MEMBER_DIV"},
                memberGrade      :  {name: "회원등급", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "MEMBER_GRADE"},
                benMembershipId  :  {name: "특전회원권ID", type: "string", methods: ["create", "update", "delete"],},
                grpNo            :  {name: "단체번호", type: "string", methods: ["create", "update", "delete"],},
                grpKind          :  {name: "단체종류", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "GRP_KIND"},
                grpName          :  {name: "단체명", type: "string", methods: ["create", "update", "delete"],},
                teamGreenFee     :  {name: "팀그린피", type: "number", methods: ["create", "update", "delete"],},
                promtnId         :  {name: "프로모션ID", type: "number", methods: ["create", "update", "delete"],},
                visitCnt         :  {name: "내장인원수", type: "number", methods: ["create", "update", "delete"],},
                cartKind         :  {name: "카트종류", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "CART_KIND"},
                cartQty          :  {name: "카트수량", type: "number", methods: ["create", "update", "delete"],},
                eventContents    :  {name: "행사내용", type: "string", methods: ["create", "update", "delete"],},
                kepFlag          :  {name: "보관여부", type: "boolean", methods: ["create", "update", "delete"],},
                selfFlag         :  {name: "셀프여부", type: "boolean", methods: ["create", "update", "delete"],},
                joinFlag         :  {name: "조인여부", type: "boolean", methods: ["create", "update", "delete"],},
                aditTeamFlag     :  {name: "추가팀여부", type: "boolean", methods: ["create", "update", "delete"],},
                galleyTeamFlag   :  {name: "갤러리팀여부", type: "boolean", methods: ["create", "update", "delete"],},
                visitConfirmFlag :  {name: "내장확정여부", type: "boolean", methods: ["create", "update", "delete"],},
                rainFlag         :  {name: "우천취소여부", type: "boolean", methods: ["create", "update", "delete"],},
                payIngFlag       :  {name: "정산진행여부", type: "boolean", methods: ["create", "update", "delete"],},
                payIngId         :  {name: "정산진행자ID", type: "string", methods: ["create", "update", "delete"],},
                teamChkoutFlag   :  {name: "팀체크아웃", type: "boolean", methods: ["create", "update", "delete"],},
                teamChkoutDt     :  {name: "팀체크아웃일시", type: "string", methods: ["create", "update", "delete"],},
                // optFlag          :  {name: "OPT여부", type: "boolean", methods: ["create", "update", "delete"],},
                delFlag          :  {name: "삭제여부", type: "boolean", methods: ["create", "update", "delete"],},
              },
              VisitCmpn: {
                // __name__: "내장객 정보",
                __name__: (type, history) => `내장객 정보 (${history?.record?.visitName})`,
                visitId            :  {name: "내장객ID", type: "number", methods: ["create", "update", "delete"],},
                chkinId            :  {name: "체크인ID", type: "number", methods: ["create", "update", "delete"],},
                visitSno           :  {name: "내장순번", type: "number", methods: ["create", "update", "delete"],},
                visitName          :  {name: "내장객명", type: "string", methods: ["create", "update", "delete"],},
                lockerNo           :  {name: "락카번호", type: "string", methods: ["create", "update", "delete"],},
                sexCode            :  {name: "성별", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "SEX_CODE"},
                bagName            :  {name: "백네임", type: "string", methods: ["create", "update", "delete"],},
                membershipId       :  {name: "회원권ID", type: "string", methods: ["create", "update", "delete"],},
                memberNo           :  {name: "회원번호", type: "string", methods: ["create", "update", "delete"],},
                memberDiv          :  {name: "회원구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "MEMBER_DIV"},
                memberGrade        :  {name: "회원등급", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "MEMBER_GRADE"},
                paymtCode          :  {name: "요금코드", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "PAYMT_CODE"},
                holeDiv            :  {name: "홀구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "HOLE_DIV"},
                joinGroupNo        :  {name: "조인그룹번호", type: "string", methods: ["create", "update", "delete"],},
                nativeDiv          :  {name: "내국인여부", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "NATIVE_DIV"},
                // mmbsCrtfcResult    :  {name: "조합원인증결과", type: "string", methods: ["create", "update", "delete"],},
                // dcmpnMemberNo      :  {name: "동반할인회원번호", type: "string", methods: ["create", "update", "delete"],},
                // mCardMemberNo      :  {name: "무기명카드회원번호", type: "string", methods: ["create", "update", "delete"],},
                vehicleNo          :  {name: "차량번호", type: "string", methods: ["create", "update", "delete"],},
                contactTel         :  {name: "연락처", type: "string", methods: ["create", "update", "delete"],},
                visitConfirmFlag   :  {name: "내장확정여부", type: "boolean", methods: ["create", "update", "delete"],},
                argmtAditFlag      :  {name: "배치추가여부", type: "boolean", methods: ["create", "update", "delete"],},
                prpayFlag          :  {name: "선불여부", type: "boolean", methods: ["create", "update", "delete"],},
                lockerReturnFlag   :  {name: "락카반납여부", type: "boolean", methods: ["create", "update", "delete"],},
                caddieFeePymntFlag :  {name: "캐디피지급여부", type: "boolean", methods: ["create", "update", "delete"],},
                autoChkinFlag      :  {name: "자동체크인여부", type: "boolean", methods: ["create", "update", "delete"],},
                chkoutFlag         :  {name: "체크아웃여부", type: "boolean", methods: ["create", "update", "delete"],},
                chkoutDt           :  {name: "체크아웃일시", type: "string", methods: ["create", "update", "delete"],},
                dcContents         :  {name: "할인내용", type: "string", methods: ["create", "update", "delete"],},
                // optFlag            :  {name: "상품코드", type: "boolean", methods: ["create", "update", "delete"],},
                delFlag            :  {name: "삭제여부", type: "boolean", methods: ["create", "update", "delete"],},
                delReason          :  {name: "삭제사유", type: "string", methods: ["create", "update", "delete"],},
              },
              VisitFee: {
                __name__: "매출 정보",
                // __name__: (type, history) => `매출 정보 (${history?.record?.slipId})`,
                slipId        :  {name: "전표ID", type: "string", methods: ["create", "update", "delete"],},
                sno           :  {name: "순번", type: "string", methods: ["create", "update", "delete"],},
                salesId       :  {name: "매출ID", type: "string", methods: ["create", "update", "delete"],},
                productCode   :  {name: "상품코드", type: "string", methods: ["create", "update", "delete"],},
                productName   :  {name: "상품명", type: "string", methods: ["create", "update", "delete"],},
                notaxCode     :  {name: "면세", type: "commonCode", methods: ["create", "update", "delete"], groupCode:"NOTAX_CODE"},
                payerId          :  {name: "정산자ID", type: "string", methods: ["create", "update", "delete"],},
                priceDiv         :  {name: "단가구분", type: "commonCode", methods: ["create", "update", "delete"],groupCode: "PRICE_DIV"},
                saleQty          :  {name: "판매수량", type: "number", methods: ["create", "update", "delete"],},
                salePrice        :  {name: "판매단가", type: "number", methods: ["create", "update", "delete"],},
                saleAmt          :  {name: "판매금액", type: "number", methods: ["create", "update", "delete"],},
                dcAmt            :  {name: "할인금액", type: "number", methods: ["create", "update", "delete"],},
                dcRate           :  {name: "할인율", type: "number", methods: ["create", "update", "delete"],},
                totAmt           :  {name: "총금액", type: "number", methods: ["create", "update", "delete"],},
                netAmt           :  {name: "공급가액", type: "number", methods: ["create", "update", "delete"],},
                vatAmt           :  {name: "부가세", type: "number", methods: ["create", "update", "delete"],},
                specialTax       :  {name: "특소세", type: "number", methods: ["create", "update", "delete"],},
                eduTax           :  {name: "교육세", type: "number", methods: ["create", "update", "delete"],},
                vilTax           :  {name: "농특세", type: "number", methods: ["create", "update", "delete"],},
                fund             :  {name: "체육기금", type: "number", methods: ["create", "update", "delete"],},
                svcAmt           :  {name: "봉사료", type: "number", methods: ["create", "update", "delete"],},
                etcTax1          :  {name: "기타세1", type: "number", methods: ["create", "update", "delete"],},
                etcTax2          :  {name: "기타세2", type: "number", methods: ["create", "update", "delete"],},
                divideDiv        :  {name: "분할구분", type: "commonCode", methods: ["create", "update", "delete"], groupCode: "DIVIDE_DIV"},
                divideOrder      :  {name: "분할차수", type: "number", methods: ["create", "update", "delete"],},
                caddieUseFlag    :  {name: "캐디이용여부", type: "boolean", methods: ["create", "update", "delete"],},
                // beforSalesId     :  {name: "이동전매출ID", type: "number", methods: ["create", "update", "delete"],},
                // voidSlipId       :  {name: "취소전표ID", type: "number", methods: ["create", "update", "delete"],},
                // optFlag          :  {name: "OPT여부", type: "boolean", methods: ["create", "update", "delete"],},
                remarks          :  {name: "비고", type: "string", methods: ["create", "update", "delete"],},
                delFlag          :  {name: "삭제여부", type: "boolean", methods: ["create", "update", "delete"],},
              },
            },
          });
        });
      } else {
        this.errorToast("팀 정보가 없습니다");
      }
    },
    historyPopupClose() {
      this.isHistoryPopupOpen = false;
    },

    // 내장객 카트요금 조회 API 사용하지 않기로 - 체크인 팝업 오픈 초기 정보 조회 API에서 조회해 오는 것으로 변경
    /*
    getCartPriceAmt: function () {
      console.log('#### 내장객 카트요금 조회 API 호출 #####')

      const visitGridDataList = getGridCurrentList(this.$refs.visitsGrid, 'visitId')
      let data = {
        bsnDate: this.teamInfo.visitDate,
        teamHoleDiv: this.teamInfo.teamHoleDiv,
        cartQty: this.teamInfo.cartQty,
        visitList: visitGridDataList.map(visit => {
          return {
            visitSno: visit.visitSno, // 내장객 순번
            memberGrade: visit.memberGrade, // 회원등급
            paymtCode: visit.paymtCode, // 요금코드
            holeDiv: visit.holeDiv // 홀구분
          }
        })
      }

      getCartPriceAmt(data).then((response) => {
        const visitCartAmtList = response.value.visitCartAmtList
        const gridLength = this.$refs.visitsGrid.getDataRows().length
        for (let gridIdx = 0; gridIdx < gridLength; gridIdx++) {
          let currentVistSno = this.$refs.visitsGrid.getCellFromIndex(gridIdx, VISIT_GRID_VISIT_SNO_COLUMN_IDX).innerHTML
          let visitCartAmt = visitCartAmtList.find(visitCartAmt => visitCartAmt.visitSno === Number(currentVistSno))
          console.log(visitCartAmt)


        }
        console.log(response)
      }).catch(error => {
        console.log('getCartPriceAmt.err.===>', error)
      })
    }
    */
    async saveEventContents(teamInfo) {
      const { chkinId, eventContents, visitDate} = teamInfo;

      if(!chkinId) {
        return this.errorToast('체크인 후 저장 가능합니다');
      }

      if (this.originalTeamInfo.eventContents === this.teamInfo.eventContents) {
        this.errorToast("변경된 내용이 없습니다");
        return;
      }

      if((await this.confirm("저장하시겠습니까?"))) {
        try {
          await GolfErpAPI.patchTeamOrVisitors({chkinId, visitDate, eventContents},[],[],this.resveInfo.resveId);
          await this.infoToast('저장되었습니다');
          await this.getCheckinInfo(this.timeData, this.resveCmpnFlag);
          this.$emit("dataRefresh");
        }catch (e) {
          console.error(e);
        }
      }
    },
    async onRainFlagChange() {
      // 요금 조회
      try {
        const records = this.$refs.visitsGrid.getBatchCurrentViewRecords();
        if (records.length > 0) {
          for (let i = 0; i < records.length; i++) {
            await this.getFixedPaymentCodeAndUpdateGreenFee(
              i
            );
          }
        }
      } catch (e) {
        console.error(e);
        this.errorToast("요금 조회 중 오류가 발생하였습니다");
      }
    }
  },
};
</script>
<style scoped>
.flex-narrow-field {flex: 1 1 0 !important;}
.flex-wide-field {flex: 2 1 0 !important;}
body .content-body .article-section.section-02 >>> .remark-active .e-tab-header .e-toolbar-items .e-toolbar-item:last-child {
  background: #f9f9f9 url('../../assets/images/common/required.png') no-repeat 40px calc(30% - 3px)
}
body .content-body .article-section.section-02 >>> .remark-active .e-tab-header .e-toolbar-items .e-toolbar-item.e-active:last-child .e-tab-wrap{
  background: #fff url('../../assets/images/common/required.png') no-repeat 40px calc(30% - 3px)
}
.icon {  background: #f9f9f9 url('../../assets/images/common/required.png') no-repeat 5px calc(50% - 1px)}
.e-tab .e-content { height: calc(100% - 34px) !important; overflow: auto; }
body .layer {position: absolute;left: 0;bottom: 0;width: 100%;background-color: #fff;z-index: 10;}
body .layerContent {box-sizing: border-box;height: 100%;border-top: 1px solid #ccc;box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.03);}
body .content-button .button {list-style: none;padding: 0;margin: 0;}
body .content-button .button li {position: absolute;left: 0;top: -22px;}
body .content-button .button li >>> .e-btn {display: block;overflow: hidden;box-shadow: none;width: 53px;height: 23px;padding: 0;border: none;background: transparent url(../../assets/images/common/layer-button-scale-up.png) no-repeat center 0;text-indent: -1000px;}  body .content-button .button li.arrow >>> .e-btn {background-position: center 0;}
body .content-button .button li.refresh >>> .e-btn {background-position: center -46px;}
body .content-button .button li.close >>> .e-btn {background-position: center -69px;}
body .content-button .button li.arrow {left: calc(50% - 16px);}
body .content-button .button li.refresh {left: auto;right: 67px;}
body .content-button .button li.close {left: auto;right: 14px;}
body .content-button .button li >>> .e-btn .e-ripple-element {background-color: transparent;}
body .content-step {padding: 9px 18px;border-bottom: 1px solid #e0e0e0;}
body .content-step .step {display: block;list-style: none;padding: 0;margin: 0;}
body .content-step .step:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-step .step li {box-sizing: border-box;float: left;width: calc(((100% - 80px) / 3) + 40px);padding-left: 40px;background: transparent url(../../assets/images/common/layer-step.png) no-repeat left center;}
body .content-step .step li.step-01 {width: calc((100% - 80px) / 3);padding-left: 0;background: none;}
body .content-step .step li .item {padding: 0 11px;border: 1px solid #e0e0e0;border-radius: 3px;background-color: #f9f9f9;color: #bbb;line-height: 27px;cursor: pointer;}
body .content-step .step li.dev-front-step-enabled .item {border-color: #2e7458;background-color: #2e7458;color: #fff;}
body .content-thumbnail {box-sizing: border-box;overflow: auto;height: 109px;padding: 12px;}
body .content-thumbnail .thumbnail {display: block;}
body .content-thumbnail .thumbnail:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-thumbnail .thumbnail .card {box-sizing: border-box;float: left;width: calc(100% / 5);padding: 6px;}
body .content-thumbnail .thumbnail .card .item {list-style: none;padding: 0;margin: 0;border: 1px solid #e0e0e0;border-radius: 3px;}
body .content-thumbnail .thumbnail .card .item li {display: block;padding: 0 6px;border-top: 1px solid #e0e0e0;color: #000;line-height: 23px;}
body .content-thumbnail .thumbnail .card .item li:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-thumbnail .thumbnail .card .item li.header {border-top: none;border-radius: 3px 3px 0 0;background-color: #f9f9f9;}
body .content-thumbnail .thumbnail .card .item li .title {float: left;padding: 0 6px;}
body .content-thumbnail .thumbnail .card .item li .content {float: right;padding: 0 6px;}
body .content-body {background-color: #fff !important;overflow: auto;}
body .content-body .body-article.article-01 {height: auto;}
body .content-body .body-article.article-01 .article-left {width: 525px;}
body .content-body .body-article.article-01 .article-right {width: calc(100% - 525px);}
body .content-body .article-section.section-01 {height: auto;}
body .content-body .article-section.section-01 .section-body {overflow: visible;border: none;}
body .content-body .article-section.section-01 .body-data .field {width: calc((100% / 2) + 1px);}
body .content-body .article-section.section-01 .body-data .field.promotion .content .item.input {width: 105px;}
body .content-body .article-section.section-01 .body-data .field.groupName .content .item.input {width: 105px;}
body .content-body .article-section.section-01 .body-data .field.memberPrivilege .content .item.input {width: 105px;}
body .content-body .article-section.section-02 {height: 191px;}
body .content-body .article-section.section-02 >>> .e-tab { height:100% !important; }
body .content-body .article-section.section-02 >>> .e-tab .e-tab-header {box-sizing: border-box;height: 34px !important;min-height: auto;border-bottom: 1px solid #e0e0e0;background-color: #fafafa;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items {min-height: auto;margin: 0 0 -1px;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-indicator {display: none;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item {height: 34px;min-height: auto;border-right: 1px solid #e0e0e0;border-bottom: 1px solid #e0e0e0;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-ripple-element {background-color: rgba(0, 0, 0, 0.1);}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-tab-wrap,
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-text-wrap {height: 100%;min-height: auto;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-tab-text {color: #bbb;font-size: 12px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-tab-wrap {padding: 0 12px;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-tab-wrap:hover,
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item .e-tab-wrap:focus {background-color: #f0f0f0;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item.e-active {border-bottom-color: #fff;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item.e-active .e-tab-wrap {background-color: #fff;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item.e-active .e-tab-text {color: #000;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item.e-active .e-close-icon:before {background-position: center bottom;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-items .e-toolbar-item.e-active .e-close-icon:hover:before {background-color: #f0f0f0;}
body .content-body .article-section.section-02 >>> .e-tab .e-hor-nav {height: 23px;min-height: auto;padding: 0 9px;margin: 5px 0 0;}
body .content-body .article-section.section-02 >>> .e-tab .e-hor-nav .e-icons {width: auto;height: 23px;padding: 3px 9px 2px;border-radius: 3px !important;background-color: transparent !important;color: #000;line-height: 1.5;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;-webkit-font-smoothing: antialiased;transform: none;}
body .content-body .article-section.section-02 >>> .e-tab .e-hor-nav .e-icons:hover,
body .content-body .article-section.section-02 >>> .e-tab .e-hor-nav .e-icons:focus {border-radius: 3px !important;background-color: #e0e0e0 !important;}
body .content-body .article-section.section-02 >>> .e-tab .e-hor-nav .e-icons:before {line-height: 1.5;content: "더보기";}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop {top: 26px !important;box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);padding: 6px 0;margin: 7px 0 0 1px;border: 1px solid #e0e0e0;border-radius: 3px;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item {min-width: auto !important;height: 23px;min-height: auto;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item .e-text-wrap {height: 100%;min-height: auto;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item .e-tab-wrap {padding: 0 12px;cursor: pointer;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {background-color: #f0f0f0;}
body .content-body .article-section.section-02 >>> .e-tab .e-toolbar-pop .e-toolbar-item .e-tab-text {color: #666;font-size: 12px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;}
body .content-body .article-section.section-0201 {min-width: 1086px;}
body .content-body .article-section.section-0201 .section-body {overflow: hidden;border: none;}
body .content-body .article-section.section-0201 .body-data .field {width: calc(100% + 1px);}
body .content-body .article-section.section-0201 .body-data .field.calculate,
body .content-body .article-section.section-0201 .body-data .field.teamStatus {width: calc((100% / 2) + 1px);}
body .content-body .article-section.section-0201 .body-data .field.calculate .item.text-01 {width: 120px;}
body .content-body .article-section.section-0201 .body-data .field.teamStatus .item.text-01 {width: 120px;}
body .content-body .body-article.article-0102 {padding: 0 !important;}
body .content-body .body-article.article-0102 .article-left {width: 220px;}
body .content-body .body-article.article-0102 .article-right {width: calc(100% - 220px);}
body .content-body .body-article.article-0102 .article-right .article-section.section-020202 {padding-bottom: 0 !important;}
body .content-body .body-article .section-header .header-button li >>> .e-btn {height: 29px;}
body .content-body .body-article .section-header .header-button li .dev-unlock-area >>> .e-btn {background-image: url('../../assets/images/common/unlock.png'); background-position: 6px !important;}
body .content-body .body-article .section-header .header-button li >>> .e-btn:not(.dev-unlock-area) {background-position: left -81px;}
body .content-body .body-article .section-header .header-button li.keyColor >>> .e-btn {background-position: left 4px;}
body .content-body .body-article .section-header .header-check {padding: 6px;}
body .content-body .article-section.section-020201 .section-body {overflow: visible;border: none;}
body .content-body .article-section.section-020201 .body-card .card {display: flow-root;box-sizing: border-box;width: 202px;height: 77px;padding: 9px;border: 1px solid #ccc;border-radius: 3px;background-color: #f9f9f9;}
body .content-body .article-section.section-020201 .body-card .card:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-body .article-section.section-020201 .body-card .photo {box-sizing: border-box;float: left;width: 46px;height: 100%;border: 1px solid #e0e0e0;background: #fff url(../../assets/images/common/photo-default.png) no-repeat center center;background-size: 40%;}
body .content-body .article-section.section-020201 .body-card .photo .item {width: 100%;height: 100%;background-color: #fff;background-repeat: no-repeat;background-position: center center;background-size: contain;}
body .content-body .article-section.section-020201 .body-card .content {box-sizing: border-box;list-style: none;float: left;width: calc(100% - 46px);padding: 2px 0 1px 12px;margin: 0;}
body .content-body .article-section.section-020201 .body-card .content .item {overflow: hidden;width: 100%;min-height: 18px;text-overflow: ellipsis;white-space: nowrap;}
body .content-body .article-section.section-020202 .section-body {overflow: visible;border: none;}
body .content-body .article-section.section-0203 .section-body {overflow: visible;border: none;}
body .content-body .article-section.section-0203 .body-card {overflow-x: auto;overflow-y: hidden;width: calc(100% + 36px);height: 113px;margin: -18px;}
body .content-body .article-section.section-0203 .body-card .box {display: flow-root;list-style: none;padding: 12px;margin: 0;}
body .content-body .article-section.section-0203 .body-card .box:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-body .article-section.section-0203 .body-card .card {display: flow-root;box-sizing: border-box;float: left;width: 202px;height: 77px;padding: 9px;margin: 6px;border: 1px solid #ccc;border-radius: 3px;background-color: #f9f9f9;cursor: pointer;}
body .content-body .article-section.section-0203 .body-card .card:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-body .article-section.section-0203 .body-card .card:hover {background-color: #f0f0f0;}
body .content-body .article-section.section-0203 .body-card .photo {box-sizing: border-box;float: left;width: 46px;height: 100%;border: 1px solid #e0e0e0;background: #fff url(../../assets/images/common/photo-default.png) no-repeat center center;background-size: 40%;}
body .content-body .article-section.section-0203 .body-card .photo .item {width: 100%;height: 100%;background-color: #fff;background-repeat: no-repeat;background-position: center center;background-size: contain;}
body .content-body .article-section.section-0203 .body-card .content {box-sizing: border-box;list-style: none;float: left;width: calc(100% - 46px);padding: 2px 0 1px 12px;margin: 0;}
body .content-body .article-section.section-0203 .body-card .content .item {overflow: hidden;width: 100%;min-height: 18px;text-overflow: ellipsis;white-space: nowrap;}
body .content-body .article-section.section-0204 .section-body {overflow: visible;border: none;}
body .content-body .article-section.section-0204 .body-data .field {width: calc(((100% - 40px) / 6) + 1px);}
body .content-body .article-section.section-0204 .body-data .field.number {width: 40px;}
body .content-body .article-section.section-0204 .body-footer {overflow: hidden;box-sizing: border-box;height: 24px;padding: 0 8px;border-top: 1px solid #e0e0e0;background-color: #f9f9f9;color: #000;}
body .content-body .article-section.section-0204 .body-footer ul {display: flow-root;list-style: none;width: 2000px;padding: 0;margin: 0 -9px;}
body .content-body .article-section.section-0204 .body-footer ul:after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
body .content-body .article-section.section-0204 .body-footer ul li {float: left;padding: 3px 9px 3px 10px;margin: 0 0 -1px;background: transparent url(../../assets/images/common/division.png) no-repeat left center;}
body .content-body .article-section.section-0204 .body-footer ul li:first-child {padding: 3px 9px;background: none;}
body .content-body .article-section.section-0105 {width:700px}
body .content-body .body-article.article-02 {height: auto;height: calc(100% - 221px - 7px - 10px);min-height: 260px;margin-top: -18px;}
body .content-body .body-article.article-02.dev-body-article-active-check-in__information {height: auto;height: calc(100% - 427px - 7px);min-height: 260px;margin-top: -18px;}
body .content-body .article-section.section-03 .section-body {overflow: hidden;border: none;}
body .content-body .article-section.section-04 .section-body {overflow: hidden;border: none;}
body .content-body .article-section.section-05 .section-body {overflow: hidden;border: none;}
body .content-body .body-article.article-03 {height: auto;margin-top: -18px;}
body .content-body .article-section.section-06 .section-body {overflow: hidden;border: none;}
body .content-body .article-section.section-06 .section-body >>> .e-grid.e-lib .dropdownbutton {margin: 0 -8px;}
body .content-body .article-section.section-06 .section-body >>> .e-grid.e-lib .dropdownbutton .e-btn {overflow: hidden;box-shadow: none;width: 23px;height: 23px;padding: 0;border: none;border-radius: 0;background: transparent url(../../assets/images/common/button-icon-etc.png) no-repeat center center;text-indent: -1000px;}
body .content-body .article-section.section-06.accordion .header-title {background-image: url("../../assets/images/common/accordion-bottom.png");}
body .content-body .article-section.section-06.accordion.close .header-title {background-image: url("../../assets/images/common/accordion-top.png");}
html body.theme-red .content-step .step li.dev-front-step-enabled .item {border-color: #c03737;background-color: #c03737;}
html body.theme-orange .content-step .step li.dev-front-step-enabled .item {border-color: #d86632;background-color: #d86632;}
html body.theme-yellow .content-step .step li.dev-front-step-enabled .item {border-color: #e2a429;background-color: #e2a429;}
html body.theme-green .content-step .step li.dev-front-step-enabled .item {border-color: #2e7458;background-color: #2e7458;}
html body.theme-blue .content-step .step li.dev-front-step-enabled .item {border-color: #3170b3;background-color: #3170b3;}
html body.theme-indigo .content-step .step li.dev-front-step-enabled .item {border-color: #5a3dac;background-color: #5a3dac;}
html body.theme-purple .content-step .step li.dev-front-step-enabled .item {border-color: #893bab;background-color: #893bab;}
html body.theme-black .content-step .step li.dev-front-step-enabled .item {border-color: #444;background-color: #444;}
</style>
